import { useWindowSize } from '../../../hooks';
import FormCryptoAccountDetails from './Forms/FormCryptoAccountDetails';
import { MenuSettingsDesktop, MenuSettingsMobile } from '../../../components';


const CryptoAccountDetails = () => {
	const mobileWindowMaxSize = 1023;
	const size = useWindowSize();

  	return (
		<>
			{
				size.width > mobileWindowMaxSize
				&& <div className="container is-hidden-touch">
					<section className="section">
						<div className="columns">
							<div className="column is-one-fifth">
								<MenuSettingsDesktop />
							</div>
							<div className="column">
								<article className="panel is-primary">
									<p className="panel-heading">Crypto account details</p>
									<div className="content p-3">
										<FormCryptoAccountDetails />
									</div>
								</article>
							</div>
						</div>
					</section>
				</div>
			}
			{
				size.width <= mobileWindowMaxSize
				&& <div className="container is-fixed-top is-hidden-desktop">
					<MenuSettingsMobile />
					<section className="section pt-0">
						<div className="content">
							<FormCryptoAccountDetails />
						</div>
					</section>
				</div>
			}
		</>
  	)
}

export default CryptoAccountDetails