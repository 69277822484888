import { useEffect } from 'react';

import ReactGA from 'react-ga4';
import { hotjar } from 'react-hotjar';

const Trackers = () => {
    useEffect(() => {
        if (process.env.REACT_APP_GA_ENABLED === '1') {
            ReactGA.initialize(process.env.REACT_APP_GA_CODE);
        }

        if (process.env.REACT_APP_HOTJAR_ENABLED === '1') {
            hotjar.initialize(
                process.env.REACT_APP_HOTJAR_HJID,
                process.env.REACT_APP_HOTJAR_HJSV
            );
        }
    }, []);

    return (
        <>

        </>
    );
}

export default Trackers;