import QRCode from "react-qr-code";
import { useReducer, useState, useEffect } from "react";

import {
    FaKey,
    FaLock,
    FaCheck,
    FaExclamationTriangle
} from 'react-icons/fa';
import { TiTick } from 'react-icons/ti';
import { BsPlusCircleFill } from 'react-icons/bs';
import { IoArrowBackSharp } from 'react-icons/io5';
import { RiEyeCloseLine, RiEyeLine } from 'react-icons/ri';

import {
    isValidTwoFACode,
    isNotEmptyString,
    isValidShortString,
    isPasswordMinLength,
} from '../../../../services';
import { useApi } from '../../../../hooks';
import { useUserContext } from '../../../../context';


const ACTIONS_PASSWORD = {
    setIsPasswordVisible: 'SET_IS_PASSWORD_VISIBLE',
    setPassword: 'SET_PASSWORD',

    startVerification: 'START_VERIFICATION',
    terminateVerification: 'TERMINATE_VERIFICATION'
};
function reducerPassword(form, action) {
    let newForm = {...form};

    const reset = (newForm) => {
        newForm.data.password = '';
        newForm.state.isPasswordValid = true;
        newForm.state.isPasswordVisible = false;
        newForm.state.isVerifying = false;
        newForm.state.api.serverError = false;
        newForm.state.api.validationError = false;
    };
    const validate = (newForm) => {
        newForm.state.api.serverError = false;
        newForm.state.api.validationError = false;

        newForm.state.isPasswordValid = (
            isValidShortString(newForm.data.password)
            && isPasswordMinLength(newForm.data.password)
        );
    };

    switch (action.type) {
        case ACTIONS_PASSWORD.setIsPasswordVisible:
            newForm.state.isPasswordVisible = action.payload.isShown;

            return newForm;
        case ACTIONS_PASSWORD.setPassword:
            newForm.data.password = action.payload.password;
            validate(newForm);

            return newForm;
        case ACTIONS_PASSWORD.startVerification:
            newForm.state.isVerifying = true;
            newForm.state.api.serverError = false;
            newForm.state.api.validationError = false;

            return newForm;
        case ACTIONS_PASSWORD.terminateVerification:
            if (action.payload.isVerified) {
                reset(newForm);
            } else {
                newForm.state.isVerifying = false;

                switch (action.payload.error) {
                    case 'SERVER_ERROR':
                        newForm.state.api.serverError = true;
                        newForm.state.api.validationError = false;
                        break;
                    default:
                        validate(newForm);
                        newForm.state.api.serverError = false;
                        newForm.state.api.validationError = true;
                        break;
                }
            }

            return newForm;
        default:
            return newForm;
    }
};

const ACTIONS_SIX_DIGITS = {
    setTwoFACode: 'SET_TWO_FA_CODE',

    startVerification: 'START_VERIFICATION',
    terminateVerification: 'TERMINATE_VERIFICATION'
};

function reducerSixDigits(form, action) {
    let newForm = {...form};

    const reset = (newForm) => {
        newForm.data.twoFACode = '';
        newForm.state.isTwoFACodeValid = false;
        newForm.state.isVerifying = false;
        newForm.state.api.serverError = false;
        newForm.state.api.validationError = false;
    };
    const validate = (newForm) => {
        newForm.state.api.serverError = false;
        newForm.state.api.validationError = false;

        newForm.state.isTwoFACodeValid =
            isValidTwoFACode(newForm.data.twoFACode);
    };

    switch (action.type) {
        case ACTIONS_SIX_DIGITS.setTwoFACode:
            newForm.data.twoFACode = action.payload.twoFACode;
            validate(newForm);

            return newForm;
        case ACTIONS_SIX_DIGITS.startVerification:
            newForm.state.isVerifying = true;
            newForm.state.api.serverError = false;
            newForm.state.api.validationError = false;

            return newForm;
        case ACTIONS_SIX_DIGITS.terminateVerification:
            if (action.payload.isVerified) {
                reset(newForm);
            } else {
                newForm.data.twoFACode = '';
                newForm.state.isVerifying = false;

                switch (action.payload.error) {
                    case 'SERVER_ERROR':
                        newForm.state.api.serverError = true;
                        newForm.state.api.validationError = false;
                        break;
                    default:
                        validate(newForm);
                        newForm.state.api.serverError = false;
                        newForm.state.api.validationError = true;
                        break;
                }
            }

            return newForm;
        default:
            return newForm;
    }
};

const FormTwoFA = () => {
    const api = useApi();
    const userContext = useUserContext();

    const [step, setStep] = useState(1);
    const [qrCodeLink, setQrCodeLink] = useState(false);
    const [isTwoFAEnabled, setIsTwoFAEnabled] = useState(false);

    const [disableButtonLoader, setDisableButtonLoader] = useState(false);
    const [iHaveAppButtonLoader, setIHaveAppButtonLoader] = useState(false);

    useEffect(() => {
        if (
            userContext
            && Object.hasOwn(userContext, 'user')
            && userContext.user !== null
            && Object.hasOwn(userContext.user, 'is_two_fa_enabled')
        ) {
            setIsTwoFAEnabled(userContext.user.is_two_fa_enabled);
        }
    }, [userContext]);





    /* Handle Switching Screens */
    const stepForward = (step) => {
        switch (step) {
            case 1:
                if (userContext.user.is_social_account) {
                    setStep(3);
                } else {
                    setStep(2);
                }
                break;
            case 2:
                setStep(3);
                break;
            case 3:
                setIHaveAppButtonLoader(true);
                api.getTwoFAQR().then(response => {
                    if (response.ok) {
                        setQrCodeLink(response.data.qr_code_uri);
                        setStep(4);
                        setIHaveAppButtonLoader(false);
                    }
                });
                break;
            case 4:
                setStep(5);
                break;
            default:
                break;
        }
    };

    const stepBack = (step) => {
        switch (step) {
            case 2:
                setStep(1);
                break;
            case 3:
                if (userContext.user.is_social_account) {
                    setStep(1);
                } else {
                    setStep(2);
                }
                break;
            case 4:
                setStep(3);
                break;
            case 5:
                setStep(4);
                break;
            default:
                break;
        }
    };





    /* Handle Password Verification */
    const [formPassword, dispatchPassword] = useReducer(reducerPassword, {
        data: {
            password: ''
        },
        state: {
            isPasswordValid: true,
            isPasswordVisible: false,

            isVerifying: false,
            api: {
                serverError: false,
                validationError: false
            }
        }
    });

    const timeShowingThePassword = 5000;

    const showPasswordVisualizing = () => {
        dispatchPassword({
            type: ACTIONS_PASSWORD.setIsPasswordVisible,
            payload: { isShown: true }
        });

        setTimeout(() => {
            dispatchPassword({
                type: ACTIONS_PASSWORD.setIsPasswordVisible,
                payload: { isShown: false }
            });
        }, timeShowingThePassword);
    };

    const hidePasswordVisualizing = () => {
        dispatchPassword({
            type: ACTIONS_PASSWORD.setIsPasswordVisible,
            payload: { isShown: false }
        });
    };

    const handlePasswordChange = (password) => {
        dispatchPassword(
            {
                type: ACTIONS_PASSWORD.setPassword,
                payload: {
                    password: password
                }
            }
        );
    };

    const handlePasswordVerification = (e) => {
        e.preventDefault();
        e.stopPropagation();

        dispatchPassword({ type: ACTIONS_PASSWORD.startVerification });

        if (
            !userContext.user.is_social_account
            && isNotEmptyString(formPassword.data.password)
            && formPassword.state.isPasswordValid
        ) {
            api.verify(
                {
                    "password": formPassword.data.password
                }
            ).then(response => {
                if (response.ok) setStep(3);

                dispatchPassword(
                    {
                        type: ACTIONS_PASSWORD.terminateVerification,
                        payload: {
                            isVerified: response.ok,
                            error: response.error
                        }
                    }
                );
            });
        } else {
            dispatchPassword(
                {
                    type: ACTIONS_PASSWORD.terminateVerification,
                    payload: {
                        isVerified: false,
                        error: false
                    }
                }
            );
        }
    };















    /* Handle Six Digits Verification */
    const [formSixDigits, dispatchSixDigits] = useReducer(reducerSixDigits, {
        data: {
            twoFACode: ''
        },
        state: {
            isTwoFACodeValid: false,

            isVerifying: false,
            api: {
                serverError: false,
                validationError: false
            }
        }
    });

    const handleTwoFACodeChange = (data) => {
        dispatchSixDigits(
            {
                type: ACTIONS_SIX_DIGITS.setTwoFACode,
                payload: { twoFACode: data }
            }
        );
    };

    const verifySixDigitCode = (e) => {
        e.preventDefault();
        e.stopPropagation();

        dispatchSixDigits({ type: ACTIONS_SIX_DIGITS.startVerification });
        if (
            '' !== formSixDigits.data.twoFACode
            && formSixDigits.state.isTwoFACodeValid
        ) {
            api.verifySettingsSecurityTwoFA(
                {
                    'code': 1 * formSixDigits.data.twoFACode
                }
            ).then(response => {
                if (response.ok) api.refresh();

                dispatchSixDigits(
                    {
                        type: ACTIONS_SIX_DIGITS.terminateVerification,
                        payload: {
                            isVerified: response.ok,
                            error: response.error
                        }
                    }
                );
            });
        } else {
            dispatchSixDigits(
                {
                    type: ACTIONS_SIX_DIGITS.terminateVerification,
                    payload: {
                        isVerified: false,
                        error: false
                    }
                }
            );
        }
    };

    const handleDisable = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setDisableButtonLoader(true);
        api.disableSettingsSecurityTwoFA().then(response => {
            if (response.ok) {
                api.refresh().then(response => {
                    setStep(1);
                    setDisableButtonLoader(false);
                });
            }
        });
    };

    return (
        <>
            {
                isTwoFAEnabled
                ? <div className="columns">
                    <div className="column">
                        <ul>
                            <li><TiTick className="has-text-secondary"/> Extra layer of protection for your account</li>
                            <li><TiTick className="has-text-secondary"/> No risk of compromised passwords</li>
                            <li><TiTick className="has-text-secondary"/> Lower security management costs</li>
                        </ul>
                    </div>
                    <div className="column">
                        <p>Two-Factor Authentication is enabled.</p>
                        <button
                            onClick={handleDisable}
                            className="button is-white-with-primary-text is-rounded"
                        >
                            Disabled
                            {
                                disableButtonLoader
                                && <span className="cl-button-loader-primary"></span>
                            }
                        </button>
                    </div>
                </div>
                : <>
                    {
                        step === 1
                        && <div className="columns">
                            <div className="column">
                                <ul>
                                    <li><TiTick className="has-text-secondary"/> Extra layer of protection for your account</li>
                                    <li><TiTick className="has-text-secondary"/> No risk of compromised passwords</li>
                                    <li><TiTick className="has-text-secondary"/> Lower security management costs</li>
                                </ul>
                            </div>
                            <div className="column">
                                <button
                                    onClick={(e) => stepForward(1)}
                                    className="button is-secondary is-rounded"
                                >Set up now</button>
                            </div>
                        </div>
                    }

                    {
                        step === 2
                        && false === userContext.user.is_social_account
                        && <>
                            <IoArrowBackSharp
                                onClick={(e) => stepBack(2)}
                                className="is-size-3 has-text-secondary"
                            />
                            <br />
                            <p>Verify your identity to set up Two-Factor Authentication</p>

                            <div className="field">
                                <label className="label">Password</label>
                                <div className="control has-icons-left has-icons-right">
                                    <input
                                        className={`input ${
                                            formPassword.data.password !== ''
                                            && !formPassword.state.isPasswordValid
                                            && 'is-danger'
                                        }`}
                                        type={`${formPassword.state.isPasswordVisible ? "text" : "password" }`}
                                        value={formPassword.data.password}
                                        onChange={(e) => handlePasswordChange(e.target.value)}
                                        autoComplete="new-password"
                                        autoFocus={true}
                                    />
                                    <span className="icon is-small is-left">
                                        <FaLock />
                                    </span>

                                    {
                                        !formPassword.state.isPasswordVisible
                                        && <span
                                            className="icon is-small is-right"
                                            data="test"
                                            onClick={showPasswordVisualizing}
                                            style={{pointerEvents: "all", pointer: "cursor"}}
                                        >
                                            <RiEyeCloseLine/>
                                        </span>
                                    }

                                    {
                                        formPassword.state.isPasswordVisible
                                        && <span
                                            className="icon is-small is-right"
                                            onClick={hidePasswordVisualizing}
                                            style={{pointerEvents: "all", pointer: "cursor"}}
                                        >
                                            <RiEyeLine/>
                                        </span>
                                    }
                                </div>
                                {
                                    formPassword.state.api.serverError
                                    && <p className="help is-danger">Unexpected error! Try again.</p>
                                }
                                {
                                    formPassword.state.api.validationError
                                    && <p className="help is-danger">Invalid password! Try again.</p>
                                }
                            </div>
                            <div className="field is-grouped is-flex is-justify-content-right">
                                <div className="control">
                                    <button
                                        onClick={handlePasswordVerification}
                                        className="button is-primary is-rounded"
                                        disabled={
                                            formPassword.data.password.length === 0
                                            ||
                                            !formPassword.state.isPasswordValid
                                        }
                                    >
                                        Verify
                                        {
                                            formPassword.state.isVerifying
                                            && <span className="cl-button-loader"></span>
                                        }
                                    </button>
                                </div>
                            </div>
                        </>
                    }

                    {
                        (
                            (
                                step === 2
                                && true === userContext.user.is_social_account
                            )
                            ||
                            step === 3
                        )
                        && <>
                            <IoArrowBackSharp
                                onClick={(e) => stepBack(3)}
                                className="is-size-3 has-text-secondary"
                            />
                            <br />
                            <h4>Set up authenticator app</h4>
                            <p>You can use any authenticator app to use Two-Factor Authentication. We recommend Google Authenticator for <a href="https://apps.apple.com/in/app/google-authenticator/id388497605" target="_blank">iOS</a> or <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&pli=1" target="_blank">Android</a>.</p>

                            <button
                                onClick={(e) => stepForward(3)}
                                className="button is-secondary is-rounded"
                            >
                                I have an authenticator app
                                {
                                    iHaveAppButtonLoader
                                    && <span className="cl-button-loader"></span>
                                }
                            </button>
                        </>
                    }

                    {
                        (
                            step === 4
                        )
                        && <>
                            <IoArrowBackSharp
                                onClick={(e) => stepBack(4)}
                                className="is-size-3 has-text-secondary"
                            />
                            <br />
                            <h4>In the authenticator app, tap <BsPlusCircleFill /> and scan the QR code</h4>

                            <div className="columns">
                                <div className="column is-4">
                                    <div className="card mb-4">
                                        <div className="card-content">
                                            <div className="content">
                                                <QRCode value={qrCodeLink} />
                                            </div>
                                        </div>
                                    </div>

                                    <button
                                        onClick={(e) => stepForward(4)}
                                        className="button is-secondary is-rounded"
                                    >Next</button>
                                </div>
                                <div className="column"></div>
                                <div className="column"></div>
                            </div>
                        </>
                    }

                    {
                        (
                            step === 5
                        )
                        && <>
                            <IoArrowBackSharp
                                onClick={(e) => stepBack(5)}
                                className="is-size-3 has-text-secondary"
                            />
                            <br />
                            <h4>Enter the 6-digit verification code you see in the app</h4>

                            <div className="columns">
                                <div className="column is-4">
                                    <div className="field mb-4">
                                        <div className="control has-icons-left has-icons-right">
                                            <input
                                                className={`input ${
                                                    (
                                                        formSixDigits.state.api.serverError
                                                        || formSixDigits.state.api.validationError
                                                    )
                                                    && 'is-danger'
                                                }`}
                                                maxLength="6"
                                                type="numeric"
                                                placeholder="Authenticator Code"
                                                value={formSixDigits.data.twoFACode}
                                                onChange={
                                                    (e) => handleTwoFACodeChange(e.target.value)
                                                }
                                                autoFocus={true}
                                            />
                                            <span className="icon is-small is-left">
                                                <FaKey />
                                            </span>
                                            {
                                                '' !== formSixDigits.data.twoFACode
                                                && (
                                                    <>
                                                        {
                                                            formSixDigits.state.isTwoFACodeValid
                                                            && <span className="icon is-small is-right">
                                                                <FaCheck/>
                                                            </span>
                                                        }

                                                        {
                                                            !formSixDigits.state.isTwoFACodeValid
                                                            && <span className="icon is-small is-right">
                                                                <FaExclamationTriangle/>
                                                            </span>
                                                        }
                                                    </>
                                                )
                                            }
                                        </div>
                                        {
                                            (
                                                formSixDigits.state.api.serverError
                                                || formSixDigits.state.api.validationError
                                            )
                                            && <p className="help is-danger">Invalid 6-digits code! Try again.</p>
                                        }
                                    </div>

                                    <button
                                        onClick={verifySixDigitCode}
                                        className="button is-secondary is-rounded"
                                        disabled={
                                            formSixDigits.data.twoFACode === 0
                                            ||
                                            !formSixDigits.state.isTwoFACodeValid
                                        }
                                    >
                                        Verify
                                        {
                                            formSixDigits.state.isVerifying
                                            && <span className="cl-button-loader"></span>
                                        }
                                    </button>
                                </div>
                                <div className="column"></div>
                                <div className="column"></div>
                            </div>
                        </>
                    }
                </>
            }
        </>
    );
}

export default FormTwoFA;