import { React } from "react";
import Favicon from "react-favicon";
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import faviconImg from './assets/img/favicon.png';

import {
    Home,
    Login, LoginSecure, OpenAccount,
    VerifyEmail, ForgottenPassword, ResetPassword,

    Game, WatchDraw, Results, DrawHistory,

    MyGames, Messages, Info, PersonalDetails, PasswordChange, Security,
    EmailPreferences, TransactionHistory, CryptoAccountDetails,

    HowToClaimMyWin, WhereTheMoneyGoes, AboutUs, HelpAndContactUs,
    TermsAndRules, PrivacyPolicy, Cookies, WinnersAndGoodCauses, Feedback,

    PageNotFound,

    Links
} from './views';

import {
    Navbar,
    Footer,
    Trackers,
    EmailVerifiedBar,

	AuthorizedRoutes,
	UnauthorizedRoutes
} from './components';
import { UserContextProvider } from './context';
import { GamesContextProvider } from './context';

import './assets/css/styles.css';


const App = () => {
    return (
        <BrowserRouter>
            <div className="App">
                <GamesContextProvider>
                    <UserContextProvider>
                        <Favicon url={faviconImg}></Favicon>
                        <Trackers />

                        <EmailVerifiedBar />
                        <Navbar />

                        <div className="content mb-0">
                            <Routes>
                                <Route exact path="/" element={<Home />} />
                                <Route path="/links" element={<Links />} />

                                <Route path="/verify-email/:key" element={<VerifyEmail />} />

                                <Route path="/game/:key/watch" element={<WatchDraw />} />
                                <>
                                    <Route path="/game/:key/draws/:page" element={<DrawHistory />} />
                                    <Route path="/game/:key/draws" element={<DrawHistory />} />
                                </>
                                <Route path="/game/:key/lucky-dips/:luckyDips" element={<Game />} />
                                <Route path="/game/:key" element={<Game />} />

                                <Route path="/how-to-claim" element={<HowToClaimMyWin />} />
                                <Route path="/where-the-money-goes" element={<WhereTheMoneyGoes />} />
                                <Route path="/about-us" element={<AboutUs />} />
                                <Route path="/help-and-contact-us" element={<HelpAndContactUs />} />
                                <Route path="/terms" element={<TermsAndRules />} />
                                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                                <Route path="/cookies" element={<Cookies />} />
                                <Route path="/winners-and-good-causes" element={<WinnersAndGoodCauses />} />
                                <Route path="/feedback" element={<Feedback />} />

                                <Route element={<AuthorizedRoutes />}>
                                    <>
                                        <Route path="/game/:key/results/:page/:id" element={<Results />} />
                                        <Route path="/game/:key/results/:page" element={<Results />} />
                                        <Route path="/game/:key/results" element={<Results />} />
                                    </>

                                    <Route path="/settings/general/my-games" element={<MyGames />} />
                                    <>
                                        <Route path="/settings/general/messages/:page" element={<Messages />} />
                                        <Route path="/settings/general/messages" element={<Messages />} />
                                    </>
                                    <Route path="/settings/general/info" element={<Info />} />
                                    <Route path="/settings/profile-and-settings/personal-details" element={<PersonalDetails />} />
                                    <Route path="/settings/profile-and-settings/password-change" element={<PasswordChange />} />
                                    <>
                                        <Route path="/settings/profile-and-settings/security/:page" element={<Security />} />
                                        <Route path="/settings/profile-and-settings/security" element={<Security />} />
                                    </>
                                    <Route path="/settings/profile-and-settings/email-preferences" element={<EmailPreferences />} />
                                    <>
                                        <Route path="/settings/funds-and-payments/transaction-history/:page" element={<TransactionHistory />} />
                                        <Route path="/settings/funds-and-payments/transaction-history" element={<TransactionHistory />} />
                                    </>
                                    <Route path="/settings/funds-and-payments/crypto-account-details" element={<CryptoAccountDetails />} />
                                </Route>
                                <Route element={<UnauthorizedRoutes />}>
                                    <Route path="/open-an-account" element={<OpenAccount />} />
                                    <Route path="/login" element={<Login />} />
                                    <Route path="/login-secure/:key" element={<LoginSecure />} />
                                    <Route path="/forgotten-password" element={<ForgottenPassword />} />
                                    <Route path="/reset-password/:key" element={<ResetPassword />} />
                                </Route>

                                <Route path="*" element={<PageNotFound />} />
                            </Routes>
                        </div>

                        <Footer />
                    </UserContextProvider>
                </GamesContextProvider>
            </div>
        </BrowserRouter>
    )
}

export default App