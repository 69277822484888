import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useWindowSize } from '../../../hooks';
import FormPasswordChange from './Forms/FormPasswordChange';
import { MenuSettingsDesktop, MenuSettingsMobile } from '../../../components';

import { useUserContext } from '../../../context';

const PasswordChange = () => {
	const mobileWindowMaxSize = 1023;
	const size = useWindowSize();
	const navigate = useNavigate();

	const userContext = useUserContext();
	useEffect(() => {
		if (userContext && userContext.user.is_social_account) {
			navigate('/');
		}
    }, [userContext]);

  	return (
		<>
			{
				size.width > mobileWindowMaxSize
				&& <div className="container is-hidden-touch">
					<section className="section">
						<div className="columns">
							<div className="column is-one-fifth">
								<MenuSettingsDesktop/>
							</div>
							<div className="column">
								<article className="panel is-primary">
									<p className="panel-heading">Password change</p>
									<div className="content p-3">
										<FormPasswordChange />
									</div>
								</article>
							</div>
						</div>
					</section>
				</div>
			}
			{
				size.width <= mobileWindowMaxSize
				&& <div className="container is-fixed-top is-hidden-desktop">
					<MenuSettingsMobile />
					<section className="section pt-0">
						<div className="content">
							<FormPasswordChange />
						</div>
					</section>
				</div>
			}
		</>
  	)
}

export default PasswordChange