const InProgressText = ({ text }) => {
    if (typeof text === 'undefined') text = 'Loading';

    return (
        <p className="has-text-grey ml-3 mb-3">
            {text}
            <span className="loader-dot">.</span>
            <span className="loader-dot">.</span>
            <span className="loader-dot">.</span>
        </p>
     );
}

export default InProgressText;