import {
    FaLock,
    FaCheck,
    FaEnvelope,
    FaExclamationTriangle
} from 'react-icons/fa';
import { useState, useReducer, useEffect, useCallback } from "react";
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import {
    ErrorBlock,
    LoginSocialGoogleButton,
    LoginSocialTwitterButton,
    LoginSocialFacebookButton
} from '../../components';
import { useApi } from '../../hooks';
import { isValidEmail, isPasswordMinLength } from '../../services';


const ACTIONS = {
    setEmail: 'SET_EMAIL',
    setPassword: 'SET_PASSWORD',

    startSending: 'START_SENDING',
    terminateSending: 'TERMINATE_SENDING'
};

function reducer(form, action) {
    let newForm = {...form};
    const resetData = (newForm) => {
        newForm.data.email = '';
        newForm.data.password = '';
    };
    const resetState = (newForm) => {
        newForm.state.isEmailValid = true;
        newForm.state.isPasswordValid = true;

        newForm.state.isSending = false;
        newForm.state.api.failed = false;
        newForm.state.api.errorMessage = '';
    };
    const validate = (newForm) => {
        newForm.state.isEmailValid = isValidEmail(newForm.data.email);
        newForm.state.isPasswordValid = isPasswordMinLength(
            newForm.data.password
        );

        newForm.state.api.failed = false;
        newForm.state.api.errorMessage = '';
    };

    switch (action.type) {
        case ACTIONS.setEmail:
            newForm.data.email = action.payload.email;
            validate(newForm);

            return newForm;
        case ACTIONS.setPassword:
            newForm.data.password = action.payload.password;
            validate(newForm);

            return newForm;
        case ACTIONS.startSending:
            newForm.state.isSending = true;

            return newForm;
        case ACTIONS.terminateSending:
            newForm.state.isSending = false;

            if (action.payload.ok) {
                resetData(newForm);
                resetState(newForm);
            } else {
                newForm.state.api.failed = true;
                newForm.state.api.errorMessage = action.payload.error.message;
            }

            return newForm;
        default:
            return newForm;
    }
};

const Login = () => {
	const [sociaLoginError, setSociaLoginError] = useState(false);

	const api = useApi();
    const navigate = useNavigate();
    const [form, dispatch] = useReducer(reducer, {
        data: {
            email: '',
            password: ''
        },
        state: {
            isEmailValid: true,
            isPasswordValid: true,

            isSending: false,
            api: {
                failed: false,
                errorMessage: ''
            }
        }
    });

    const [searchParams] = useSearchParams();

    const handleEmailChange = (data) => {
        dispatch(
            {
                type: ACTIONS.setEmail,
                payload: { email: data }
            }
        );
    };
    const handlePasswordChange = (data) => {
        dispatch(
            {
                type: ACTIONS.setPassword,
                payload: { password: data }
            }
        );
    };
    const handleSend = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (
            !form.state.isSending

            && '' !== form.data.email
            && '' !== form.data.password

            && form.state.isEmailValid
            && form.state.isPasswordValid
        ) {
            dispatch({ type: ACTIONS.startSending });

            api.login(
                {
                    'email_or_username': form.data.email,
                    'password': form.data.password
                }
            ).then(response => {
                dispatch(
                    {
                        type: ACTIONS.terminateSending,
                        payload: response
                    }
                );

                const path = searchParams.get('path');

                if (response.ok) {
                    if (
                        Object.hasOwn(response.data, 'access_token')
                        && Object.hasOwn(response.data, 'refresh_token')
                    ) {
                        if (null !== path) {
                            navigate(path);
                            return;
                        }

                        navigate('/');
                        return;
                    }

                    navigate('/login-secure/' + response.data.key + (
                        null !== path ? '?path=' + path : ''
                    ));
                }
            });
        }
    };

	const handleEnter = useCallback((e) => {
		if (e.key === "Enter") {
			e.preventDefault();
        	e.stopPropagation();

			if (
				'' !== form.data.email
				&& '' !== form.data.password
				&& form.state.isEmailValid
				&& form.state.isPasswordValid
			) {
				handleSend(e);
			}
		}
	}, []);
	useEffect(() => {
		document.addEventListener("keydown", handleEnter, false);

		return () => {
		  	document.removeEventListener("keydown", handleEnter, false);
		};
	}, []);

	return (
		<div className="container">
			<section className="section">
				<div className="columns">
					<div className="column is-3"></div>
					<div className="column">
						<div className="content">
							<h1 className="title">Login</h1>
							{
								form.state.api.failed
								&& <ErrorBlock message={form.state.api.errorMessage}/>
							}

							<div className="field">
								<div className="control has-icons-left has-icons-right">
									<input
										className={`input ${
											'' !== form.data.email
											&& !form.state.isEmailValid
											&& 'is-danger'
										}`}
										type="email"
										placeholder="Email"
										value={form.data.email}
										onChange={
											(e) => handleEmailChange(e.target.value)
										}
										autoFocus={true}
									/>
									<span className="icon is-small is-left">
										<FaEnvelope />
									</span>
									{
										'' !== form.data.email
										&& (
											<>
												{
													form.state.isEmailValid
													&& <span className="icon is-small is-right">
														<FaCheck/>
													</span>
												}

												{
													!form.state.isEmailValid
													&& <span className="icon is-small is-right">
														<FaExclamationTriangle/>
													</span>
												}
											</>
										)
									}
								</div>
								{
									'' !==form.data.email
									&& !form.state.isEmailValid
									&& <p
										className="help is-danger"
									>Invalid email</p>
								}
							</div>

							<div className="field">
								<div className="control has-icons-left has-icons-right">
									<input
										className={`input ${
											'' !== form.data.password
											&& !form.state.isPasswordValid
											&& 'is-danger'
										}`}
										type="password"
										placeholder="Password"
										value={form.data.password}
										onChange={
											(e) => handlePasswordChange(e.target.value)
										}
									/>
									<span className="icon is-small is-left">
										<FaLock />
									</span>
									{
										'' !== form.data.password
										&& (
											<>
												{
													form.state.isPasswordValid
													&& <span className="icon is-small is-right">
														<FaCheck/>
													</span>
												}

												{
													!form.state.isPasswordValid
													&& <span className="icon is-small is-right">
														<FaExclamationTriangle/>
													</span>
												}
											</>
										)
									}
								</div>
								{
									'' !==form.data.password
									&& !form.state.isPasswordValid
									&& <p className="help is-danger">Must be 8 characters or longer - at least one small and one capital letter, one digit and one symbol (i.e. #, $, @).</p>
								}
							</div>

							<div className="field">
								<p className="control is-pulled-right">
									<button
										className="button is-primary is-rounded"
										onClick={handleSend}
										disabled={
											'' === form.data.email
											||  '' === form.data.password

											|| !form.state.isEmailValid
											|| !form.state.isPasswordValid
										}
									>
										Log in
										{
											form.state.isSending
											&& <span className="cl-button-loader"></span>
										}
									</button>
								</p>
							</div>
							<br />
							<br />
						</div>

						<div className="is-divider" data-content="OR"></div>

						<div className="content has-text-centered">
							{
								sociaLoginError
								&& <ErrorBlock message={sociaLoginError}/>
							}

							<LoginSocialGoogleButton
								setSociaLoginError={setSociaLoginError}
							/>
							<LoginSocialFacebookButton
								setSociaLoginError={setSociaLoginError}
							/>
							{
								1 !== 1
								&& <LoginSocialTwitterButton
									setSociaLoginError={setSociaLoginError}
								/>
							}
						</div>

						<div className="content">
							<div className="content is-flex is-justify-content-center">
								<p>
									<Link
										to="/forgotten-password"
										className="link-standard"
									>Forgotten password?</Link>
								</p>
							</div>

							<div className="content is-flex is-justify-content-center">
								<p>
									New player?&nbsp;
									<Link
										to="/open-an-account"
										className="link-standard"
									>Open an Account</Link>
								</p>
							</div>

							<div className="content is-flex is-justify-content-center">
								<p className="is-size-7">
									I suspect my account has been accessed by another person.&nbsp;
									<Link
										to="/help-and-contact-us"
										className="link-standard"
									>Contact us</Link> immedietly!
								</p>
							</div>
						</div>
					</div>
					<div className="column is-3"></div>
				</div>
			</section>
			<div className="block my-6"></div>
		</div>
  	)
}

export default Login