import {
    FaLock,
    FaCheck,
    FaEnvelope,
    FaExclamationTriangle
} from 'react-icons/fa';
import { useReducer, useEffect, useCallback } from "react";
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import {
	isValidEmail,
	isPasswordMinLength,
	passwordStrengthChecker
} from '../../services';
import { useApi } from '../../hooks';
import { ErrorBlock } from '../../components';


const ACTIONS = {
    setEmail: 'SET_EMAIL',
    setEmailConfirm: 'SET_EMAIL_CONFIRM',
    setPassword: 'SET_PASSWORD',

    startSending: 'START_SENDING',
    terminateSending: 'TERMINATE_SENDING'
};

function reducer(form, action) {
    let newForm = {...form};
    const resetData = (newForm) => {
        newForm.data.email = '';
        newForm.data.emailConfirm = '';
        newForm.data.password = '';
    };
    const resetState = (newForm) => {
        newForm.state.isEmailValid = true;
        newForm.state.isEmailConfirmValid = true;
		newForm.state.isEmailAndEmailConfirmMatch = true;
        newForm.state.isPasswordValid = true;

        newForm.state.isSending = false;
        newForm.state.api.failed = false;
        newForm.state.api.errorMessage = '';
    };
    const validate = (newForm) => {
        newForm.state.isEmailValid = isValidEmail(newForm.data.email);
        newForm.state.isEmailConfirmValid =
            isValidEmail(newForm.data.emailConfirm);

		newForm.state.isEmailAndEmailConfirmMatch = true;
		if (
			newForm.data.emailConfirm !== '' &&
			newForm.state.isEmailConfirmValid
		) {
			newForm.state.isEmailAndEmailConfirmMatch = (
				newForm.data.email === newForm.data.emailConfirm
			);
		}

        newForm.state.isPasswordValid = isPasswordMinLength(
            newForm.data.password
        );
		newForm.state.passwordStrength = passwordStrengthChecker(
			newForm.data.password
		)

        newForm.state.api.failed = false;
        newForm.state.api.errorMessage = '';
    };

    switch (action.type) {
        case ACTIONS.setEmail:
            newForm.data.email = action.payload.email;
            validate(newForm);

            return newForm;
        case ACTIONS.setEmailConfirm:
            newForm.data.emailConfirm = action.payload.emailConfirm;
            validate(newForm);

            return newForm;
        case ACTIONS.setPassword:
            newForm.data.password = action.payload.password;
            validate(newForm);

            return newForm;
        case ACTIONS.startSending:
            newForm.state.isSending = true;

            return newForm;
        case ACTIONS.terminateSending:
            newForm.state.isSending = false;

            if (action.payload.ok) {
                resetData(newForm);
                resetState(newForm);
            } else {
                newForm.state.api.failed = true;
                newForm.state.api.errorMessage = action.payload.error.message;
            }

            return newForm;
        default:
            return newForm;
    }
};

const OpenAccount = () => {
	const api = useApi();
    const navigate = useNavigate();
    const [form, dispatch] = useReducer(reducer, {
        data: {
            email: '',
            emailConfirm: '',
            password: ''
        },
        state: {
            isEmailValid: true,

            isEmailConfirmValid: true,
			isEmailAndEmailConfirmMatch: true,

            isPasswordValid: true,
			passwordStrength: '',

            isSending: false,
            api: {
                failed: false,
                errorMessage: ''
            }
        }
    });

    const [searchParams] = useSearchParams();

    const handleEmailChange = (data) => {
        dispatch(
            {
                type: ACTIONS.setEmail,
                payload: { email: data }
            }
        );
    };
    const handleEmailConfirmChange = (data) => {
        dispatch(
            {
                type: ACTIONS.setEmailConfirm,
                payload: { emailConfirm: data }
            }
        );
    };
    const handlePasswordChange = (data) => {
        dispatch(
            {
                type: ACTIONS.setPassword,
                payload: { password: data }
            }
        );
    };
    const handleSend = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (
            !form.state.isSending

            && '' !== form.data.email
            && '' !== form.data.emailConfirm
            && '' !== form.data.password

            && form.state.isEmailValid
            && form.state.isEmailConfirmValid
			&& form.state.isEmailAndEmailConfirmMatch
            && form.state.isPasswordValid
        ) {
            dispatch({ type: ACTIONS.startSending });

            api.register(
                {
                    'email': form.data.email,
                    'email_confirm': form.data.emailConfirm,
                    'password': form.data.password
                }
            ).then(response => {
                dispatch(
                    {
                        type: ACTIONS.terminateSending,
                        payload: response
                    }
                );

                const path = searchParams.get('path');
                if (null !== path) {
                    navigate(path);
                    return;
                }

                if (response.ok) navigate('/');
            });
        }
    };

	const handleEnter = useCallback((e) => {
		if (e.key === "Enter") {
			e.preventDefault();
        	e.stopPropagation();

			if (
				'' !== form.data.email
				&& '' !== form.data.password
				&& form.state.isEmailValid
				&& form.state.isPasswordValid
			) {
				handleSend(e);
			}
		}
	}, []);
	useEffect(() => {
		document.addEventListener("keydown", handleEnter, false);

		return () => {
		  	document.removeEventListener("keydown", handleEnter, false);
		};
	}, []);

  	return (
		<div className="container">
			<section className="section">
				<div className="columns">
					<div className="column is-3"></div>
					<div className="column">
						<div className="content">
							<h1 className="title">Open an Account</h1>

							{
								form.state.api.failed
								&& <ErrorBlock message={form.state.api.errorMessage}/>
							}

							<div className="field mb-0">
								<div className="control has-icons-left has-icons-right">
									<input
										className={`input ${
											'' !== form.data.email
											&& !form.state.isEmailValid
											&& 'is-danger'
										}`}
										type="email"
										placeholder="Email"
										value={form.data.email}
										onChange={
											(e) => handleEmailChange(e.target.value)
										}
										autoFocus={true}
									/>
									<span className="icon is-small is-left">
										<FaEnvelope />
									</span>
									{
										'' !== form.data.email
										&& (
											<>
												{
													form.state.isEmailValid
													&& <span className="icon is-small is-right">
														<FaCheck/>
													</span>
												}

												{
													!form.state.isEmailValid
													&& <span className="icon is-small is-right">
														<FaExclamationTriangle/>
													</span>
												}
											</>
										)
									}
								</div>
								{
									'' !==form.data.email
									&& !form.state.isEmailValid
									&& <p
										className="help is-danger"
									>Invalid email</p>
								}
							</div>
							<p className="help">We need your email to tell you if you win.</p>

							<div className="field">
								<div className="control has-icons-left has-icons-right">
									<input
										className={`input ${
											'' !== form.data.emailConfirm
											&& (
												!form.state.isEmailConfirmValid
												|| !form.state.isEmailAndEmailConfirmMatch
											)
											&& 'is-danger'
										}`}
										type="email"
										placeholder="Confirm email"
										value={form.data.emailConfirm}
										onChange={
											(e) => handleEmailConfirmChange(e.target.value)
										}
									/>
									<span className="icon is-small is-left">
										<FaEnvelope />
									</span>
									{
										'' !== form.data.emailConfirm
										&& (
											<>
												{
													form.state.isEmailConfirmValid
													&& form.state.isEmailAndEmailConfirmMatch
													&& <span className="icon is-small is-right">
														<FaCheck/>
													</span>
												}

												{
													(
														!form.state.isEmailConfirmValid
														|| !form.state.isEmailAndEmailConfirmMatch
													)
													&& <span className="icon is-small is-right">
														<FaExclamationTriangle/>
													</span>
												}
											</>
										)
									}
								</div>
								{
									'' !==form.data.emailConfirm
									&& !form.state.isEmailConfirmValid
									&& <p
										className="help is-danger"
									>Invalid confirm email</p>
								}
								{
									'' !==form.data.emailConfirm
									&& !form.state.isEmailAndEmailConfirmMatch
									&& <p
										className="help is-danger"
									>Email and confirm email should match</p>
								}
							</div>

							<div className="field mb-0">
								<div className="control has-icons-left has-icons-right">
									<input
										className={`input ${
											'' !== form.data.password
											&& !form.state.isPasswordValid
											&& 'is-danger'
										}`}
										type="password"
										placeholder="Create a password"
										value={form.data.password}
										onChange={
											(e) => handlePasswordChange(e.target.value)
										}
									/>
									<span className="icon is-small is-left">
										<FaLock />
									</span>
									{
										'' !== form.data.password
										&& (
											<>
												{
													form.state.isPasswordValid
													&& <span className="icon is-small is-right">
														<FaCheck/>
													</span>
												}

												{
													!form.state.isPasswordValid
													&& <span className="icon is-small is-right">
														<FaExclamationTriangle/>
													</span>
												}
											</>
										)
									}
								</div>
								{
									'' !== form.data.password
									&& !form.state.isPasswordValid
									&& <p className="help is-danger">Must be 8 characters or longer - at least one small and one capital letter, one digit and one symbol (i.e. #, $, @).</p>
								}
							</div>
							{
								'' !== form.data.password
								&& form.state.isPasswordValid
								&& <>
									{
										form.state.passwordStrength === 'WEAK'
										&& <p className="help has-text-grey-light">Strength: Weak</p>
									}
									{
										form.state.passwordStrength === 'MEDIUM'
										&& <p className="help has-text-info">Strength: Medium</p>
									}
									{
										form.state.passwordStrength === 'STRONG'
										&& <p className="help has-text-success">Strength: Strong</p>
									}
								</>
							}

							<div className="field mt-3">
								<p className="control is-pulled-right">
									<button
										className="button is-primary is-rounded"
										onClick={handleSend}
										disabled={
											'' === form.data.email
											||  '' === form.data.password

											|| !form.state.isEmailValid
											|| !form.state.isPasswordValid
										}
									>
										Create an Account
										{
											form.state.isSending
											&& <span className="cl-button-loader"></span>
										}
									</button>
								</p>
							</div>
							<br />
							<br />
						</div>
						<div className="content">
							<div className="content is-flex is-justify-content-center">
								<p>
									Already have an account?&nbsp;
									<Link
										to="/login"
										className="link-standard"
									>Log in</Link>
								</p>
							</div>
						</div>
					</div>
					<div className="column is-3"></div>
				</div>
			</section>
		</div>
  	)
}

export default OpenAccount