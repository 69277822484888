import { useEffect, useState } from "react";


const FiatFormatter = ({type, data = false}) => {
    const [amount, setAmount] = useState(0);

    const tightFormat = (amount) => {
        let nIterations = 0;
        while (amount > 999) {
            amount /= 1000;
            nIterations++;
        }
        if (amount % amount.toFixed() > 0) amount = amount.toFixed(1);
        amount += '';
        if (amount.endsWith(".0")) amount = amount.replace(/(.0$)/, '');

        return amount + { 0: '', 1: 'K', 2: 'M', 3: 'B' }[nIterations];
    };

    const detailFormat = (amount) => {
        return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    };

    const removeZerosAtTheEnd = (string) => {
        if (string.slice(-3) === '.00') {
            string = string.slice(0, -3);
        } else if(string.slice(-1) === '0') {
            string = string.slice(0, -1);
        }

        return string;
    };

    useEffect(() => {
        if (data) {
            switch (type) {
                case 'JACKPOT':
                case 'TICKET':
                    setAmount(tightFormat(data.value));
                    break;
                case 'DETAIL':
                    setAmount(detailFormat(data.value));
                    break;
                case 'DETAIL_NO_ZEROS':
                    setAmount(
                        removeZerosAtTheEnd(
                            detailFormat(data.value)
                        )
                    )
                    break;
                default:
                    setAmount(data.value);
            }
        }
    }, [data]);

    return (
        data
        && <>
            {
                'LEFT' === data.position_of_symbol
                && <>{data.symbol}</>
            }
            {amount}
            {
                'RIGHT' === data.position_of_symbol
                && <>{data.symbol}</>
            }
        </>
    );
}

export default FiatFormatter;