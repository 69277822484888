const PrivacyPolicy = () => {
  	return (
    	<div className="container">
			<section className="section">
				<h1 className="title">Privacy Policy</h1>
				<p>CoinLottery (coinlottery.bet) is committed to protecting the privacy of our users and promises to collect, process, and share your data safely and securely when you use our platform and in any interactions you have with us. Our Privacy Policy explains how we do this and what your rights are. Any personal data processed in accordance with our Privacy Policy is controlled by us as the data controller and in line with Data Protection legislation.</p>


				<h2 className="subtitle">Information We Collect About You</h2>
				<p className="ml-5">
					<ul>
						<li>&bull; Personal and contact information, including your name, email address, CoinLottery account username, social media username, title, date of birth, gender, and security question answers, when you create a CoinLottery account or update that information in your account.</li>
						<li>&bull; Details and copies of your communications and interactions with us through our customer support team or other means, including email, telephone, post, online through webchat, the feedback button, or on CoinLottery social media channels.</li>
						<li>&bull; Copies of documents you provide to verify your identity, including a driving license or passport, when claiming a prize or when requested by law.</li>
						<li>&bull; Transaction details, including a record of payments made by you and to you, details of CoinLottery games played, and the outcome of those games.</li>
						<li>&bull; Financial information, including your crypto account when playing by direct debit, and payment card information when you make a purchase by loading your CoinLottery account wallet. Payment card information will be collected and processed securely by a third-party provider.</li>
						<li>&bull; Personal and contact information, including your image, voice, footage, and any other information that identifies you, when contributing to our advertising and/or media content, when completing the contributor's consent/release form, or in any correspondence with us about your contribution.</li>
						<li>&bull; Technical information about your device or browser, including geolocation data to determine your location, IP address, device ID, browser type and version, and time zone setting, which may in some cases be personal data.</li>
						<li>&bull; We may also process personal data received directly from you or from various third parties and public sources, including social media channels and other third parties.</li>
					</ul>
				</p>


				<h2 className="subtitle">How and why we use your personal data</h2>
				<p>In the table below, we set out all of the ways we use your personal data and the reasons for doing so. We have also specified our legitimate interests where applicable.</p>
				<p>It is sometimes necessary for us to process your personal data to enter into a contract with you or to fulfill a contractual requirement, or to comply with a legal obligation (referred to as "performance of a contract" or "compliance with a legal obligation" below). In such cases, if you do not provide the required personal data, we will not be able to provide our games or other services to you.</p>
				<table className="table">
					<thead>
						<tr>
							<td></td>
							<th>How we use your personal data</th>
							<th>Why we use your personal data</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>Registration</td>
							<td>We use your email address to register you as a player on CoinLottery.</td>
							<td>Performance of a contract with you.</td>
						</tr>
						<tr>
							<td>Eligibility</td>
							<td>We use technical information such as your location or time zone, the personal details you provide at account setup, and any identity documents you provide to determine your eligibility to play our games.</td>
							<td>Compliance with a legal obligation.</td>
						</tr>
						<tr>
							<td>Verification</td>
							<td>In some cases, we may need to verify your identity through copies of documents in order to comply with legal requirements or fulfill requests regarding your CoinLottery account. For example, in the event of a deceased player, we need certain verification information to close that player's account.</td>
							<td>Compliance with a legal obligation.</td>
						</tr>
						<tr>
							<td>Playing our games</td>
							<td>We use your personal and contact information, financial information, and transaction details about your purchases to provide you with our games and other services.</td>
							<td>Performance of a contract with you.</td>
						</tr>
						<tr>
							<td>Managing your account</td>
							<td>We use your personal and contact information, financial information, and transaction details about your CoinLottery purchases to manage your CoinLottery account, notify you of winnings, and pay out prizes.</td>
							<td>Performance of a contract with you.</td>
						</tr>
						<tr>
							<td>Paying prizes</td>
							<td>We use your BTC address and personal details to pay the prize.</td>
							<td>Performance of a contract with you.</td>
						</tr>
						<tr>
							<td>Communicating with You</td>
							<td>
								We use the personal and contact information you provide us, copies of your communications with us, and in some cases, transaction details, to manage our relationship with you. This includes:
								<ul>
									<li>&bull; Notifying you about changes to our Account Terms or Privacy Policy.</li>
									<li>&bull; Responding to your queries, requests, or complaints through email, telephone, or web chat.</li>
								</ul>
							</td>
							<td>
								<ul>
									<li>&bull; It is necessary for the performance of a contract with you.</li>
									<li>&bull; It is necessary to comply with a legal obligation.</li>
									<li>&bull; It is necessary for our legitimate interests, such as keeping our records updated and studying how players play our games.</li>
								</ul>
							</td>
						</tr>
						<tr>
							<td>Staff Training</td>
							<td>We use copies of your communications with us, including voice recordings, to train our staff.</td>
							<td>This is necessary for our legitimate interests, as it ensures that we can provide the best service to you.</td>
						</tr>
						<tr>
							<td>Market Research</td>
							<td>We may use your personal and contact information to invite you to take part in market research or surveys. The results of these surveys are used to improve our games, app, website, or service.</td>
							<td>This is necessary for our legitimate interests, as it allows us to assess and improve our business.</td>
						</tr>
						<tr>
							<td>Prize Draws and Competitions</td>
							<td>We may use your personal and contact information, transaction details, and financial information as part of a prize draw or competition. This includes entering, taking part, and paying any prizes that are due as part of the prize draw or competition.</td>
							<td>This is necessary for the performance of a contract with you.</td>
						</tr>
						<tr>
							<td>Technical Issues</td>
							<td>If you contact us about an issue, we may use your technical information, personal and contact information, to administer and protect our business and website. This includes troubleshooting, data analysis, testing, system maintenance, support, reporting, and hosting of data.</td>
							<td>
								<ul>
									<li>&bull; It is necessary for our legitimate interests, such as running CoinLottery, providing administration and IT services, and ensuring security and preventing fraud.</li>
									<li>&bull; It is necessary to comply with a legal obligation.</li>
								</ul>
							</td>
						</tr>
						<tr>
							<td>Experience and Marketing</td>
							<td>We use information about your use of our app or website, technical information about your device, and in some cases, your personal and contact information and other relevant information, to deliver relevant website content, prize draws, competitions, and other direct marketing to you on various channels, including social media platforms. We may also use this information to manage the delivery of that content. For example, we may automatically display relevant advertisements to you based on your use of our website. </td>
							<td>This is necessary for our legitimate interests, as it ensures that you receive relevant content based on your use of our app or website and the CoinLottery games you have played.</td>
						</tr>
						<tr>
							<td>Advertising Effectiveness</td>
							<td>We use information about your use of our app or website and in some cases, your personal and contact information, to measure or understand the effectiveness of the advertising we serve to you.</td>
							<td>This is necessary for our legitimate interests, as it allows us to determine how effective advertising is and improve it.</td>
						</tr>
						<tr>
							<td>Analytics</td>
							<td>We use information about your use of our app or website, technical information, including information about your device or location, to optimize our service and improve our app, website, games, services, direct marketing, player relationships, behavior profiling, and experiences.</td>
							<td>This is necessary for our legitimate interests, as it allows us to measure interactions with our website.</td>
						</tr>
						<tr>
							<td>Winners</td>
							<td>If you win on a CoinLottery game, we may use your personal and contact information to get in touch with you and provide you with necessary support.</td>
							<td>This is required to fulfill our legitimate interests, such as enhancing the games we offer and providing efficient services to our winners.</td>
						</tr>
						<tr>
							<td>Publicity</td>
							<td>We may feature your personal and limited contact information in marketing materials or events, but only with your explicit consent.</td>
							<td>This is based on your consent.</td>
						</tr>
						<tr>
							<td>Contribution to marketing materials</td>
							<td>The personal data you contribute, such as an interview or a photo you grant us the rights to use, may be utilized for our marketing content across various media.</td>
							<td>This is necessary for our legitimate interests, such as evaluating and publishing your contribution, and for the performance of our contract with you, including rights management, payment processing, and communication regarding your contribution.</td>
						</tr>
						<tr>
							<td>Regulatory compliance</td>
							<td>To fulfill our legal and regulatory obligations, we may need to use your personal information, contact details, financial information, transaction history with us, records of communications with us (including voice recordings), information on how you use the app or website, and technical information. This includes reporting aggregate data to the Gambling Commission, implementing responsible gambling measures, and providing the Gambling Commission with required information.</td>
							<td>This is necessary to comply with legal obligations.</td>
						</tr>
						<tr>
							<td>Fraud prevention</td>
							<td>To detect and prevent fraud, we may analyze your personal and contact information, financial information, transaction history with us, records of communications with us (including voice recordings), information on how you use the app or website, and technical information.</td>
							<td>This is required to comply with legal obligations and for our legitimate interests, such as ensuring that transactions and interactions with us are legitimate.</td>
						</tr>
						<tr>
							<td>Queries and investigations</td>
							<td>For the purpose of investigating prize claims or addressing any requests, complaints, or queries from you, we may use your personal and contact information, financial information, transaction history with us, records of communications with us (including voice recordings), information on how you use the app or website, and technical information.</td>
							<td>This is necessary to comply with legal obligations and for our legitimate interests, such as providing a response to you and resolving any claims you may make.</td>
						</tr>
						<tr>
							<td>Accessibility and vulnerable players</td>
							<td>By using the information you provide, we aim to identify and provide appropriate support to players who may have accessibility needs or are considered vulnerable.</td>
							<td>This is based on your consent and is necessary for our legitimate interests, such as offering the most suitable support for each player's unique circumstances.</td>
						</tr>
					</tbody>
				</table>


				<h2 className="subtitle">How we use your personal data for direct marketing</h2>
				<p>At CoinLottery, we use your personal data for direct marketing purposes to ensure that you have a great experience when playing our games and receiving our communications. We send marketing communications through various channels such as email, mobile device notifications, and advertisements served by our advertising partners across the internet.</p>
				<p>You will receive direct marketing by email if you have registered for a CoinLottery account through our website and have either given your consent for email marketing during the registration process or if we have a legitimate interest in sending you email marketing and you have not requested us to stop.</p>

				<h3>Opting Out of Direct Marketing</h3>
				<p>You have the option to opt-out of receiving direct marketing communications and notifications from us at any time. Here are the ways you can do this:</p>
				<p>
					<ul>
						<li>&bull; To stop receiving marketing emails, either click the 'unsubscribe' link in any email we send you, or login to your CoinLottery account and adjust your email preferences, or write to us.</li>
						<li>&bull; To stop receiving mobile device app push notifications, turn off the relevant option in the 'Settings' section of your device. You can find out how to do this on our FAQs page.</li>
						<li>&bull; To stop receiving web push notifications in your browser, adjust the relevant options in the 'Settings' section of your browser. Our help page provides information on how to do this in various browsers.</li>
					</ul>
				</p>

				<h3>Personalisation</h3>
				<p>We want to personalise your experience and make our communications and content relevant to you. To achieve this, we use information about your gaming habits and how you use our app or website to offer you promotions and content that are of interest to you.</p>

				<h3>Stopping Personalised Direct Marketing</h3>
				<p>If you prefer not to receive personalised direct marketing, you can turn off personalisation in your Personalisation settings within your account. If you do this, you will still receive marketing communications and notifications, but they will not be personalised to you.</p>
				<p>To stop all direct marketing, including both personalised and non-personalised marketing communications and notifications, you must opt-out of both. Please note that it may take up to 48 hours for our systems to update after changing your preferences.</p>

				<h3>Sharing your information with marketing partners</h3>
				<p>We may share your data with our advertising partners, such as advertisers, advertising networks, and advertising agencies, to provide you with targeted advertising or to exclude you from such advertising. Our advertising partners may use technologies like cookies, web beacons, device IDs, and others to collect information about your use of our app or website and your browsing habits. We may also share your data with social media providers, such as Facebook, to show you relevant ads while using that platform.</p>
				<p>You have the option to opt-out of cookies and other technologies being used for marketing purposes. Please go to our Manage your cookies page for information on how to do this.</p>


				<h2 className="subtitle">How we share your data with third parties</h2>
				<p>At times, we may share the personal information we gather from you with the following third parties:</p>
				<p className="ml-5">
					<ul>
						<li>&bull; Payment processors - for establishing a direct debit on your account and for paying out awards.</li>
						<li>&bull; Annuity policy providers and financial brokers - for the purpose of arranging annuity award payments.</li>
						<li>&bull; IT service providers - for setting up your player account, communicating with you through webchat and other communication channels, and for providing us with software and hosting, support, and licensing.</li>
						<li>&bull; Marketing service providers - to send email communications and notifications.</li>
						<li>&bull; Marketing agencies - for presenting you with targeted advertising or marketing on our app, website, or social media and for conducting behavioral modeling.</li>
						<li>&bull; Social media platforms - for targeted advertising.</li>
						<li>&bull; Online survey companies and market research agencies - for conducting surveys, market research, and analyzing results.</li>
						<li>&bull; Digital analytics companies - for website analytics and marketing reporting.</li>
						<li>&bull; Public relations agencies - for managing external communications about us and our winners.</li>
						<li>&bull; Professional advisers including lawyers, bankers, auditors, and insurers - for providing consultancy, banking, legal, insurance, and accounting services.</li>
						<li>&bull; Gambling Commission - for legal and regulatory purposes, as required under our CoinLottery license.</li>
						<li>&bull; Police and other regulatory authorities - for legal and regulatory purposes or as part of an investigation.</li>
						<li>&bull; Dispute resolution agencies - for resolving complaints.</li>
						<li>&bull; Credit reference agencies - for verifying the information you have provided us and for complying with regulatory obligations.</li>
						<li>&bull; Security companies and research institutions - for security purposes, including protection against fraud and safeguarding CoinLottery.</li>
						<li>&bull; Auditors - for auditing our systems and transactions to ensure efficiency and compliance with regulations or contracts.</li>
					</ul>
				</p>
				<p>We may disclose your personal information to other third parties if required by law or regulation, or for protecting our rights, property, or safety or that of our players or others. This includes sharing information with other organizations for the purpose of reducing fraud risk and credit risk.</p>


				<h2 className="subtitle">How we protect your personal data</h2>
				<p>We have implemented several measures to secure your personal information:</p>
				<p>
					<ul>
						<li>&bull; Our online security protocols guarantee the confidentiality of secure card payments, and we are compliant with the payment card data security standards (PCI-DSS). No card details are stored by us.</li>
						<li>&bull; Our website and app use https encryption to protect all information exchanged, and this can be seen in the address bar of your browser.</li>
						<li>&bull; As stated in this Privacy Policy, we may share your personal information with third parties, but we require them to implement appropriate technical and organizational measures to protect it. In some cases, we may be required by law to share your personal information with a third party, over which we have limited control on its protection.</li>
						<li>&bull; Our website and app undergo regular audits by an independent auditor to maintain our security certifications.</li>
					</ul>
				</p>


				<h2 className="subtitle">How long we keep your personal data</h2>
				<p>We ensure that your personal information is only kept for as long as it is necessary to fulfill the purpose it was collected for. This includes meeting any legal, accounting or reporting obligations.</p>
				<p>Once the retention period is over, your data will either be deleted or anonymized so that it cannot be linked to you. It may then be used for research or statistical purposes.</p>
				<p>When determining the appropriate retention period for your personal information, we consider various factors including the type, amount and sensitivity of the data, the potential risk of harm from unauthorized use or disclosure, whether the purpose for processing the data can be achieved through other means, and any applicable legal requirements.</p>


				<h2 className="subtitle">Contact Us</h2>
				<p>If you have any questions, concerns or requests regarding your personal data, or if you would like to exercise your rights in relation to your personal data, please feel free to contact us by email at <a href="mailto:support@coinlottery.bet">support@coinlottery.bet</a>.</p>
				<p>We will make every effort to respond to you as soon as possible and to resolve any issues you may have in relation to your personal data.</p>


				<h2 className="subtitle">Changes</h2>
				<p>Please note that this Privacy Policy may be updated from time to time to reflect any changes in our privacy practices. This Privacy Policy was last updated 3th February 2023.</p>
			</section>
			<div className="block my-6"></div>
		</div>
  	)
}

export default PrivacyPolicy