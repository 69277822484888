import { Fragment, useState, useEffect } from "react";

import {
    Pagination,
    FiatFormatter,
    InProgressText,
	CryptoFormatter,
    DataNotLoadedErrorMessage
} from '../../../components';
import { useApi } from '../../../hooks';
import { useUserContext } from '../../../context';


const ListOfDraws = ({ gameKey, currentPage = 1 }) => {
    const api = useApi();
    const userContext = useUserContext();

    const [results, setResults] = useState(false);
    const [totalPages, setTotalPages] = useState(false);

    const [data, setData] = useState([]);
    const [error, setError] = useState(false);
    const [isPending, setIsPending] = useState(true);
    useEffect(() => {
        api.getGameDraws(gameKey, currentPage).then(response => {
            setIsPending(false);

            if (!response.ok) {
                setError(true);
                return;
            }

            setData(response.data);
        });
    }, [gameKey, currentPage]);

    useEffect(() => {
        if (data) {
            setResults(data.results);
            setTotalPages(data.totalPages);
        }
    }, [data]);

    return (
        <>
            {error && <DataNotLoadedErrorMessage />}
			{isPending && <InProgressText/>}
            {
                results
                && (
                    <>
                        <table className="table tableList is-size-7 mt-6">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Jackpot</th>
                                    <th>Ball numbers</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    results.length > 0
                                    ? results.map((draw, index, draws) => (
                                        <Fragment key={`draw-${draw.id}`}>
                                            {
                                                (
                                                    index === 0 ||
                                                    (
                                                        index > 0 &&
                                                        draws[index - 1].monthAndYear !== draw.month_and_year
                                                    )
                                                ) &&
                                                <tr className="noHover">
                                                    <th colSpan="3">{draw.month_and_year}</th>
                                                </tr>
                                            }

                                            <tr>
                                                <td>{draw.datetime}</td>
                                                <td>
                                                    {
                                                        false === draw.jackpot
                                                        ? '- - - - -'
                                                        : <>
                                                            <p>
                                                                <CryptoFormatter
                                                                    type="DETAIL"
                                                                    data={draw.jackpot.crypto}
                                                                />&nbsp;
                                                                (<FiatFormatter
                                                                    type="DETAIL"
                                                                    data={draw.jackpot.fiat[userContext.currency_code]}
                                                                />)
                                                            </p>
                                                            <p className="is-size-7">
                                                                Donated:&nbsp;
                                                                <CryptoFormatter
                                                                    type="DETAIL"
                                                                    data={draw.donation.crypto}
                                                                />&nbsp;
                                                                (<FiatFormatter
                                                                    type="DETAIL"
                                                                    data={draw.donation.fiat[userContext.currency_code]}
                                                                />) to &nbsp;
                                                                {
                                                                    draw.partners.map((partner, indexParent) => (
                                                                        <Fragment key={`partner-${indexParent}-of-draw-${draw.id}`}>
                                                                            {
                                                                                indexParent > 0 &&
                                                                                <>
                                                                                    {
                                                                                        indexParent < draw.partners.length - 1
                                                                                        ? ', '
                                                                                        : ' and '
                                                                                    }
                                                                                </>
                                                                            }
                                                                            <a
                                                                                href={partner.url}
                                                                                className="link-standard"
                                                                                target="_blank"
                                                                                rel="noreferrer nofollow"
                                                                            >{partner.name}</a>
                                                                        </Fragment>
                                                                    ))
                                                                }
                                                            </p>
                                                        </>
                                                    }
                                                </td>
                                                <td>{draw.numbers.join(', ')}</td>
                                            </tr>
                                        </Fragment>
                                    ))
                                    : (
                                        <tr>
                                            <th colSpan="3">No draw history yet.</th>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>

                        <Pagination
                            url={`/game/${gameKey}/draws`}
                            page={currentPage}
                            totalPages={totalPages}
                            numberOfResults={results.length}
                        />
                    </>
                )
            }
        </>
    );
}

export default ListOfDraws;