import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from 'react-router-dom';

import {
	MenuListOfGamesMobile,
	DataNotLoadedErrorMessage
} from '../../components';
import { useApi } from '../../hooks';
import { getValidPage } from '../../services';
import ListOfDraws from './Lists/ListOfDraws';
import { useUserContext, useGamesContext } from '../../context';
import MenuResultsAndDrawsMobile from './Components/MenuResultsAndDrawsMobile';
import MenuResultsAndDrawsDesktop from './Components/MenuResultsAndDrawsDesktop';

const DrawHistory = () => {
	const userContext = useUserContext();

	const api = useApi();
	const navigate = useNavigate();

	let { page } = useParams();
	page = getValidPage(page);
	if (!page) navigate.push('/page-not-found');

	const { key } = useParams();

	const [game, setGame] = useState(false);
	const gameContext = useGamesContext();
	useEffect(() => {
		if (gameContext.games) {
			for (let i = 0; i < gameContext.games.length; i++) {
				if (gameContext.games[i].key === key) {
					setGame(gameContext.games[i]);
					break;
				}
			}
		}
	}, [gameContext, key]);

	const [drawsForDownload, setDrawsForDownload] = useState(false);

	const [allDraws, setAllDraws] = useState([]);
    const [errorAllDraws, setErrorAllDraws] = useState(false);
    const [isPendingAllDraws, setIsPendingAllDraws] = useState(true);
    useEffect(() => {
        api.getGameDraws(key).then(response => {
            setIsPendingAllDraws(false);

            if (!response.ok) {
                setErrorAllDraws(true);
                return;
            }

            setAllDraws(response.data);
        });
    }, []);

	const detailFiatFormat = (amount) => {
		const data = amount[userContext['currency_code']];
        let value = data['value'].toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

		if ('LEFT' === data.position_of_symbol) {
			value = data.symbol + value;
		} else {
			value = value + data.symbol;
		}

		return value;
    };

	useEffect(() => {
		if (allDraws && game) {
			let draws = [];
			let partners = [];

			for (let i = 0; i < allDraws.length; i++) {
				partners = []
				for (let k = 0; k < allDraws[i]['partners'].length; k++) {
					partners.push(allDraws[i]['partners'][k]['name']);
				}
				partners = partners.join(', ')

				draws.push(
					{
						"Game": game['name'],
						"Draw number": allDraws[i]['number'],
						"Date": allDraws[i]['datetime'],
						"Jackpot": (
							false === allDraws[i]['jackpot']
							? '- - - - -'
							: allDraws[i]['jackpot']['crypto']['value'] + " " + allDraws[i]['jackpot']['crypto']['code'] + " (" + detailFiatFormat(allDraws[i]['jackpot']['fiat']) + ")"
						),
						"Donation": (
							false === allDraws[i]['donation']
							? '- - - - -'
							: allDraws[i]['donation']['crypto']['value'] + " " + allDraws[i]['donation']['crypto']['code'] + " (" + detailFiatFormat(allDraws[i]['donation']['fiat']) + ")"
						),
						"Partners": partners === '' ? '- - - - -' : partners,
						"Numbers": allDraws[i]['numbers'].join(', ')
					}
				);

			}

			setDrawsForDownload(draws);
		}
	}, [allDraws, game]);

	const getDateTime = () => {
		var currentdate = new Date();

		const year = currentdate.getFullYear();

		let month = currentdate.getMonth() + 1;
		month = month > 9 ? month : "0" + month;

		let day = currentdate.getDate();
		day = day > 9 ? day : "0" + day;

		let hour = currentdate.getHours();
		hour = hour > 9 ? hour : "0" + hour;

		let min = currentdate.getMinutes();
		min = min > 9 ? min : "0" + min;

		let sec = currentdate.getSeconds();
		sec = sec > 9 ? sec : "0" + sec;

		return year + "-" + month + "-" + day +
			"@" + hour + "-" + min + "-" + sec;
	};

	const exportToCSV = () => {
		if (!isPendingAllDraws && !errorAllDraws) {
			const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
			const fileExtension = ".xlsx";
			const fileName = 'CoinLottery, Draw History of ' + game['name'] + ", exported " + getDateTime()

			const ws = XLSX.utils.json_to_sheet(drawsForDownload);
			const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
			const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
			const data = new Blob([excelBuffer], { type: fileType });
			FileSaver.saveAs(data, fileName + fileExtension);
		}
	};

  	return (
		<div className="container">
			{
				!game
				&& <div className="mt-3">
					<DataNotLoadedErrorMessage/>
				</div>
			}

			{
				game
				&& <>
					<div className="is-fixed-top is-hidden-desktop">
						<MenuListOfGamesMobile
							games={gameContext.games}
							urlEnding="/draws"
						/>
						{
							userContext.isAuthenticated
							&& <MenuResultsAndDrawsMobile gameKey={game.key} />
						}
					</div>

					<section className="section">
						<div className="content">
							<h1 className="title">{game.name} Results</h1>
						</div>
						<div className="content is-hidden-touch">
							{
								userContext.isAuthenticated
								&& <MenuResultsAndDrawsDesktop
									gameKey={game.key}
									gameColor={game.color}
								/>
							}
						</div>
						<div className="content mt-6">
							<h2 className="title is-4">Draw History</h2>
							<p>SuperGame draw details for the previous 180 days with the most recent draws shown at the top.</p>
							<div className="columns">
								<div className="column is-2">
									<Link to="/how-to-claim" className="link-standard">How to claim your prize</Link>
								</div>
								<div className="column">
									{
										drawsForDownload.length > 0
										&& <button
											onClick={exportToCSV}
											className="link-standard is-size-6"
										>Download draw history (CSV format)</button>
									}
								</div>
							</div>

							<ListOfDraws
								gameKey={game.key}
								currentPage={page}
							/>
						</div>
					</section>
					<div className="block my-6"></div>
				</>
			}
		</div>
  	)
}

export default DrawHistory;