import {
    FaKey,
    FaCheck,
    FaQuestion,
    FaRegComment,
    FaExclamationTriangle
} from 'react-icons/fa';
import {
	Link,
	useParams,
	useNavigate,
	useSearchParams
} from 'react-router-dom';
import { useState, useEffect, useReducer, useCallback } from 'react';

import { useApi } from '../../hooks';
import { isInteger, isValidTwoFACode } from '../../services';
import { CountdownTimer, ErrorBlock } from '../../components';


const ACTIONS = {
    setTwoFACode: 'SET_TWO_FA_CODE',
    setSecurityQuestion: 'SET_SECURITY_QUESTIONS',
    setSecurityAnswer: 'SET_SECURITY_ANSWER',

    startSending: 'START_SENDING',
    terminateSending: 'TERMINATE_SENDING'
};

function reducer(form, action) {
    let newForm = {...form};
    const resetData = (newForm) => {
        newForm.data.twoFACode = '';
        newForm.data.securityQuestionId = 0;
        newForm.data.securityAnswer = '';
    };
    const resetState = (newForm) => {
        newForm.state.isTwoFACodeValid = true;
        newForm.state.isSecurityQuestionValid = true;
        newForm.state.isSecurityAnswerValid = true;

        newForm.state.isSending = false;
        newForm.state.api.failed = false;
        newForm.state.api.errorMessage = '';
    };
    const validate = (newForm) => {
        newForm.state.isTwoFACodeValid =
            isValidTwoFACode(newForm.data.twoFACode);
        newForm.state.isSecurityQuestionValid =
            isInteger(newForm.data.securityQuestionId);
        newForm.state.isSecurityAnswerValid =
            newForm.data.securityAnswer.length > 0;

        newForm.state.api.failed = false;
        newForm.state.api.errorMessage = '';
    };

    switch (action.type) {
        case ACTIONS.setTwoFACode:
            newForm.data.twoFACode = action.payload.twoFACode;
            validate(newForm);

            return newForm;
        case ACTIONS.setSecurityQuestion:
            newForm.data.securityQuestionId = action.payload.securityQuestionId;
            validate(newForm);

            return newForm;
        case ACTIONS.setSecurityAnswer:
            newForm.data.securityAnswer = action.payload.securityAnswer;
            validate(newForm);

            return newForm;
        case ACTIONS.startSending:
            newForm.state.isSending = true;

            return newForm;
        case ACTIONS.terminateSending:
            newForm.state.isSending = false;

            if (action.payload.ok) {
                resetData(newForm);
                resetState(newForm);
            } else {
                newForm.data.twoFACode = '';
                newForm.state.isTwoFACodeValid = true;

                newForm.state.api.failed = true;
                newForm.state.api.errorMessage = action.payload.error.message;
            }

            return newForm;
        default:
            return newForm;
    }
};

const LoginSecure = () => {
	const api = useApi();

    const { key } = useParams();
    const [isPending, setIsPending] = useState(true);
    const [expiresIn, setExpiresIn] = useState(false);
	const [isVerified, setIsVerified] = useState(false);
	const [isTwoFAEnabled, setIsTwoFAEnabled] = useState(false);
	const [securityQuestions, setSecurityQuestions] = useState(false);
	const [isSecurityQuestionEnabled, setIsSecurityQuestionEnabled] = useState(false);
	const [isCountdownTimerTerminated, setIsCountdownTimerTerminated] = useState(false);

    useEffect(() => {
        api.validateLoginSecureKey({ 'key': key }).then(response => {
            setIsVerified(response.ok);
			if (response.ok) {
				setExpiresIn(response.data.expires_in);
				setIsTwoFAEnabled(response.data.is_two_fa_enabled);
				setIsSecurityQuestionEnabled(response.data.is_security_question_enabled);
			}

			api.getSecurityQuestions().then(response => {
				setIsPending(false);

				if (response.ok) setSecurityQuestions(response.data);
			});
        });
    }, []);



	const navigate = useNavigate();
    const [form, dispatch] = useReducer(reducer, {
        data: {
            twoFACode: '',
            securityQuestionId: 0,
            securityAnswer: ''
        },
        state: {
            isTwoFACodeValid: true,
            isSecurityQuestionValid: true,
            isSecurityAnswerValid: true,

            isSending: false,
            api: {
                failed: false,
                errorMessage: ''
            }
        }
    });

    const [searchParams] = useSearchParams();

    const handleTwoFACodeChange = (data) => {
        dispatch(
            {
                type: ACTIONS.setTwoFACode,
                payload: { twoFACode: data }
            }
        );
    };
    const handleSecurityQuestionChange = (data) => {
        dispatch(
            {
                type: ACTIONS.setSecurityQuestion,
                payload: { securityQuestionId: data }
            }
        );
    };
    const handleSecurityAnswerChange = (data) => {
        dispatch(
            {
                type: ACTIONS.setSecurityAnswer,
                payload: { securityAnswer: data }
            }
        );
    };
    const handleSend = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (
            !form.state.isSending
			&& (
				(
					// isTwoFAEnabled
					// &&
					'' !== form.data.twoFACode
					&& form.state.isTwoFACodeValid
				)
				||
				(
					// isSecurityQuestionEnabled
					// &&
					0 !== form.data.securityQuestionId
					&& '' !== form.data.securityAnswer
					&& form.state.isSecurityQuestionValid
					&& form.state.isSecurityAnswerValid
				)
			)
        ) {
            dispatch({ type: ACTIONS.startSending });

            api.loginSecure(
                {
                    'key': key,
                    'twofa_code': 1* form.data.twoFACode,
                    'security_question_id': 1 * form.data.securityQuestionId,
                    'security_answer': form.data.securityAnswer
                }
            ).then(response => {
                dispatch(
                    {
                        type: ACTIONS.terminateSending,
                        payload: response
                    }
                );

                if (response.ok) {
					const path = searchParams.get('path');

					if (null === path) navigate('/settings/profile-and-settings/personal-details');
					else navigate(path);
				}
            });
        }
    };

	const handleEnter = useCallback((e) => {
		if (e.key === "Enter") {
			e.preventDefault();
        	e.stopPropagation();

			if (
				(
					'' !== form.data.twoFACode
					&& form.state.isTwoFACodeValid
				)
				||
				(
					0 !== form.data.securityQuestionId
					&& '' !== form.data.securityAnswer
					&& form.state.isSecurityQuestionValid
					&& form.state.isSecurityAnswerValid
				)
			) {
				handleSend(e);
			}
		}
	}, []);
	useEffect(() => {
		document.addEventListener("keyup", handleEnter, false);

		return () => {
		  	document.removeEventListener("keyup", handleEnter, false);
		};
	}, []);

  	return (
		<div className="container">
			<section className="section">
				<div className="columns">
					<div className="column is-3"></div>
					<div className="column">
						<div className="content">
							<h1 className="title">
								Security Verification
								{
									!isPending
									&& <>
										&nbsp;
										<CountdownTimer
											expiresIn={expiresIn}
											setTerminated={setIsCountdownTimerTerminated}
										/>
									</>
								}
							</h1>
							{
								isPending
								&& <h2>Please wait...</h2>
							}
							{
								!isPending
								&& (
									!isVerified
									|| isCountdownTimerTerminated
								)
								&& <>
									<h2 className="has-text-danger">
										The time window of 5 min. to log in has expired!
									</h2>
									<Link
										className="button is-danger mr-3"
										to='/login'
									>
										Try again
									</Link>
								</>
							}
							{
								!isPending
								&& isVerified
								&& !isCountdownTimerTerminated
								&& securityQuestions
								&& <>
									{
										form.state.api.failed
										&& <ErrorBlock message={form.state.api.errorMessage}/>
									}

									{
										isTwoFAEnabled
										&& <>
											<div className="field mb-0">
												<div className="control has-icons-left has-icons-right">
													<input
														className={`input ${
															'' !== form.data.twoFACode
															&& !form.state.isTwoFACodeValid
															&& 'is-danger'
														}`}
														maxLength="6"
														type="numeric"
														placeholder="Authenticator Code"
														value={form.data.twoFACode}
														onChange={
															(e) => handleTwoFACodeChange(e.target.value)
														}
														autoFocus={true}
													/>
													<span className="icon is-small is-left">
														<FaKey />
													</span>
													{
														'' !== form.data.twoFACode
														&& (
															<>
																{
																	form.state.isTwoFACodeValid
																	&& <span className="icon is-small is-right">
																		<FaCheck/>
																	</span>
																}

																{
																	!form.state.isTwoFACodeValid
																	&& <span className="icon is-small is-right">
																		<FaExclamationTriangle/>
																	</span>
																}
															</>
														)
													}
												</div>
											</div>
											<p className="help">Enter the 6-digit code from Google Authenticator</p>
										</>
									}

									{
										isSecurityQuestionEnabled
										&& <div className="field">
											<div className="control has-icons-left has-icons-right">
												<div className="select">
													<select
														onChange={
															(e) => handleSecurityQuestionChange(
																e.target.value
															)
														}
														value={form.data.securityQuestionId}
													>
														<option value="0">- - - Choose a security question - - -</option>
														{
															securityQuestions.map((question) => (
																<option
																	key={question.id}
																	value={question.id}
																>
																	{question.question}
																</option>
															))
														}
													</select>
												</div>
												<span className="icon is-small is-left">
													<FaQuestion />
												</span>
												{
													0 !== form.data.securityQuestionId
													&& (
														<>
															{
																form.state.isSecurityQuestionValid
																&& <span className="icon is-small is-right">
																	<FaCheck/>
																</span>
															}

															{
																!form.state.isSecurityQuestionValid
																&& <span className="icon is-small is-right">
																	<FaExclamationTriangle/>
																</span>
															}
														</>
													)
												}
											</div>
										</div>
									}

									{
										0 !== form.data.securityQuestionId
										&& form.state.isSecurityQuestionValid
										&& <div className="field">
											<div className="control has-icons-left has-icons-right">
												<input
													className={`input ${
														'' !== form.data.securityAnswer
														&& !form.state.isSecurityAnswerValid
														&& 'is-danger'
													}`}
													type="text"
													placeholder="Answer to the security question"
													value={form.data.securityAnswer}
													onChange={
														(e) => handleSecurityAnswerChange(e.target.value)
													}
													autoFocus={true}
												/>
												<span className="icon is-small is-left">
													<FaRegComment />
												</span>
												{
													'' !== form.data.securityAnswer
													&& (
														<>
															{
																form.state.isSecurityAnswerValid
																&& <span className="icon is-small is-right">
																	<FaCheck/>
																</span>
															}

															{
																!form.state.isSecurityAnswerValid
																&& <span className="icon is-small is-right">
																	<FaExclamationTriangle/>
																</span>
															}
														</>
													)
												}
											</div>
										</div>
									}

									<div className="field">
										<p className="control is-pulled-right">
											<button
												className="button is-primary is-rounded"
												onClick={handleSend}
												disabled={
													(
														isTwoFAEnabled && (
															'' === form.data.twoFACode
															|| !form.state.isTwoFACodeValid
														)
													)
													||
													(
														isSecurityQuestionEnabled && (
															0 === form.data.securityQuestionId
															||  '' === form.data.securityAnswer
															|| !form.state.isSecurityQuestionValid
															|| !form.state.isSecurityAnswerValid
														)
													)
												}
											>
												Submit
												{
													form.state.isSending
													&& <span className="cl-button-loader"></span>
												}
											</button>
										</p>
									</div>
									<br />
									<br />
								</>
							}
						</div>

						{
							!isPending
							&& isVerified
							&& !isCountdownTimerTerminated
							&& <div className="content">
								<div className="content is-flex is-justify-content-center">
									<p>
										Security verification unavailable?&nbsp;
										<Link
											to="/help-and-contact-us"
											className="link-standard"
										>Contact our support</Link>.
									</p>
								</div>
							</div>
						}
					</div>
					<div className="column is-3"></div>
				</div>
			</section>
		</div>
  	)
}

export default LoginSecure