import {
    FaKey,
    FaBtc,
    FaLock,
    FaCheck,
    FaExclamationTriangle,
} from 'react-icons/fa';
import {
    validate as validateBtcAddress,
    getAddressInfo
} from 'bitcoin-address-validation';
import { Link } from 'react-router-dom';
import { useReducer, useEffect, useState } from "react";
import { RiEyeCloseLine, RiEyeLine } from 'react-icons/ri';

import {
    isValidTwoFACode,
    isNotEmptyString,
    isValidShortString,
    isPasswordMinLength,
    hidePartOfTheString
} from '../../../../services';
import {
    InProgressText,
    DataNotLoadedErrorMessage
} from '../../../../components';
import { useApi } from '../../../../hooks';
import { useUserContext } from '../../../../context';


const ACTIONS = {
    set: 'SET',

    enableEditRegime: 'ENABLE_SWITCH_REGIME',
    disableEditRegime: 'DISABLE_SWITCH_REGIME',

    setIsPasswordVisible: 'SET_IS_PASSWORD_VISIBLE',
    setIsBtcAddressCopied: 'SET_IS_BTC_ADDRESS_COPIED',

    setBtcAddress: 'SET_BTC_ADDRESS',
    setPassword: 'SET_PASSWORD',
    setTwoFACode: 'SET_TWO_FA_CODE',

    startSaving: 'START_SAVING',
    terminateSaving: 'TERMINATE_SAVING'
};
function reducer(form, action) {
    let newForm = {...form};
    const resetFields = (newForm, cryptoAccountDetails) => {
        newForm.data.btcAddress = cryptoAccountDetails.btcAddress;
        newForm.data.password = "";
        newForm.data.twoFACode = "";
    };
    const resetStates = (newForm) => {
        newForm.state.api.serverError = false;
        newForm.state.api.validationError = false;

        newForm.state.isBtcAddressValid = true;
        newForm.state.isPasswordValid = true;
        newForm.state.isTwoFACodeValid = true;

        newForm.state.isSaving = false;
        newForm.state.isEditing = false;
    };
    const setFields = (newForm, action) => {
        action.payload.cryptoAccountDetails.btcAddress = newForm.data.btcAddress;
        action.payload.cryptoAccountDetails.password = "";
        action.payload.cryptoAccountDetails.twoFACode = "";
    };
    const validate = (newForm, isSocialAccount) => {
        newForm.state.api.serverError = false;
        newForm.state.api.validationError = false;

        newForm.state.isBtcAddressValid = validateBtcAddress(
            newForm.data.btcAddress
        )

        if (isSocialAccount) {
            newForm.state.isTwoFACodeValid = isValidTwoFACode(
                newForm.data.twoFACode
            );
        } else {
            newForm.state.isPasswordValid = (
                isValidShortString(newForm.data.password)
                && isPasswordMinLength(newForm.data.password)
            );
        }
    };

    switch (action.type) {
        case ACTIONS.set:
            return {
                data: {
                    btcAddress: action.payload.cryptoAccountDetails.btcAddress,
                    password: '',
                    twoFACode: ''
                },
                state: {
                    isBtcAddressValid: true,
                    isPasswordValid: true,
                    isTwoFACodeValid: true,
                    isPasswordVisible: false,
                    isBtcAddressCopied: false,

                    isEditing: false,
                    isSaving: false,
                    api: {
                        serverError: false,
                        validationError: false
                    }
                }
             }
        case ACTIONS.enableEditRegime:
            newForm.state.isEditing = true;

            return newForm;
        case ACTIONS.disableEditRegime:
            resetFields(newForm, action.payload.cryptoAccountDetails);
            resetStates(newForm);

            return newForm;
        case ACTIONS.setIsPasswordVisible:
            newForm.state.isPasswordVisible = action.payload.isShown;

            return newForm;
        case ACTIONS.setIsBtcAddressCopied:
            newForm.state.isBtcAddressCopied = action.payload.isShown;

            return newForm;
        case ACTIONS.setBtcAddress:
            newForm.data.btcAddress = action.payload.btcAddress;
            validate(newForm, action.payload.isSocialAccount);

            return newForm;
        case ACTIONS.setPassword:
            newForm.data.password = action.payload.password;
            validate(newForm, action.payload.isSocialAccount);

            return newForm;
        case ACTIONS.setTwoFACode:
            newForm.data.twoFACode = action.payload.twoFACode;
            validate(newForm, action.payload.isSocialAccount);

            return newForm;
        case ACTIONS.startSaving:
            newForm.state.isSaving = true;

            return newForm;
        case ACTIONS.terminateSaving:
            if (action.payload.isChanged) {
                if (action.payload.isSaved) {
                    setFields(newForm, action);
                    resetStates(newForm);
                } else {
                    newForm.state.isSaving = false;

                    switch (action.payload.error) {
                        case 'SERVER_ERROR':
                            newForm.state.api.serverError = true;
                            newForm.state.api.validationError = false;
                            break;
                        default:
                            validate(newForm, action.payload.isSocialAccount);
                            newForm.state.api.serverError = false;
                            newForm.state.api.validationError = true;
                            break;
                    }
                }
            } else {
                resetFields(newForm, action.payload.cryptoAccountDetails);
                resetStates(newForm);
            }

            return newForm;
        default:
            return newForm;
    }
};

const FormCryptoAccountDetails = () => {
    const api = useApi();
    const userContext = useUserContext();

    const [cryptoAccountDetails, setCryptoAccountDetails] = useState({
        btcAddress: ''
    });
    const [error, setError] = useState(false);
    const [isPending, setIsPending] = useState(true);
    useEffect(() => {
        api.getSettingsCryptoAccountDetails().then(response => {
            setIsPending(false);

            if (!response.ok) {
                setError(true);
                return;
            }

            if (response.data.hasOwnProperty('btc_address')) {
                const d = { btcAddress: response.data.btc_address }

                setCryptoAccountDetails(d);
                dispatch(
                    {
                        type: ACTIONS.set,
                        payload: { cryptoAccountDetails: d }
                    }
                );
            }
        });
    }, []);



    const [form, dispatch] = useReducer(reducer, {
        data: {
            btcAddress: '',
            password: '',
            twoFACode: ''
        },
        state: {
            isBtcAddressValid: true,
            isPasswordValid: true,
            isTwoFACodeValid: true,
            isPasswordVisible: false,
            isBtcAddressCopied: false,

            isEditing: false,
            isSaving: false,
            api: {
                serverError: false,
                validationError: false
            }
        }
    });



    const timeShowingCopiedStatus = 650;
    const timeShowingThePassword = 5000;
    const handleCopying = () => {
        navigator.clipboard.writeText(form.data.btcAddress);

        dispatch({
            type: ACTIONS.setIsBtcAddressCopied,
            payload: { isShown: true }
        });

        setTimeout(() => {
            dispatch({
                type: ACTIONS.setIsBtcAddressCopied,
                payload: { isShown: false }
            });
        }, timeShowingCopiedStatus);
    };
    const handleEdit = () => {
        dispatch({type: ACTIONS.enableEditRegime});
    };
    const showPasswordVisualizing = () => {
        dispatch({
            type: ACTIONS.setIsPasswordVisible,
            payload: { isShown: true }
        });

        setTimeout(() => {
            dispatch({
                type: ACTIONS.setIsPasswordVisible,
                payload: { isShown: false }
            });
        }, timeShowingThePassword);
    };
    const hidePasswordVisualizing = () => {
        dispatch({
            type: ACTIONS.setIsPasswordVisible,
            payload: { isShown: false }
        });
    };
    const handleBtcAddressChange = (btcAddress) => {
        dispatch(
            {
                type: ACTIONS.setBtcAddress,
                payload: {
                    btcAddress: btcAddress,
                    isSocialAccount: userContext.user.is_social_account
                }
            }
        );
    };
    const handlePasswordChange = (password) => {
        dispatch(
            {
                type: ACTIONS.setPassword,
                payload: {
                    password: password,
                    isSocialAccount: userContext.user.is_social_account
                }
            }
        );
    };
    const handleTwoFACodeChange = (twoFACode) => {
        dispatch(
            {
                type: ACTIONS.setTwoFACode,
                payload: {
                    twoFACode: twoFACode,
                    isSocialAccount: userContext.user.is_social_account
                }
            }
        );
    };
    const handleCancel = () => {
        dispatch(
            {
                type: ACTIONS.disableEditRegime,
                payload: { cryptoAccountDetails: cryptoAccountDetails }
            }
        );
    };
    const handleSave = (e) => {
        e.preventDefault();
        e.stopPropagation();

        dispatch({ type: ACTIONS.startSaving });
        if (
            form.data.btcAddress !== cryptoAccountDetails.btcAddress
            && form.state.isBtcAddressValid
            && (
                (
                    !userContext.user.is_social_account
                    && isNotEmptyString(form.data.password)
                    && form.state.isPasswordValid
                )
                ||
                (
                    userContext.user.is_social_account
                    && userContext.user.is_two_fa_enabled
                    && isNotEmptyString(form.data.twoFACode)
                    && form.state.isTwoFACodeValid
                )
            )
        ) {
            api.updateSettingsCryptoAccountDetails(
                {
                    "btc_address": form.data.btcAddress,
                    "password": form.data.password,
                    "twofa_code": 1 * form.data.twoFACode
                }
            ).then(response => {
                dispatch(
                    {
                        type: ACTIONS.terminateSaving,
                        payload: {
                            isChanged: true,
                            cryptoAccountDetails: cryptoAccountDetails,
                            isSocialAccount: userContext.user.is_social_account,
                            isSaved: response.ok,
                            error: response.error
                        }
                    }
                );
            });
        } else {
            dispatch(
                {
                    type: ACTIONS.terminateSaving,
                    payload: {
                        isChanged: false,
                        cryptoAccountDetails: cryptoAccountDetails,
                        isSocialAccount: userContext.user.is_social_account,
                    }
                }
            );
        }
    };

    return (
        <>
            <p>The Bitcoin address below is where we'll pay you won prizes and jackpots.</p>
            {
                userContext.user === null
                && <DataNotLoadedErrorMessage />
            }
            {
                userContext.user !== null
                && userContext.user.is_social_account
                && !userContext.user.is_two_fa_enabled
                && <article className="message is-danger">
                    <div className="message-body">
                        Two-Factor Authentication must be enabled to add or edit the BTC address.&nbsp;
                        <Link to="/settings/profile-and-settings/security">
                            Go to Security
                        </Link>
                    </div>
                </article>
            }
            {
                (
                    userContext.user !== null
                    && (
                        !userContext.user.is_social_account
                        || (
                            userContext.user.is_social_account
                            && userContext.user.is_two_fa_enabled
                        )
                    )
                )
                && <>
                    {error && <DataNotLoadedErrorMessage />}
                    {isPending && <InProgressText/>}
                    {
                        cryptoAccountDetails
                        && !error
                        && !isPending
                        && <>
                            {
                                form.state.api.serverError
                                && <article className="message is-danger">
                                    <div className="message-body">
                                        Error. Please wait a few minutes before you try again.
                                    </div>
                                </article>
                            }
                            {
                                form.state.api.validationError
                                && <article className="message is-danger">
                                    <div className="message-body">
                                        Please check the fields below and make sure all the required data are filled in.
                                    </div>
                                </article>
                            }

                            <div className="field">
                                <label className="label">BTC address</label>
                                <div className="control has-icons-left has-icons-right">
                                    <input
                                        autoFocus={true}
                                        className={`input ${
                                            cryptoAccountDetails.btcAddress !== form.data.btcAddress
                                            && !form.state.isBtcAddressValid
                                            && 'is-danger'
                                        }`}
                                        type="text"
                                        placeholder=""
                                        value={`${
                                            form.state.isEditing
                                            ? form.data.btcAddress
                                            : hidePartOfTheString(form.data.btcAddress)
                                        }`}
                                        onChange={(e) => handleBtcAddressChange(e.target.value)}
                                        disabled={!form.state.isEditing}
                                    />
                                    <span className="icon is-small is-left">
                                        <FaBtc />
                                    </span>

                                    {
                                        cryptoAccountDetails.btcAddress !== form.data.btcAddress
                                        && (
                                            <>
                                                {
                                                    form.state.isBtcAddressValid
                                                    && <span className="icon is-small is-right">
                                                        <FaCheck/>
                                                    </span>
                                                }

                                                {
                                                    !form.state.isBtcAddressValid
                                                    && <span className="icon is-small is-right">
                                                        <FaExclamationTriangle/>
                                                    </span>
                                                }
                                            </>
                                        )
                                    }
                                </div>
                                {
                                    form.data.btcAddress.length > 0
                                    && <p className="help">
                                        {
                                            !form.state.isBtcAddressCopied
                                            && <button
                                                onClick={handleCopying}
                                                className="link-standard"
                                            >Copy</button>
                                        }
                                        {
                                            form.state.isBtcAddressCopied
                                            && <button
                                                className="link-standard"
                                            >Copied</button>
                                        }
                                    </p>
                                }
                                {
                                    form.data.btcAddress !== ''
                                    && (
                                        <>
                                            {
                                                !form.state.isBtcAddressValid
                                                && <p className="help is-danger">Invalid BTC (Bitcoin) address.</p>
                                            }
                                        </>
                                    )
                                }
                            </div>

                            {
                                form.state.isEditing
                                && <>
                                    {
                                        userContext.user !== null
                                        && !userContext.user.is_social_account
                                        && <div className="field">
                                            <label className="label">Password</label>
                                            <div className="control has-icons-left has-icons-right">
                                                <input
                                                    className={`input ${
                                                        form.data.password !== ''
                                                        && !form.state.isPasswordValid
                                                        && 'is-danger'
                                                    }`}
                                                    type={`${form.state.isPasswordVisible ? "text" : "password" }`}
                                                    value={form.data.password}
                                                    onChange={(e) => handlePasswordChange(e.target.value)}
                                                    autoComplete="new-password"
                                                />
                                                <span className="icon is-small is-left">
                                                    <FaLock />
                                                </span>

                                                {
                                                    !form.state.isPasswordVisible
                                                    && <span
                                                        className="icon is-small is-right"
                                                        data="test"
                                                        onClick={showPasswordVisualizing}
                                                        style={{pointerEvents: "all", pointer: "cursor"}}
                                                    >
                                                        <RiEyeCloseLine/>
                                                    </span>
                                                }

                                                {
                                                    form.state.isPasswordVisible
                                                    && <span
                                                        className="icon is-small is-right"
                                                        onClick={hidePasswordVisualizing}
                                                        style={{pointerEvents: "all", pointer: "cursor"}}
                                                    >
                                                        <RiEyeLine/>
                                                    </span>
                                                }
                                            </div>
                                            {
                                                form.data.password !== ''
                                                && (
                                                    <>
                                                        {
                                                            !form.state.isPasswordValid
                                                            && <p className="help is-danger">The password should be at least 6 characters long and no longer than 250 characters.</p>
                                                        }
                                                    </>
                                                )
                                            }
                                        </div>
                                    }

                                    {
                                        userContext.user !== null
                                        && userContext.user.is_social_account
                                        && userContext.user.is_two_fa_enabled
                                        && <div className="field">
                                            <label className="label">2FA code</label>
                                            <div className="control has-icons-left has-icons-right">
                                                <input
                                                    className={`input ${
                                                        form.data.twoFACode !== ''
                                                        && !form.state.isTwoFACodeValid
                                                        && 'is-danger'
                                                    }`}
                                                    maxLength="6"
												    type="numeric"
                                                    value={form.data.twoFACode}
                                                    onChange={(e) => handleTwoFACodeChange(e.target.value)}
                                                />
                                                <span className="icon is-small is-left">
                                                    <FaKey />
                                                </span>
                                                {
                                                    '' !== form.data.twoFACode
                                                    && (
                                                        <>
                                                            {
                                                                form.state.isTwoFACodeValid
                                                                && <span className="icon is-small is-right">
                                                                    <FaCheck/>
                                                                </span>
                                                            }

                                                            {
                                                                !form.state.isTwoFACodeValid
                                                                && <span className="icon is-small is-right">
                                                                    <FaExclamationTriangle/>
                                                                </span>
                                                            }
                                                        </>
                                                    )
                                                }
                                            </div>
                                            <p className="help">Enter the 6-digit code from Binance/Google Authenticator</p>
                                        </div>
                                    }
                                </>
                            }



                            {
                                !form.state.isEditing
                                && <div className="field is-grouped is-flex is-justify-content-right">
                                    <div className="control">
                                        <button
                                            onClick={handleEdit}
                                            className="button is-secondary is-rounded"
                                        >Edit</button>
                                    </div>
                                </div>
                            }
                            {
                                form.state.isEditing
                                && <div className="field is-grouped is-flex is-justify-content-right">
                                    <div className="control">
                                        <button
                                            onClick={handleCancel}
                                            className="button is-rounded"
                                        >Cancel</button>
                                    </div>
                                    <div className="control">
                                        <button
                                            onClick={handleSave}
                                            className="button is-primary is-rounded"
                                            disabled={
                                                !form.state.isBtcAddressValid
                                                || (
                                                    userContext.user !== null
                                                    && !userContext.user.is_social_account
                                                    && (
                                                        !isNotEmptyString(form.data.password)
                                                        || !form.state.isPasswordValid
                                                    )
                                                )
                                                || (
                                                    userContext.user !== null
                                                    && userContext.user.is_social_account
                                                    && userContext.user.is_two_fa_enabled
                                                    && (
                                                        !isNotEmptyString(form.data.twoFACode)
                                                        || !form.state.isTwoFACodeValid
                                                    )
                                                )
                                            }
                                        >
                                            Save
                                            {
                                                form.state.isSaving
                                                && <span className="cl-button-loader"></span>
                                            }
                                        </button>
                                    </div>
                                </div>
                            }
                        </>
                    }
                </>
            }
        </>
    );
}

export default FormCryptoAccountDetails;