import { Link } from 'react-router-dom';

const HowToClaimMyWin = () => {
  	return (
		<div className="container">
			<section className="section">
				<div className="columns">
					<div className="column"></div>
					<div className="column is-7">
						<div className="content">
							<h1 className="title">How to Claim My Win?</h1>
							<p>
								The beauty of the blockchain is that you don't have to claim anything. Any prize or jackpot you won, is automatically transferred to the crypto address you provided.
							</p>
						</div>
						<div className="content">
							<p>
								The only requirement is to <Link to="/settings/funds-and-payments/crypto-account-details" className="link-standard">set up the BTC (Bitcoin) address</Link> of your wallet where you'd like to be funded the prizes or the jackpots you win.
							</p>
						</div>
					</div>
					<div className="column"></div>
				</div>
			</section>
			<div className="block my-6"></div>
		</div>
  	)
}

export default HowToClaimMyWin