import { useState, useEffect } from 'react';

import { useApi } from '../../../hooks';
import { InProgressText, DataNotLoadedErrorMessage } from '../../../components';


const ListOfCauses = () => {
    const api = useApi();
    const elementsPerRow = 3;
    const [data, setData] = useState([]);
    const [error, setError] = useState(false);
    const [isPending, setIsPending] = useState(true);

    useEffect(() => {
        api.getCauses().then(response => {
            setIsPending(false);

            if (!response.ok) {
                setError(true);
                return;
            }

            setData(response.data);
        });
    }, []);

    return (
        <>
            {
                data.length > 0
                && (
                    <>
                        <hr />

                        <section className="section">
                            <div className="content">
                                <h1 className="title">Causes We Will Support</h1>

                                {error && <DataNotLoadedErrorMessage />}
                                {isPending && <InProgressText/>}
                                {
                                    [...Array(Math.ceil(data.length/elementsPerRow)).keys()].map((rowNumber) => (
                                        <div key={`row-${rowNumber}`} className="columns">
                                            {
                                                Object.values(
                                                    Object.keys(data).slice(elementsPerRow * rowNumber, elementsPerRow * rowNumber + elementsPerRow).reduce((result, key) => {
                                                        result[key] = data[key];

                                                        return result;
                                                    }, {})
                                                ).map((cause) => (
                                                    <div key={cause.id} className="column is-4">
                                                        <div className="card causes-card">
                                                            <div className="card-image">
                                                                <figure className="image is-4by3 m-0">
                                                                    <img src={cause.image} alt={cause.title} />
                                                                </figure>
                                                            </div>
                                                            <div className="card-content">
                                                                <div className="media">
                                                                    <div className="media-content">
                                                                    <p className="title is-4">{cause.title}</p>
                                                                    </div>
                                                                </div>

                                                                <div className="content">
                                                                    {cause.description}
                                                                    &nbsp;
                                                                    <a
                                                                        href={cause.link}
                                                                        target="_blank"
                                                                        rel="noreferrer nofollow"
                                                                        className="link-standard"
                                                                    >Read more...</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                        </section>
                    </>
                )
            }
        </>
    );
}

export default ListOfCauses;