const authAppRoutes = [
    '/login',
    '/login-secure',
    '/open-an-account',
    '/forgotten-password',
    '/reset-password',
];

export const isAuthPath = (path) => {
    for (let i = 0; i < authAppRoutes.length; i++) {
        if (path.includes(authAppRoutes[i])) {
            return true;
        }
    }

    return false;
};

export const getPathForUrl = (prevPath) => {
    let path = null;
    const storedPath = getPath();

    if (!isAuthPath(prevPath)) {
        path = prevPath;
        setPath(path);
    } else {
        if (null !== storedPath && !isAuthPath(storedPath)) {
            path = storedPath;
        }
    }

    return path;
}

const getPath = () => {
    return window.localStorage.getItem("path");
};

const setPath = (path) => {
    return window.localStorage.setItem("path", path);
};