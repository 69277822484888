import { Link } from 'react-router-dom';
import { useState, useEffect } from "react";

import {
    GameStartsAt,
    FiatFormatter,
    InProgressText,
    DataNotLoadedErrorMessage
} from '../../../../components';
import { useApi } from '../../../../hooks';
import { useUserContext } from '../../../../context';


const ListOfGames = ({isMobile}) => {
    const userContext = useUserContext();

    const api = useApi();
    const games = {
        "bitcoin-lotto": "lotto-card-redish",
        "bitcoin-hero": "lotto-card-yellow",
        "ether-lotto": "lotto-card-blueish",
        "mega-ball": "lotto-card-red",
        "super-game": "lotto-card-yellow-dark",
        "threasure": "lotto-card-purple"
    };

    const [data, setData] = useState([]);
    const [error, setError] = useState(false);
    const [isPending, setIsPending] = useState(true);
    useEffect(() => {
        api.getSettingsGames().then(response => {
            setIsPending(false);

            if (!response.ok) {
                setError(true);
                return;
            }

            setData(response.data);
        });
    }, []);


    return (
        <>
            {error && <DataNotLoadedErrorMessage />}
			{isPending && <InProgressText/>}
            {
                data && data.length > 0
                ? data.map((game) => (
                    <div key={game.id} className={`card ${games[game.key]} nohover mb-2`}>
                        <div className="card-content">
                            {
                                isMobile
                                ? <>
                                    <div className="content mb-1">
                                        <div className="has-text-weight-bold is-size-5">
                                            {game.name}
                                        </div>
                                        <p>
                                            <GameStartsAt
                                                startAt={game.next_draw.start_at}
                                                details={false}
                                            />
                                        </p>
                                    </div>
                                    <div className="content mb-1">
                                        <div className="is-size-2 has-text-weight-bold">
                                            <FiatFormatter
                                                type="JACKPOT"
                                                data={game.jackpot.fiat[userContext.currency_code]}
                                            />
                                        </div>
                                    </div>
                                    <div className="content">
                                        <Link
                                            to={`/game/${game.key}`}
                                            className="button lotto-button is-rounded mr-4 mt-2"
                                        >Play</Link>
                                        <Link
                                            to={`/game/${game.key}/results`}
                                            className="button lotto-button is-rounded mt-2"
                                        >Check Results</Link>
                                    </div>
                                </>
                                : <>
                                    <div className="content">
                                        <div className="has-text-weight-bold is-size-5">
                                            {game.name}
                                        </div>
                                        <div className="columns is-flex is-vcentered">
                                            <div className="column is-one-fifth">
                                                <div className="is-size-2 has-text-weight-bold">
                                                    <FiatFormatter
                                                        type="JACKPOT"
                                                        data={game.jackpot.fiat[userContext.currency_code]}
                                                    />
                                                </div>
                                            </div>
                                            <div className="column is-one-third">
                                                <div className="is-size-6">
                                                    <GameStartsAt
                                                        startAt={game.next_draw.start_at}
                                                        details={false}
                                                    />
                                                </div>
                                            </div>
                                            <div className="column is-flex is-justify-content-right">
                                                <Link
                                                    to={`/game/${game.key}`}
                                                    className="button lotto-button is-rounded"
                                                >Play</Link>
                                                <Link
                                                    to={`/game/${game.key}/results`}
                                                    className="button lotto-button is-rounded ml-4"
                                                >Check Results</Link>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                ))
                : (
                    <>
                        {
                            !error
                            && !isPending
                            && <p className="has-text-grey ml-3 mb-3">No games played yet.</p>
                        }
                    </>
                )
            }
        </>
    );
}

export default ListOfGames;