import { useState } from 'react';
import { FaFacebookF } from 'react-icons/fa';
import { LoginSocialFacebook } from 'reactjs-social-login';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useApi } from '../../../hooks';
import { authTranslations } from '../../../services';


const LoginSocialFacebookButton = ({setSociaLoginError}) => {
    const api = useApi();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [loginInProgress, setLoginInProgress] = useState(false);

    const onLoginStart = () => {
        setLoginInProgress(true);
    };

    const onSuccess = (response) => {
        setSociaLoginError(false);

        api.loginViaFacebook(
            {
                'access_token': response['accessToken']
            }
        ).then(response => {
            setLoginInProgress(false);

            if (!response.ok) {
                setSociaLoginError(authTranslations['facebook_login_failed']);
            }

            const path = searchParams.get('path');

            if (
                Object.hasOwn(response.data, 'access_token')
                && Object.hasOwn(response.data, 'refresh_token')
            ) {
                if (null !== path) {
                    navigate(path);
                    return;
                }

                navigate('/');
                return;
            }

            navigate('/login-secure/' + response.data.key + (
                null !== path ? '?path=' + path : ''
            ));
        });
    }

    const onFailure = (error) => {
        setLoginInProgress(false);
        setSociaLoginError(authTranslations['facebook_login_failed']);
    }

    return (
        <>
            <LoginSocialFacebook
                appId={process.env.REACT_APP_FACEBOOK_APP_ID || ''}
                isOnlyGetToken={false}

                onLoginStart={onLoginStart}
                onResolve={({ provider, data }) => { onSuccess(data) }}
                onReject={onFailure}

                scope="email,public_profile"
                fields="name,email,picture,location"

                className="button is-info m-2"
            >
                <span className="icon">
                    <FaFacebookF />
                </span>
                <span>Facebook</span>
                {
                    loginInProgress
                    && <span className="cl-button-loader"></span>
                }
            </LoginSocialFacebook>
        </>
    );
}

export default LoginSocialFacebookButton;