import { useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useUserContext } from '../../context';

const SettingsMobileMenu = () => {
    let openRef = useRef();
    const location = useLocation();
    const userContext = useUserContext();

    useEffect(() => {
        if (location.pathname.includes('/settings/profile-and-settings/personal-details')) {
            openRef.current.scrollLeft = 0;
        }
        if (location.pathname.includes('/settings/profile-and-settings/password-change')) {
            openRef.current.scrollLeft = 40;
        }
        if (location.pathname.includes('/settings/profile-and-settings/security')) {
            openRef.current.scrollLeft = 120;
        }
        if (location.pathname.includes('/settings/profile-and-settings/email-preferences')) {
            openRef.current.scrollLeft = 160;
        }
    }, [location.pathname]);

  	return (
        <>
            <div className="tabs is-small mb-1 has-background-light" id="nav-mobile-level-one">
                <ul>
                    <li
                        className={`${location.pathname.includes('/settings/general') ? 'is-active' : ''}`}
                    >
                        <Link to="/settings/general/my-games">General</Link>
                    </li>
                    <li className={`${location.pathname.includes('/settings/profile-and-settings') ? 'is-active' : ''}`}>
                        <Link to="/settings/profile-and-settings/personal-details">Profile & Settings</Link>
                    </li>
                    <li className={`${location.pathname.includes('/settings/funds-and-payments') ? 'is-active' : ''}`}>
                        <Link to="/settings/funds-and-payments/transaction-history">Funds & Payments</Link>
                    </li>
                </ul>
            </div>
            <div className="tabs is-small" id="nav-mobile-level-two" ref={openRef}>
                <ul>
                    {
                        location.pathname.includes('/settings/general')
                        && <>
                            <li className={`${location.pathname.includes('/settings/general/my-games') ? 'is-active' : ''}`}>
                                <Link to="/settings/general/my-games">My games</Link>
                            </li>
                            <li className={`${location.pathname.includes('/settings/general/messages') ? 'is-active' : ''}`}>
                                <Link to="/settings/general/messages">Messages</Link>
                            </li>
                        </>
                    }

                    {
                        location.pathname.includes('/settings/profile-and-settings')
                        && <>
                            <li className={`${location.pathname.includes('/settings/profile-and-settings/personal-details') ? 'is-active' : ''}`}>
                                <Link to="/settings/profile-and-settings/personal-details">Personal details</Link>
                            </li>
                            {
                                userContext.user
                                && !userContext.user.is_social_account
                                && <li className={`${location.pathname.includes('/settings/profile-and-settings/password-change') ? 'is-active' : ''}`}>
                                    <Link to="/settings/profile-and-settings/password-change">Password change</Link>
                                </li>
                            }
                            <li className={`${location.pathname.includes('/settings/profile-and-settings/security') ? 'is-active' : ''}`}>
                                <Link to="/settings/profile-and-settings/security">Security</Link>
                            </li>
                            <li className={`${location.pathname.includes('/settings/profile-and-settings/email-preferences') ? 'is-active' : ''}`}>
                                <Link to="/settings/profile-and-settings/email-preferences">Email preferences</Link>
                            </li>
                        </>
                    }

                    {
                        location.pathname.includes('/settings/funds-and-payments')
                        && <>
                            <li className={`${location.pathname.includes('/settings/funds-and-payments/transaction-history') ? 'is-active' : ''}`}>
                                <Link to="/settings/funds-and-payments/transaction-history">Transaction history</Link>
                            </li>
                            <li className={`${location.pathname.includes('/settings/funds-and-payments/crypto-account-details') ? 'is-active' : ''}`}>
                                <Link to="/settings/funds-and-payments/crypto-account-details">Crypto account details</Link>
                            </li>
                        </>
                    }
                </ul>
            </div>
        </>
    )
}

export default SettingsMobileMenu