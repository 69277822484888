import { useState, useEffect } from "react";

import { getCurrentUTCUnixTimestamp, secondsToDhms } from '../../../services';


const GameCountdown = ({game}) => {
    const [days, setDays] = useState(0);
	const [hours, setHours] = useState(0);
	const [minutes, setMinutes] = useState(0);
	const [seconds, setSeconds] = useState(0);

	useEffect(() => {
		if (game) {
			const interval = setInterval(() => {
				const currentUTCUnixTimestamp = getCurrentUTCUnixTimestamp();

                if (game.next_draw.start_at - currentUTCUnixTimestamp <= 0) {
                    setDays('0');
                    setHours('0');
                    setMinutes('0');
                    setSeconds('0');

                    clearInterval(interval);
                    return;
                }

				const [days, hours, minutes, seconds] = secondsToDhms(
					game.next_draw.start_at - currentUTCUnixTimestamp
				);

				setDays(days);
				setHours(hours);
				setMinutes(minutes);
				setSeconds(seconds);
			}, 1000);

			return () => clearInterval(interval);
		}
	}, [game]);

    return (
        <>
            <p className="time">
                <span className="time-number">{days}</span>
                <span className="time-label">Days</span>
            </p>
            <p className="divider mr-2">:</p>
            <p className="time">
                <span className="time-number">{hours}</span>
                <span className="time-label">Hours</span>
            </p>
            <p className="divider mr-2">:</p>
            <p className="time">
                <span className="time-number">{minutes}</span>
                <span className="time-label">Mins</span>
            </p>
            <p className="divider mr-2">:</p>
            <p className="time">
                <span className="time-number">{seconds}</span>
                <span className="time-label">Sec</span>
            </p>
        </>
    );
}

export default GameCountdown;