// Username
export const isValidUsername = (username) => {
    return username.length > 0 && null !== String(username).match(/^[0-9a-zA-Z\\ \\'\\_\\-]+$/);
};

// Email
export const isValidEmail = (email) => {
    return null !== String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

// Password
export const isPasswordMinLength = (password) => {
    return password.length >= 6;
};

export const passwordStrengthChecker = (password) => {
    const strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')
    const mediumPassword = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))')

    if(strongPassword.test(password)) return 'STRONG';
    if(mediumPassword.test(password)) return 'MEDIUM';
    return 'WEAK';
};

// 2FA
export const isValidTwoFACode = (code) => {
    const n = 1 * code;
    return code.length === 6 && Number.isInteger(n) && n <= 999999;
};

// String, int, etc.
export const isValidTextString = (string) => {
    return string.length <= 65000;
};

export const isNotEmptyString = (string) => {
    return string.length > 0;
};

export const isValidShortString = (string) => {
    return string.length <= 250;
};

export const hidePartOfTheString = (string) => {
    const Ln = string.length;

    if (Ln > 15) {
        return string.substring(0, 4) + "*".repeat(Ln - 8) + string.substring(Ln - 4, Ln);
    }

    if (Ln > 8) {
        return string.substring(0, 3) + "*".repeat(Ln - 6) + string.substring(Ln - 3, Ln);
    }

    if (Ln > 6) {
        return string.substring(0, 2) + "*".repeat(Ln - 4) + string.substring(Ln - 2, Ln);
    }

    if (Ln > 4) {
        return string.substring(0, 1) + "*".repeat(Ln - 2) + string.substring(Ln - 1, Ln);
    }

    return '';
};

export const isInteger = (data) => {
    return Number.isInteger(1 * data);
};

export const areStringsEqual = (stringOne, stringTwo) => {
    return stringOne.length > 0 && stringOne === stringTwo;
};

export const secondsToDhms = (seconds) => {
    seconds = Number(seconds);

    let days = Math.floor(seconds / (3600*24));
    let hours = Math.floor(seconds % (3600*24) / 3600);
    let minutes = Math.floor(seconds % 3600 / 60);
    seconds = Math.floor(seconds % 60);

    return [days, hours, minutes, seconds];
};

// General
export const getCurrentUTCUnixTimestamp = () => {
    let utcTimestamp = new Date().getTime();

    return Math.ceil(utcTimestamp/1000)
};
// export const getNowUTC = () => {
//     let timeObject = new Date();
//     timeObject = new Date(
//         timeObject.getTime() + timeObject.getTimezoneOffset() * 60000
//     );
//     return timeObject.getTime()/1000;
// };