import { useParams, useNavigate } from 'react-router-dom';

import { useWindowSize } from '../../../hooks';
import { getValidPage } from '../../../services';
import ListOfTransactions from './Lists/ListOfTransactions'
import { MenuSettingsDesktop, MenuSettingsMobile } from '../../../components';


const TransactionHistory = () => {
	const navigate = useNavigate();

	let { page } = useParams();
	page = getValidPage(page);
	if (!page) navigate.push('/page-not-found');

	const mobileWindowMaxSize = 1023;
	const size = useWindowSize();

  	return (
		<>
			{
				size.width > mobileWindowMaxSize
				&& <div className="container is-hidden-touch">
					<section className="section">
						<div className="columns">
							<div className="column is-one-fifth">
								<MenuSettingsDesktop/>
							</div>
							<div className="column">
								<article className="panel is-primary">
									<p className="panel-heading">Transaction history</p>
									<div className="content p-3">
										<ListOfTransactions currentPage={page} />
									</div>
								</article>
							</div>
						</div>
					</section>
				</div>
			}
			{
				size.width <= mobileWindowMaxSize
				&& <div className="container is-fixed-top is-hidden-desktop">
					<MenuSettingsMobile />
					<section className="section pl-2 pt-0">
						<div className="content">
							<ListOfTransactions currentPage={page} />
						</div>
					</section>
				</div>
			}

			<div className="block my-6"></div>
		</>
  	)
}

export default TransactionHistory