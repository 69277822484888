import axios from "axios";
import { useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';

import {
    setTokens,
    isAuthPath,
    getJwtToken,
    removeTokens,
    getRefreshToken,
    authTranslations,
    triggerLoginEventListener,
    triggerLogoutEventListener,
    activateLoginEventListener,
    activateLogoutEventListener
} from '../services';


// TODO: We may implement AbortController, link: https://03balogun.medium.com/practical-use-case-of-the-abortcontroller-and-axios-cancel-token-7c75bf85f3ea
const useApi = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const client = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json'
        },
    });

    useEffect(() => {
        activateLoginEventListener(navigate);
        activateLogoutEventListener(navigate);
    }, []);



    /* ========================== */
    /* ===== Login & Logout ===== */
    /* ========================== */
    const _login = (access_token, refresh_token) => {
        setTokens(access_token, refresh_token);
        triggerLoginEventListener();
    };

    const _logout = () => {
        removeTokens();
        triggerLogoutEventListener();
    };

    const redirectIfIsAuthPath = () => {
        if (isAuthPath(location.pathname)) navigate('/');
    };



    /* ================================================= */
    /* ===== Request :: Prepare, Execute, Complete ===== */
    /* ================================================= */
    /* === Headers === */
    const _getHeaderAuthorizationIfAny = () => {
        const access_token = getJwtToken();
        return access_token
            ? { 'Authorization': 'Bearer ' + access_token }
            : {}
    };

    const _getHeaderRefresh = () => {
        const refresh_token = getRefreshToken();
        return refresh_token
            ? { 'Authorization': 'Bearer ' + refresh_token }
            : {}
    };

    const _getHeaderWithCredentials = () => {
        return { 'Access-Control-Allow-Credentials': true }
    };

    const _combineHeaders = (headerOne, headerTwo) => {
        return Object.assign({}, headerOne, headerTwo);
    };

    /* === Responses === */
    const _get_success_response = (response) => {
        return { 'data': response.data, 'error': false, 'ok': true }
    };

    const _get_error_response = (response) => {
        const data = response.data

        if (data === undefined || (data !== undefined && (
            !Object.hasOwn(data, 'error') || (
                Object.hasOwn(data, 'error') && (
                    !Object.hasOwn(data['error'], 'message')
                    || !Object.hasOwn(data['error'], 'code')
                )
            )
        ))) {
            return {
                'error': {
                    'message': authTranslations['UNEXPECTED_ERROR'],
                    'code': 'UNEXPECTED_ERROR'
                },
                'ok': false
            }
        }

        return {
            'error': {
                'message':
                    Object.hasOwn(authTranslations, data['error']['code'])
                    ? authTranslations[data['error']['code']]
                    : data['error']['message'],
                'code': data['error']['code']
            },
            'ok': false
        }
    };

    /* === Request Handlers === */
    const _handle_refresh = async (
        method,
        url,
        payload,
        error,
        requireRefresh,
        redirectIfRefreshFails
    ) => {
        if (error.response.status !== 401) return error.response;
        if (!(requireRefresh && getRefreshToken())) {
            _logout();
            if (redirectIfRefreshFails) {
                navigate('/login');
                return error.response;
            }
        }

        return await api.refresh().then(async responseRefresh => {
            if (!responseRefresh.ok) return responseRefresh;

            switch(method) {
                case 'POST':
                    return await _post(url, payload, false);
                case 'PUT':
                    return await _put(url, payload, false);
                case 'GET':
                    return await _get(url, payload, false);
                case 'DELETE':
                    return await _delete(url, payload, false);
                default:
                    break;
            }
        });
    };

    const _get = async (
        url,
        payload = {},
        requireRefresh = true,
        redirectIfRefreshFails = true
    ) => {
        try {
            const options = { headers: _getHeaderAuthorizationIfAny() };
            const urlParams = new URLSearchParams(payload).toString();
            const response = await client.get(
                    url + (urlParams === '' ? '' : '?' + urlParams),
                    options
                )
                .then(response => response)
                .catch(async error => {
                    return await _handle_refresh(
                        'GET',
                        url,
                        payload,
                        error,
                        requireRefresh,
                        redirectIfRefreshFails
                    );
                });

            if (Object.hasOwn(response, 'error') && Object.hasOwn(response, 'ok')) {
                return response;
            }

            return response.status !== 200
                ? _get_error_response(response)
                : _get_success_response(response);
        } catch (error) {
            return _get_error_response(error.response);
        }
    };

    const _post = async (
        url,
        payload = {},
        requireRefresh = true,
        redirectIfRefreshFails = true
    ) => {
        try {
            const options = { headers: _getHeaderAuthorizationIfAny() };
            const response = await client.post(url, payload, options)
                .then(response => response)
                .catch(async error => {
                    return await _handle_refresh(
                        'POST',
                        url,
                        payload,
                        error,
                        requireRefresh,
                        redirectIfRefreshFails
                    );
                });

            if (Object.hasOwn(response, 'error') && Object.hasOwn(response, 'ok')) {
                return response;
            }

            return response.status !== 200 && response.status !== 201
                ? _get_error_response(response)
                : _get_success_response(response);
        } catch (error) {
            return _get_error_response(error.response);
        }
    };

    const _put = async (
        url,
        payload = {},
        requireRefresh = true,
        redirectIfRefreshFails = true
    ) => {
        try {
            const options = { headers: _getHeaderAuthorizationIfAny() };
            const response = await client.put(url, payload, options)
                .then(response => response)
                .catch(async error => {
                    return await _handle_refresh(
                        'PUT',
                        url,
                        payload,
                        error,
                        requireRefresh,
                        redirectIfRefreshFails
                    );
                });

            if (Object.hasOwn(response, 'error') && Object.hasOwn(response, 'ok')) {
                return response;
            }

            return response.status !== 200
                ? _get_error_response(response)
                : _get_success_response(response);
        } catch (error) {
            return _get_error_response(error.response);
        }
    };

    const _delete = async (
        url,
        payload = {},
        requireRefresh = true,
        redirectIfRefreshFails = true
    ) => {
        try {
            const options = {
                data: payload,
                headers: _getHeaderAuthorizationIfAny()
            };
            const response = await client.delete(url, options)
                .then(response => response)
                .catch(async error => {
                    return await _handle_refresh(
                        'DELETE',
                        url,
                        payload,
                        error,
                        requireRefresh,
                        redirectIfRefreshFails
                    );
                });

            if (Object.hasOwn(response, 'error') && Object.hasOwn(response, 'ok')) {
                return response;
            }

            return response.status !== 200
                ? _get_error_response(response)
                : _get_success_response(response);
        } catch (error) {
            return _get_error_response(error.response);
        }
    };



    /* ===================== */
    /* ===== Endpoints ===== */
    /* ===================== */
    const api = {
        /* ===== Auth :: Special ===== */
        register: async function(payload) {
            try {
                const url = '/auth/register';
                const options = {
                    headers: _getHeaderWithCredentials(),
                    withCredentials: true
                };
                const response = await client.post(url, payload, options);

                if (
                    response.status !== 201 || (
                        response.status === 201 && (
                            !Object.hasOwn(response.data, 'access_token')
                            || !Object.hasOwn(response.data, 'refresh_token')
                        )
                    )
                ) return _get_error_response(response);

                _login(
                    response.data.access_token,
                    response.data.refresh_token
                );

                return _get_success_response(response);
            } catch (error) {
                return _get_error_response(error.response);
            }
        },
        login: async function(payload) {
            try {
                const url = '/auth/login';
                const options = {
                    headers: _getHeaderWithCredentials(),
                    withCredentials: true
                };
                const response = await client.post(url, payload, options);

                if (
                    response.status === 200
                    && Object.hasOwn(response.data, 'access_token')
                    && Object.hasOwn(response.data, 'refresh_token')
                ) _login(
                    response.data.access_token,
                    response.data.refresh_token
                );

                return _get_success_response(response);
            } catch (error) {
                return _get_error_response(error.response);
            }
        },
        loginViaFacebook: async function(payload) {
            try {
                const url = '/auth/login/facebook';
                const options = {
                    headers: _getHeaderWithCredentials(),
                    withCredentials: true
                };
                const response = await client.post(url, payload, options);

                if (
                    response.status === 200
                    && Object.hasOwn(response.data, 'access_token')
                    && Object.hasOwn(response.data, 'refresh_token')
                ) _login(
                    response.data.access_token,
                    response.data.refresh_token
                );

                return _get_success_response(response);
            } catch (error) {
                return _get_error_response(error.response);
            }
        },
        loginViaGoogle: async function(payload) {
            try {
                const url = '/auth/login/google';
                const options = {
                    headers: _getHeaderWithCredentials(),
                    withCredentials: true
                };
                const response = await client.post(url, payload, options);

                if (
                    response.status === 200
                    && Object.hasOwn(response.data, 'access_token')
                    && Object.hasOwn(response.data, 'refresh_token')
                ) _login(
                    response.data.access_token,
                    response.data.refresh_token
                );

                return _get_success_response(response);
            } catch (error) {
                return _get_error_response(error.response);
            }
        },
        loginViaTwitter: async function(payload) {
            try {
                const url = '/auth/login/twitter';
                const options = {
                    headers: _getHeaderWithCredentials(),
                    withCredentials: true
                };
                const response = await client.post(url, payload, options);

                if (
                    response.status === 200
                    && Object.hasOwn(response.data, 'access_token')
                    && Object.hasOwn(response.data, 'refresh_token')
                ) _login(
                    response.data.access_token,
                    response.data.refresh_token
                );

                return _get_success_response(response);
            } catch (error) {
                return _get_error_response(error.response);
            }
        },
        loginSecure: async function(payload) {
            try {
                const url = '/auth/login-secure';
                const options = {
                    headers: _getHeaderWithCredentials(),
                    withCredentials: true
                };
                const response = await client.post(url, payload, options);

                if (
                    response.status !== 200 || (
                        response.status === 200 && (
                            !Object.hasOwn(response.data, 'access_token')
                            || !Object.hasOwn(response.data, 'refresh_token')
                        )
                    )
                ) return _get_error_response(response);

                _login(
                    response.data.access_token,
                    response.data.refresh_token
                );

                return _get_success_response(response);
            } catch (error) {
                return _get_error_response(error.response);
            }
        },
        refresh: async function() {
            try {
                const url = '/auth/refresh';
                const options = {
                    headers: _combineHeaders(
                        _getHeaderRefresh(),
                        _getHeaderWithCredentials()
                    ),
                    withCredentials: true
                };
                const response = await client.post(url, {}, options);

                if (
                    response.status !== 200 || (
                        response.status === 200 && (
                            !Object.hasOwn(response.data, 'access_token')
                            || !Object.hasOwn(response.data, 'refresh_token')
                        )
                    )
                ) {
                    _logout();
                    navigate('/login');

                    return _get_error_response(response);
                }

                _login(response.data.access_token, response.data.refresh_token);
                redirectIfIsAuthPath();

                return _get_success_response(response);
            } catch (error) {
                _logout();
                navigate('/login');

                return _get_error_response(error.response);
            }
        },
        logout: async function() {
            try {
                const url = '/auth/logout';
                const options = {
                    headers: _combineHeaders(
                        _getHeaderAuthorizationIfAny(),
                        _getHeaderWithCredentials()
                    ),
                    withCredentials: true
                };
                const response = await client.delete(url, options);

                _logout();

                return response.status !== 200
                    ? _get_error_response(response)
                    : _get_success_response(response);
            } catch (error) {
                _logout();

                return _get_error_response(error.response);
            }
        },



        /* === Auth :: General === */
        validateLoginSecureKey: async function(payload) {
            const url = '/auth/validate-login-secure-key';
            return await _get(url, payload);
        },
        resendVerificationEmail: async function() {
            const url = '/auth/resend-verification-email';
            return await _get(url);
        },
        verifyEmail: async function(payload) {
            const url = '/auth/verify-email';
            return await _put(url, payload);
        },
        forgottenPasswordGetResetLink: async function(payload) {
            const url = '/auth/forgotten-password';
            return await _get(url, payload);
        },
        velidateResetPasswordKey: async function(payload) {
            const url = '/auth/reset-password';
            return await _get(url, payload);
        },
        resetPassword: async function(payload) {
            const url = '/auth/reset-password';
            return await _put(url, payload);
        },
        getProfile: async function(redirectIfRefreshFails = true) {
            const url = '/profile';
            return await _get(url, {}, true, redirectIfRefreshFails);
        },
        verify: async function(payload) {
            const url = '/verify';
            return await _post(url, payload);
        },
        getActiveSessions: async function() {
            const url = '/auth/tokens';
            return await _get(url);
        },
        revokeTokens: async function(payload) {
            const url = '/auth/tokens';
            return await _delete(url, payload);
        },



        /* === Getters === */
        getCountries: async function() {
            const url = '/countries';
            return await _get(url);
        },
        getTimezones: async function() {
            const url = '/timezones';
            return await _get(url);
        },
        getCurrencies: async function() {
            const url = '/currencies';
            return await _get(url);
        },
        getSecurityQuestions: async function() {
            const url = '/security-questions';
            return await _get(url);
        },
        getWinners: async () => {
            const url = '/winners';
            return await _get(url);
        },
        getCauses: async () => {
            const url = '/causes';
            return await _get(url);
        },



        /* === Setters === */
        contact: async function(payload) {
            const url = '/contact';
            return await _post(url, payload);
        },



        /* === Games === */
        getGames: async () => {
            const url = '/games';
            return await _get(url);
        },
        addGameSavedNumbers: async function(key, payload) {
            const url = '/games/' + key + '/saved-numbers';
            return await _put(url, payload);
        },
        getGameSavedNumbers: async function(key) {
            const url = '/games/' + key + '/saved-numbers';
            return await _get(url);
        },
        deleteGameSavedNumbers: async function(key) {
            const url = '/games/' + key + '/saved-numbers';
            return await _delete(url);
        },
        getGameLastPlayedNumbers: async function(key) {
            const url = '/games/' + key + '/last-played-numbers';
            return await _get(url);
        },
        initiatePayment: async function(payload) {
            const url = '/payment';
            return await _post(url, payload);
        },
        getPaymentStatus: async function(transaction_id) {
            const url = '/payment?transaction_id=' + transaction_id;
            return await _get(url);
        },
        getGameWins: async function(key) {
            const url = '/games/' + key + '/wins';
            return await _get(url);
        },
        getGamePlays: async function(key, page = 1) {
            const url = '/games/' + key + '/plays?_page=' + page;
            return await _get(url);
        },
        getGameDraws: async function(key, page = false) {
            let url = '/games/' + key + '/draws';
            if (page) url += '?_page=' + page;

            return await _get(url);
        },
        getGameWatch: async function(key) {
            let url = '/games/' + key + '/watch';
            return await _get(url);
        },



        /* === Settings === */
            /* Games */
        getSettingsGames: async function() {
            const url = '/settings/games';
            return await _get(url);
        },

            /* Messages */
        getSettingsMessages: async function(page = 1) {
            const url = '/settings/messages?_page=' + page;
            return await _get(url);
        },
        updateSettingsMessages: async function(id, payload) {
            const url = '/settings/messages/' + id;
            return await _put(url, payload);
        },

            /* Personal data */
        getSettingsPersonalDetails: async function() {
            const url = '/settings/personal-details';
            return await _get(url);
        },
        updateSettingsPersonalDetails: async function(payload) {
            const url = '/settings/personal-details';
            return await _put(url, payload);
        },
        getSettingsPersonalDetailsUsernameAvailability: async function(username) {
            const url = '/settings/personal-details/username/' + username + '/availability';
            return await _get(url);
        },
        getSettingsPersonalDetailsEmailAvailability: async function(email) {
            const url = '/settings/personal-details/email/' + email + '/availability';
            return await _get(url);
        },

            /* Password */
        updateSettingsPassword: async function(payload) {
            const url = '/settings/password';
            return await _put(url, payload);
        },

            /* 2FA */
        getTwoFAQR: async function() {
            const url = '/settings/security/twofa/qr';
            return await _get(url);
        },
        verifySettingsSecurityTwoFA: async function(payload) {
            const url = '/settings/security/twofa/verify';
            return await _post(url, payload);
        },
        disableSettingsSecurityTwoFA: async function() {
            const url = '/settings/security/twofa';
            return await _delete(url);
        },

            /* Security questions */
        getSettingsSecuritySecurityQuestions: async function() {
            const url = '/settings/security/security-questions';
            return await _get(url);
        },
        updateSettingsSecuritySecurityQuestions: async function(payload) {
            const url = '/settings/security/security-questions';
            return await _put(url, payload);
        },

            /* Email preferences */
        getSettingsEmailPreferences: async function() {
            const url = '/settings/email-preferences';
            return await _get(url);
        },
        updateSettingsEmailPreferences: async function(payload) {
            const url = '/settings/email-preferences';
            return await _put(url, payload);
        },

            /* Transactions financial */
        getSettingsTransactions: async function(page = 1) {
            const url = '/settings/transactions?_page=' + page;
            return await _get(url);
        },

            /* Crypto account details */
        getSettingsCryptoAccountDetails: async function() {
            const url = '/settings/crypto-account-details';
            return await _get(url);
        },
        updateSettingsCryptoAccountDetails: async function(payload) {
            const url = '/settings/crypto-account-details';
            return await _put(url, payload);
        },
    };

    return api;
};

export default useApi;