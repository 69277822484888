import {
	FaTwitter, FaFacebookF, FaTelegramPlane, FaRedditAlien
} from 'react-icons/fa';

import FormContact from './Forms/FormContact';


const HelpAndContactUs = () => {
  	return (
		<div className="container">
			<section className="section">
				<div className="content">
					<h1 className="title">Help & Contact Us</h1>
				</div>
				<div className="content mt-6">
					<div className="columns">
						<div className="column is-6 mb-6">
							<FormContact />
						</div>

						<div className="is-divider-vertical" data-content="OR"></div>

						<div className="column">
							<div className="content">
								<h2 className="title is-size-4">
									Our offices
								</h2>
								<p>
									Brookfield Avenue 45, p.o. box 177,<br />
									Douglas IM9 1PS,<br />
									Isle of Man, UK
								</p>
								{
									1 !== 1
									&& <p>
										<a href="mailto: press@coinlottery.bet" className="link-standard" target="_blank" rel="noreferrer">press@coinlottery.bet</a>
									</p>
								}
								<p>
									<a href="mailto: support@coinlottery.bet" className="link-standard" target="_blank" rel="noreferrer">support@coinlottery.bet</a>
								</p>
							</div>

							<div className="content mt-3">
								<a href="https://twitter.com/CoinLotteryLive" target="_blank" rel="noreferrer nofollow" className="social-icon-link is-size-3 pr-3">
									<FaTwitter />
								</a>
								<a href="https://www.facebook.com/coinlotterybet/" target="_blank" rel="noreferrer nofollow" className="social-icon-link is-size-3 pr-3">
									<FaFacebookF />
								</a>
								<a href="https://t.me/coinlotterybet" target="_blank" rel="noreferrer nofollow" className="social-icon-link is-size-3 pr-3">
									<FaTelegramPlane />
								</a>
								{
									1 !== 1
									&& <a href="#" target="_blank" rel="noreferrer nofollow" className="social-icon-link is-size-3 pr-3">
										<FaRedditAlien />
									</a>
								}
							</div>

							{/* <div className="content mt-6">
								<h2 className="title is-size-4">
									Product and account support
								</h2>
								<p>Our help center is fresh and always open. If you can't find the answer you're looking for, we're here to lend a hand.</p>
								<button className="button is-primary is-rounded">Go to the help center</button>
							</div> */}
						</div>
					</div>
				</div>
			</section>
			<div className="block my-6"></div>
		</div>
  	)
}

export default HelpAndContactUs