import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { useApi } from '../../../hooks';
import { getPathForUrl } from '../../../services';


const LogoutButton = () => {
    const api = useApi();
    const navigate = useNavigate();

    const location = useLocation();
    const [pathToAttachToUrl, setPathToAttachToUrl] = useState('');
    useEffect(() => {
        const path = getPathForUrl(location.pathname);
        setPathToAttachToUrl(null === path ? '' : '?path=' + path);
    }, [location.pathname]);

    const handleLogout = (e) => {
        e.preventDefault();
        e.stopPropagation();

        api.logout().then(response => {
            navigate('/login' + pathToAttachToUrl);
        });
    };

    return (
        <button
            className="button is-white-with-primary-text is-rounded"
            onClick={handleLogout}
        >Log out</button>
    );
}

export default LogoutButton;