import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import { useApi } from '../../hooks';
import { useUserContext } from '../../context';
import { triggerEmailVerifiedEventListener } from "../../services";


const VerifyEmail = () => {
	const api = useApi();
    const { key } = useParams();
    const userContext = useUserContext();
    const [isPending, setIsPending] = useState(true);
    const [isVerified, setIsVerified] = useState(false);

    useEffect(() => {
        api.verifyEmail({ 'key': key }).then(response => {
            setIsPending(false);
            setIsVerified(response.ok);

            if (response.ok) {
                userContext.setEmailAsVerified();
                triggerEmailVerifiedEventListener();
            }
        });
    }, []);

  	return (
		<div className="container">
			<section className="section">
				<div className="columns">
					<div className="column is-3"></div>
					<div className="column">
						<div className="content">
							<h1 className="title">Open an Account</h1>
							{
								isPending
								&& <h2>Email verification is pending! Please wait.</h2>
							}
							{
								!isPending
								&& <>
									{
										isVerified
										&& <>
											<h2 className="subtitle is-4">Congradulations! Your email address has been confirmed successfully.</h2>
											{
												userContext.isAuthenticated
												? <Link
													to="/settings/profile-and-settings/personal-details"
													className="button is-primary is-rounded"
												>My profile</Link>
												: <Link
													to="/login"
													className="button is-primary is-rounded"
												>Log In</Link>
											}
										</>
									}
									{
										!isVerified
										&& <>
											<h2 className="subtitle has-text-primary is-4">Incorrect or already used link for email confirmation</h2>
											<p>
												Please try again or&nbsp;
												<Link
													to="/help-and-contact-us"
													className="link-standard"
												>contact our support</Link>.
											</p>
										</>
									}
								</>
							}
						</div>
					</div>
				</div>
			</section>
		</div>
  	)
}

export default VerifyEmail