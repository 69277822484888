import { useState, useEffect } from 'react';

import {
    FiatFormatter,
    InProgressText,
	CryptoFormatter,
    DataNotLoadedErrorMessage
} from '../../../components';
import { useApi } from '../../../hooks';
import { useUserContext } from '../../../context';


const ListOfWinners = () => {
    const userContext = useUserContext();

    const api = useApi();
    const elementsPerRow = 4;
    const [data, setData] = useState([]);
    const [error, setError] = useState(false);
    const [isPending, setIsPending] = useState(true);

    useEffect(() => {
        api.getWinners().then(response => {
            setIsPending(false);

            if (!response.ok) {
                setError(true);
                return;
            }

            setData(response.data);
        });
    }, []);

    return (
        <>
            {
                data.length > 0
                && (
                    <>
                        <section className="section">
                            <div className="content">
                                <h1 className="title">Winners</h1>

                                {error && <DataNotLoadedErrorMessage />}
                                {isPending && <InProgressText/>}

                                {
                                    [...Array(Math.ceil(data.length/elementsPerRow)).keys()].map((rowNumber) => (
                                        <div key={`row-${rowNumber}`} className="columns">
                                            {
                                                Object.values(
                                                    Object.keys(data).slice(elementsPerRow * rowNumber, elementsPerRow * rowNumber + elementsPerRow).reduce((result, key) => {
                                                        result[key] = data[key];

                                                        return result;
                                                    }, {})
                                                ).map((winner) => (
                                                    <div key={winner.id} className="column is-3">
                                                        <div className="card winner-card">
                                                            <div className="card-content">
                                                                <div className="media">
                                                                    <div className="media-left">
                                                                        <figure className="image is-48x48 ml-0 mr-0">
                                                                            <img src={winner.user_image} width="96" height="96" alt={winner.user_name} />
                                                                        </figure>
                                                                    </div>
                                                                    <div className="media-content">
                                                                        <p className="title is-5">{winner.user_name}</p>
                                                                        <p className="subtitle has-text-primary is-6">
                                                                            <time dateTime="2022-06-21">{winner.when}</time>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="content">
                                                                    Played "{winner.game_name}" and won a jackpot of&nbsp;
                                                                    <strong>
                                                                        <CryptoFormatter
                                                                            type="DETAIL"
                                                                            data={winner.amount_won.crypto}
                                                                        />&nbsp;
                                                                        (<FiatFormatter
                                                                            type="DETAIL"
                                                                            data={winner.amount_won.fiat[userContext.currency_code]}
                                                                        />)
                                                                    </strong>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                        </section>

                        <hr />
                    </>
                )
            }
        </>
    );
}

export default ListOfWinners;