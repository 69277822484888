import { useEffect, useState } from "react";


const CryptoFormatter = ({type = 'TIGHT', data = false}) => {
    const [amount, setAmount] = useState(0);

    const removeZerosAtTheEnd = (string) => {
        if (string.slice(-3) === '.00') {
            string = string.slice(0, -3);
        } else if(string.slice(-1) === '0') {
            string = string.slice(0, -1);
        }

        return string;
    };

    const tightFormat = (amount) => {
        return removeZerosAtTheEnd(amount.toFixed(2));
    };

    useEffect(() => {
        if (data) {
            switch (type) {
                case 'DETAIL':
                    setAmount(data.value);
                    break;
                case 'TIGHT':
                default:
                    setAmount(tightFormat(data.value));
            }
        }
    }, [data]);

    return (
        data
        && <>
            {amount} {data.code}
        </>
    );
}

export default CryptoFormatter;