import { Link, useLocation } from 'react-router-dom';
import {
	FaTwitter, FaFacebookF, FaTelegramPlane, FaRedditAlien
} from 'react-icons/fa';

import ageplusImg from '../assets/img/18-plus.png';
import appleStoreLogoImg from '../assets/img/app-store-logo.png';
import googlePlayStoreLogoImg from '../assets/img/google-play-store-logo.png';


const Footer = () => {
	const location = useLocation();

	return (
    	<>
      		<section className={`section is-hidden-touch give-feedback-section pt-5 pb-5 ${ location.pathname.includes('/watch') ? '' : 'has-background-primary' }`}>
          		<div className="container">
              		<div className="columns is-flex is-vcentered">
						<div className="column is-flex is-justify-content-right">
							<p className={`${ location.pathname.includes('/watch') ? '' : 'has-text-white' }`}>We'd love to know what you think</p>
						</div>
						<div className="column is-flex is-justify-content-left">
							<Link
								to="/feedback"
								className="button is-primary has-border is-rounded"
							>GIVE FEEDBACK</Link>
						</div>
					</div>
				</div>
      		</section>
      		<footer className="footer has-background-white is-hidden-touch pt-0 pb-0">
          		<section className="section pt-5 pb-5">
              		<div className="container">
                  		<div className="columns">
                      		<div className="column is-two-fifths">
                          		<p className="mt-5 pr-4">Coin Lottery games should always be fun, playing in a way that is right for you. Using our handy toolkit you can set limits, take time out or set up reminders.</p>
                      		</div>
                      		<div className="column">
                          		<aside className="menu">
                              		<p className="menu-label">Games</p>
                              		<ul className="menu-list">
                                  		<li>
                                    		<Link to="/how-to-claim">How to claim</Link>
                                  		</li>
                                  		<li>
                                    		<Link to="/where-the-money-goes">Where the money goes</Link>
                                  		</li>
                              		</ul>
                          		</aside>
                      		</div>
                      		<div className="column">
                          		<aside className="menu">
                              		<p className="menu-label">Additional information</p>
                              		<ul className="menu-list">
                                  		<li>
                                    		<Link to="/about-us">About us</Link>
                                  		</li>
										{
											1 !== 1
											&& <li>
												<a href="#" target="_blank" rel="noreferrer nofollow">White Paper</a>
											</li>
										}
                                  		<li>
                                    		<Link to="/help-and-contact-us">Help & contact us</Link>
                                  		</li>
                              		</ul>
                          		</aside>
                      		</div>
                      		<div className="column">
                          		<aside className="menu">
                              		<p className="menu-label">Legal</p>
                              		<ul className="menu-list">
                                  		<li>
                                    		<Link to="/terms">Terms</Link>
                                  		</li>
                                  		<li>
                                    		<Link to="/privacy-policy">Privacy Policy</Link>
                                  		</li>
                                  		<li>
                                    		<Link to="/cookies">Cookies</Link>
                                  		</li>
                              		</ul>
                          		</aside>
                      		</div>
                  		</div>
              		</div>
          		</section>

          		<hr className="mt-0 mb-0"/>

          		<section className="section pt-5 pb-5">
              		<div className="container">
                  		<div className="columns is-flex is-vcentered">
                      		<div className="column is-one-third">
                          		<div className="columns is-flex is-vcentered">
                              		<div className="column is-one-fifth">
                                  		<img src={ageplusImg} width="50" alt="Players must be 18 or over"/>
                              		</div>
                              		<div className="column">
                                  		<span className="is-size-7">Players must be 18 or over.</span>
                              		</div>
                          		</div>
                      		</div>
                      		<div className="column is-one-third">
								<a href="https://twitter.com/CoinLotteryLive" target="_blank" rel="noreferrer nofollow" className="social-icon-link is-size-3 pr-3">
									<FaTwitter/>
								</a>
								<a href="https://www.facebook.com/coinlotterybet/" target="_blank" rel="noreferrer nofollow" className="social-icon-link is-size-3 pr-3">
									<FaFacebookF/>
								</a>
								<a href="https://t.me/coinlotterybet" target="_blank" rel="noreferrer nofollow" className="social-icon-link is-size-3 pr-3">
									<FaTelegramPlane/>
								</a>

								{
									1 !== 1
									&& <a href="#" target="_blank" rel="noreferrer nofollow" className="social-icon-link is-size-3 pr-3">
										<FaRedditAlien/>
									</a>
								}
                      		</div>
							{
								1 !== 1
								&& <>
									<div className="column">
										<a href="#" target="_blank" rel="noreferrer nofollow">
											<img src={appleStoreLogoImg} alt="Apple store"/>
										</a>
									</div>
									<div className="column">
										<a href="#" target="_blank" rel="noreferrer nofollow">
											<img src={googlePlayStoreLogoImg} alt="Google Play store"/>
										</a>
									</div>
								</>
							}
                  		</div>
              		</div>
          		</section>

				<section className="section pt-5 pb-5">
              		<div className="container">
                  		<div className="columns is-flex is-vcentered">
                      		<div className="column">
							  	<span className="is-size-7">The Coin Lottery games on this website are promoted by CoinLottery Limited under licence. </span>
                      		</div>
                  		</div>
              		</div>
          		</section>
      		</footer>
    	</>
  	)
}

export default Footer