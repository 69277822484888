import { Link } from 'react-router-dom';
import { useReducer, useEffect, useCallback } from "react";
import { FaEnvelope, FaCheck, FaExclamationTriangle } from 'react-icons/fa';

import { useApi } from '../../hooks';
import { isValidEmail } from '../../services';
import { ErrorBlock, SuccessBlock } from '../../components';


const ACTIONS = {
    setEmail: 'SET_EMAIL',

    startSending: 'START_SENDING',
    terminateSending: 'TERMINATE_SENDING'
};

function reducer(form, action) {
    let newForm = {...form};
    const resetData = (newForm) => {
        newForm.data.email = '';
    };
    const resetState = (newForm) => {
        newForm.state.isEmailValid = true;

        newForm.state.isSending = false;
        newForm.state.api.failed = false;
        newForm.state.api.success = false;
        newForm.state.api.errorMessage = '';
    };
    const validate = (newForm) => {
        newForm.state.isEmailValid = isValidEmail(newForm.data.email);

        newForm.state.api.failed = false;
        newForm.state.api.success = false;
        newForm.state.api.errorMessage = '';
    };

    switch (action.type) {
        case ACTIONS.setEmail:
            newForm.data.email = action.payload.email;
            validate(newForm);

            return newForm;
        case ACTIONS.startSending:
            newForm.state.isSending = true;

            return newForm;
        case ACTIONS.terminateSending:
            newForm.state.isSending = false;

            if (action.payload.ok) {
                newForm.state.api.success = true;
                resetData(newForm);
                // resetState(newForm);
            } else {
                newForm.state.api.failed = true;
                newForm.state.api.success = false;
                newForm.state.api.errorMessage = action.payload.error.message;
            }

            return newForm;
        default:
            return newForm;
    }
};

const ForgottenPassword = () => {
	const api = useApi();
    const [form, dispatch] = useReducer(reducer, {
        data: {
            email: ''
        },
        state: {
            isEmailValid: true,

            isSending: false,
            api: {
                failed: false,
                success: false,
                errorMessage: ''
            }
        }
    });

    const handleEmailChange = (data) => {
        dispatch(
            {
                type: ACTIONS.setEmail,
                payload: { email: data }
            }
        );
    };
    const handleSend = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (
            !form.state.isSending

            && '' !== form.data.email
            && form.state.isEmailValid
        ) {
            dispatch({ type: ACTIONS.startSending });

            api.forgottenPasswordGetResetLink(
                {
                    'email': form.data.email
                }
            ).then(response => {
                dispatch(
                    {
                        type: ACTIONS.terminateSending,
                        payload: response
                    }
                );
            });
        }
    };

	const handleEnter = useCallback((e) => {
		if (e.key === "Enter") {
			e.preventDefault();
        	e.stopPropagation();

			if (
				'' !== form.data.email
				&& form.state.isEmailValid
			) {
				handleSend(e);
			}
		}
	}, []);
	useEffect(() => {
		document.addEventListener("keydown", handleEnter, false);

		return () => {
		  	document.removeEventListener("keydown", handleEnter, false);
		};
	}, []);
  	return (
		<div className="container">
			<section className="section">
				<div className="columns">
					<div className="column is-3"></div>
					<div className="column">
						<div className="content">
							<h1 className="title">Forgotten Password</h1>

							{
								form.state.api.failed
								&& <ErrorBlock message={form.state.api.errorMessage}/>
							}
							{
								form.state.api.success
								&& <SuccessBlock message="Reset link sent successfully. Please check your email."/>
							}

							<div className="field">
								<div className="control has-icons-left has-icons-right">
									<input
										className={`input ${
											'' !== form.data.email
											&& !form.state.isEmailValid
											&& 'is-danger'
										}`}
										type="email"
										placeholder="Email"
										value={form.data.email}
										onChange={
											(e) => handleEmailChange(e.target.value)
										}
										autoFocus={true}
									/>
									<span className="icon is-small is-left">
										<FaEnvelope />
									</span>
									{
										'' !== form.data.email
										&& (
											<>
												{
													form.state.isEmailValid
													&& <span className="icon is-small is-right">
														<FaCheck/>
													</span>
												}

												{
													!form.state.isEmailValid
													&& <span className="icon is-small is-right">
														<FaExclamationTriangle/>
													</span>
												}
											</>
										)
									}
								</div>
								{
									'' !==form.data.email
									&& !form.state.isEmailValid
									&& <p
										className="help is-danger"
									>Invalid email</p>
								}
							</div>
							<div className="field">
								<p className="control is-pulled-right">
									<button
										className="button is-primary is-rounded"
										onClick={handleSend}
										disabled={
											'' === form.data.email
											|| !form.state.isEmailValid
										}
									>
										Send Reset Link
										{
											form.state.isSending
											&& <span className="cl-button-loader"></span>
										}
									</button>
								</p>
							</div>
							<br />
							<br />
						</div>

						<div className="content">
							<div className="content is-flex is-justify-content-center">
								<p>
									Back to&nbsp;
									<Link
										to="/login"
										className="link-standard"
									>Log In</Link> page
								</p>
							</div>

							<div className="content is-flex is-justify-content-center">
								<p>
									New player?&nbsp;
									<Link
										to="/open-an-account"
										className="link-standard"
									>Open an Account</Link>
								</p>
							</div>

							<div className="content is-flex is-justify-content-center">
								<p className="is-size-7">
									I suspect my account has been accessed by another person.&nbsp;
									<Link
										to="/help-and-contact-us"
										className="link-standard"
									>Contact us</Link> immedietly!
								</p>
							</div>
						</div>
					</div>
					<div className="column is-3"></div>
				</div>
			</section>
		</div>
  	)
}

export default ForgottenPassword