import { useState, useEffect } from 'react';


const CountdownTimer = ({expiresIn, setTerminated = null}) => {
    const [min, setMin] = useState(0);
    const [sec, setSec] = useState(0);
    const [timeLeft, setTimeLeft] = useState(expiresIn);

    const formatTime = (t) => t < 10 ? '0' + t : t;

    useEffect(() => {
        const interval = setInterval(() => {
            const m = Math.floor(timeLeft / 60);
            const s = timeLeft - m * 60;

            setMin(m);
            setSec(s);
            if (m <= 0 && s <= 0) {
                if (setTerminated) setTerminated(true);
                return () => clearInterval(interval);
            }

            setTimeLeft((t) => t - 1);
        }, 1000);

        return () => clearInterval(interval);
    }, [timeLeft]);

    return (
        <>
            <span className="countdownTimer">
                <span className="timeBlock">{formatTime(min)}</span>
                <span className="timeSplitter">:</span>
                <span className="timeBlock">{formatTime(sec)}</span>
            </span>
        </>
    );
}

export default CountdownTimer;