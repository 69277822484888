export function setTokens(access_token, refresh_token) {
    setJwtToken(access_token);
    setRefreshToken(refresh_token);
}

export function removeTokens() {
    removeJwtToken();
    removeRefreshToken();
}



export function getJwtToken() {
    return sessionStorage.getItem("jwt")
}

export function setJwtToken(token) {
    sessionStorage.setItem("jwt", token)
}

function removeJwtToken() {
    sessionStorage.removeItem("jwt")
}



export function getRefreshToken() {
    return window.localStorage.getItem("refreshToken")
}

function setRefreshToken(token) {
    window.localStorage.setItem("refreshToken", token)
}

function removeRefreshToken() {
    window.localStorage.removeItem("refreshToken")
}