import {
    FaCheck,
    FaGlobe,
    FaEnvelope,
    FaExclamationTriangle
} from 'react-icons/fa';
import { useState, useReducer, useEffect } from "react";

import {
    isValidEmail,
    isNotEmptyString,
    isValidTextString,
    isValidShortString
} from '../../../services';
import {
    ErrorBlock,
    SuccessBlock,
    InProgressText,
    DataNotLoadedErrorMessage,
} from '../../../components';
import { useApi } from '../../../hooks';


const ACTIONS = {
    enableEditRegime: 'ENABLE_SWITCH_REGIME',
    disableEditRegime: 'DISABLE_SWITCH_REGIME',

    setName: 'SET_NAME',
    setEmail: 'SET_EMAIL',
    setCountryId: 'SET_COUNTRY_ID',
    setMessage: 'SET_MESSAGE',

    startSending: 'START_SENDING',
    terminateSending: 'TERMINATE_SENDING',
    setIsSent: 'SET_IS_SENT'
};

function reducer(form, action) {
    let newForm = {...form};
    const resetFields = (newForm) => {
        newForm.data.name = '';
        newForm.data.email = '';
        newForm.data.countryId = 0;
        newForm.data.message = '';
    };
    const resetStates = (newForm) => {
        newForm.state.api.serverError = false;
        newForm.state.api.validationError = false;

        newForm.state.isNameValid = true;
        newForm.state.isEmailValid = true;
        newForm.state.isCountryIdValid = true;
        newForm.state.isMessageValid = true;

        newForm.state.isSending = false;
        newForm.state.isEditing = false;
    };
    const validate = (newForm) => {
        newForm.state.api.serverError = false;
        newForm.state.api.validationError = false;

        newForm.state.isNameValid = (
            isNotEmptyString(newForm.data.name)
            && isValidShortString(newForm.data.name)
        );
        newForm.state.isEmailValid = isValidEmail(newForm.data.email);
        newForm.state.isCountryIdValid = newForm.data.countryId !== 0;
        newForm.state.isMessageValid = (
            isNotEmptyString(newForm.data.message)
            && isValidTextString(newForm.data.message)
        );
    };

    switch (action.type) {
        case ACTIONS.enableEditRegime:
            newForm.state.isEditing = true;

            return newForm;
        case ACTIONS.disableEditRegime:
            resetFields(newForm);
            resetStates(newForm);

            return newForm;
        case ACTIONS.setName:
            newForm.data.name = action.payload.name;
            validate(newForm);

            return newForm;
        case ACTIONS.setEmail:
            newForm.data.email = action.payload.email;
            validate(newForm);

            return newForm;
        case ACTIONS.setCountryId:
            newForm.data.countryId = action.payload.countryId;
            validate(newForm);

            return newForm;
        case ACTIONS.setMessage:
            newForm.data.message = action.payload.message;
            validate(newForm);

            return newForm;
        case ACTIONS.startSending:
            newForm.state.isSending = true;

            return newForm;
        case ACTIONS.terminateSending:
            if (action.payload.isChanged) {
                if (action.payload.isSent) {
                    resetFields(newForm);
                    resetStates(newForm);
                    newForm.state.isSent = true;
                } else {
                    newForm.state.isSending = false;

                    switch (action.payload.error) {
                        case 'SERVER_ERROR':
                            newForm.state.api.serverError = true;
                            newForm.state.api.validationError = false;
                            break;
                        default:
                            validate(newForm);
                            newForm.state.api.serverError = false;
                            newForm.state.api.validationError = true;
                            break;
                    }
                }
            } else {
                resetFields(newForm);
                resetStates(newForm);
            }

            return newForm;
        case ACTIONS.setIsSent:
            newForm.state.isSent = action.payload.isSent;
            return newForm;
        default:
            return newForm;
    }
};

const FormContact = () => {
    const api = useApi();

    const [error, setError] = useState(false);
    const [isPending, setIsPending] = useState(true);
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        api.getCountries().then(response => {
            setIsPending(false);

            if (!response.ok) {
                setError(true);
                return;
            }

            setCountries(response.data);
        });
    }, []);

    const [form, dispatch] = useReducer(reducer, {
        data: {
            name: '',
            email: '',
            countryId: 0,
            message: ''
        },
        state: {
            isNameValid: true,
            isEmailValid: true,
            isCountryIdValid: true,
            isMessageValid: true,

            isEditing: false,
            isSending: false,
            isSent: false,
            api: {
                serverError: false,
                validationError: false
            }
        }
    });

    const handleNameChange = (name) => {
        dispatch(
            {
                type: ACTIONS.setName,
                payload: { name: name }
            }
        );
    };
    const handleEmailChange = (email) => {
        dispatch(
            {
                type: ACTIONS.setEmail,
                payload: { email: email }
            }
        );
    };
    const handleCountryIdChange = (countryId) => {
        dispatch(
            {
                type: ACTIONS.setCountryId,
                payload: { countryId: countryId }
            }
        );
    };
    const handleMessageChange = (message) => {
        dispatch(
            {
                type: ACTIONS.setMessage,
                payload: { message: message }
            }
        );
    };
    const handleSend = (e) => {
        e.preventDefault();
        e.stopPropagation();

        dispatch({ type: ACTIONS.startSending });

        if (
            form.data.name !== ''
            && form.data.email !== ''
            && form.data.countryId !== 0
            && form.data.message !== ''

            && form.state.isNameValid
            && form.state.isEmailValid
            && form.state.isCountryIdValid
            && form.state.isMessageValid
        ) {
            api.contact(
                {
                    "name": form.data.name,
                    "email": form.data.email,
                    "country_id": 1 * form.data.countryId,
                    "message": form.data.message
                }
            ).then(response => {
                dispatch(
                    {
                        type: ACTIONS.terminateSending,
                        payload: {
                            isChanged: true,
                            isSent: response.ok,
                            error: response.error
                        }
                    }
                );
            });
        } else {
            dispatch(
                {
                    type: ACTIONS.terminateSending,
                    payload: {
                        isChanged: false
                    }
                }
            );
        }
    };

    useEffect(() => {
        if (form.state.isSent) {
            setTimeout(() => {
                dispatch(
                    {
                        type: ACTIONS.setIsSent,
                        payload: {
                            isSent: false
                        }
                    }
                );
            }, 5000);
        }
    }, [form.state.isSent]);

    return (
        <>
            {error && <DataNotLoadedErrorMessage message="Contact form cannot be loaded."/>}
            {isPending && <InProgressText/>}
            {
                !error
                && !isPending
                && countries
                && <>
                    <h2 className="title is-size-4">
                        How can we help?
                    </h2>
                    {
                        form.state.isSent
                        && <SuccessBlock message="Message has been sent. We'll contact you within 24 hours!"/>
                    }
                    {
                        form.state.api.serverError
                        && <ErrorBlock message="Error. Please wait a few minutes before you try again."/>
                    }
                    {
                        form.state.api.validationError
                        && <ErrorBlock message="Please check the fields below and make sure all the required data are filled in."/>
                    }

                    <div className="field">
                        <label className="label">Name</label>
                        <div className="control has-icons-right">
                            <input
                                className={`input ${
                                    '' !== form.data.name
                                    && !form.state.isNameValid
                                    && 'is-danger'
                                }`}
                                type="text"
                                placeholder="Your name"
                                value={form.data.name}
                                onChange={(e) => handleNameChange(e.target.value)}
                                autoFocus={true}
                            />
                            {
                                '' !== form.data.name
                                && (
                                    <>
                                        {
                                            form.state.isNameValid
                                            && <span className="icon is-small is-right">
                                                <FaCheck/>
                                            </span>
                                        }

                                        {
                                            !form.state.isNameValid
                                            && <span className="icon is-small is-right">
                                                <FaExclamationTriangle/>
                                            </span>
                                        }
                                    </>
                                )
                            }
                        </div>
                        {
                            '' !==form.data.name
                            && !form.state.isNameValid
                            && <p className="help is-success">The name cannot be empty and no longer than 250 characters.</p>
                        }
                    </div>

                    <div className="field">
                        <label className="label">Email</label>
                        <div className="control has-icons-left has-icons-right">
                            <input
                                className={`input ${
                                    '' !== form.data.email
                                    && !form.state.isEmailValid
                                    && 'is-danger'
                                }`}
                                type="email"
                                placeholder="Email address"
                                value={form.data.email}
                                onChange={(e) => handleEmailChange(e.target.value)}
                            />
                            <span className="icon is-small is-left">
                                <FaEnvelope />
                            </span>
                            {
                                '' !== form.data.email
                                && (
                                    <>
                                        {
                                            form.state.isEmailValid
                                            && <span className="icon is-small is-right">
                                                <FaCheck/>
                                            </span>
                                        }

                                        {
                                            !form.state.isEmailValid
                                            && <span className="icon is-small is-right">
                                                <FaExclamationTriangle/>
                                            </span>
                                        }
                                    </>
                                )
                            }
                        </div>
                        {
                            '' !==form.data.email
                            && !form.state.isEmailValid
                            && <p className="help is-danger">This email is invalid.</p>
                        }
                    </div>

                    <div className="field">
                        <label className="label">Country</label>
                        <div className="control has-icons-left">
                            <div className="select">
                                <select
                                    onChange={(e) => handleCountryIdChange(e.target.value)}
                                    value={form.data.countryId}
                                >
                                    <option value="0">- - - Choose Country - - -</option>
                                    {
                                        countries.map((country) => (
                                            <option
                                                key={country.id}
                                                value={country.id}
                                            >
                                                {country.name}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                            <span className="icon is-medium is-left">
                                <FaGlobe />
                            </span>
                        </div>
                    </div>

                    <div className="field">
                        <label className="label">Message</label>
                        <div className="control">
                            <textarea
                                className={`textarea ${
                                    '' !== form.data.message
                                    && !form.state.isMessageValid
                                    && 'is-danger'
                                }`}
                                placeholder="Question, inquire or else"
                                value={form.data.message}
                                onChange={(e) => handleMessageChange(e.target.value)}
                            ></textarea>
                        </div>
                        {
                            '' !==form.data.message
                            && !form.state.isMessageValid
                            && <p className="help is-danger">This message cannot be empty.</p>
                        }
                    </div>

                    <div className="field is-grouped is-pulled-right">
                        <div className="control">
                            <button
                                className="button is-primary is-rounded"
                                onClick={handleSend}
                                disabled={
                                    !isNotEmptyString(form.data.name)
                                    || !isNotEmptyString(form.data.email)
                                    || form.data.countryId === 0
                                    || !isNotEmptyString(form.data.message)

                                    || !form.state.isNameValid
                                    || !form.state.isEmailValid
                                    || !form.state.isCountryIdValid
                                    || !form.state.isMessageValid
                                }
                            >
                                Send
                                {
                                    form.state.isSending
                                    && <span className="cl-button-loader"></span>
                                }
                            </button>
                        </div>
                    </div>
                </>
            }
        </>
    );
}

export default FormContact;