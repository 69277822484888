import { Link } from 'react-router-dom';

import { MenuSettingsMobile } from '../../../components';


const Info = () => {
  	return (
		<div className="container is-fixed-top is-hidden-desktop">
			<MenuSettingsMobile />

			<section className="section pt-0">
				<div className="content">
					<h2 className="title is-size-4 mb-0">Games</h2>
					<p>
						<Link to="#" className="link-standard">How to claim</Link>
						<br />
						<Link to="#" className="link-standard">Where the money goes</Link>
					</p>
				</div>
				<div className="content">
					<h2 className="title is-size-4 mb-0">Additional</h2>
					<p>
						<Link to="#" className="link-standard">About Us</Link>
						<br />
						<Link to="#" className="link-standard">White paper</Link>
						<br />
						<Link to="#" className="link-standard">Help & Contact Us</Link>
						<br />
						<Link to="#" className="link-standard">Give Feedback</Link>
					</p>
				</div>
				<div className="content">
					<h2 className="title is-size-4 mb-0">Legal</h2>
					<p>
						<Link to="#" className="link-standard">Terms & Rules</Link>
						<br />
						<Link to="#" className="link-standard">Privacy Policy</Link>
					</p>
				</div>
			</section>
		</div>
  	)
}

export default Info