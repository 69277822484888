import { FaUserAlt } from 'react-icons/fa';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import { useApi } from '../hooks';
import { getPathForUrl } from '../services';
import ListOfGames from './Lists/ListOfGames';
import { useUserContext, useGamesContext } from '../context';
import ListOfGamesPointingToResults from './Lists/ListOfGamesPointingToResults';

import gamesImg from '../assets/img/games.png'
import profileImg from '../assets/img/profile.png'
import loginImg from '../assets/img/login.png';
import resultsImg from '../assets/img/results.png';
import winnersImg from '../assets/img/winners.png';
import logo from '../assets/img/CoinLottery-Logo-Dark.png';
import gamesActiveImg from '../assets/img/games-active.png';
import loginActiveImg from '../assets/img/login-active.png';
import resultsActiveImg from '../assets/img/results-active.png';
import winnersActiveImg from '../assets/img/winners-active.png';
import profileActiveImg from '../assets/img/profile-active.png';


const Navbar = () => {
	const api = useApi();
    const navigate = useNavigate();
	const location = useLocation();
	const userContext = useUserContext();
	const gameContext = useGamesContext();
	const [pathToAttachToUrl, setPathToAttachToUrl] = useState('');
	const firstGame = gameContext.games && gameContext.games.length > 0
		? gameContext.games[0]
		: 'bitcoin-lotto';

	const [game, setGame] = useState(false);
	const currentDatetimeUTC = Math.ceil(new Date().getTime()/1000)
	const displayWatchDrawsXSecondsBeforeGameStart = 3600;

	useEffect(() => {
		if (gameContext.games) {
			for (let i = 0; i < gameContext.games.length; i++) {
				if (
					gameContext.games[i].next_draw.end_at > currentDatetimeUTC
					&& gameContext.games[i].next_draw.start_at - currentDatetimeUTC < displayWatchDrawsXSecondsBeforeGameStart
				) {
					setGame(gameContext.games[i]);
					break;
				}
			}
		}
	}, [gameContext])

    useEffect(() => {
        const path = getPathForUrl(location.pathname);
        setPathToAttachToUrl(null === path ? '' : '?path=' + path);
    }, [location.pathname]);

	const handleLogout = (e) => {
        e.preventDefault();
        e.stopPropagation();

        api.logout().then(response => {
            navigate('/login' + pathToAttachToUrl);
        });
    };

  	return (
		<>
			<nav className="navbar has-shadow is-white is-hidden-touch">
				<div className="navbar-brand pl-3 pr-6">
					<div className="navbar-item">
						<Link to="/">
							<img src={logo} alt="CoinLottery logo" width="165" />
						</Link>
					</div>
				</div>

				<div className="navbar-menu pr-3">
					<div className="navbar-start">
						{
							gameContext.games
							&& gameContext.games.length > 0
							&& <div className="navbar-item has-dropdown is-hoverable">
								<Link to="#" className="navbar-link">
									Games
								</Link>

								<div className="navbar-dropdown pt-0 pb-0">
									<section className="section p-4">
										<div className="container">
											<ListOfGames location="nav" />
										</div>
									</section>
								</div>
							</div>
						}

						{
							gameContext.games
							&& gameContext.games.length > 0
							&& <div className="navbar-item has-dropdown is-hoverable">
								<Link to="#" className="navbar-link">
									Results
								</Link>

								<div className="navbar-dropdown pt-0 pb-0">
									<section className="section p-4">
										<div className="container">
											<ListOfGamesPointingToResults pointToDraws={!userContext.isAuthenticated}/>

											<div className="columns">
												<Link to="/how-to-claim" className="column p-1">
													<div className="lotto-card-purple-dark is-clickable border-radius-10 has-text-white has-text-centered px-3 py-3">
														<div className="jackpot-size-nav is-size-6 has-text-weight-bold">
															How to claim won prizes or jackpot?
														</div>
													</div>
												</Link>
											</div>
										</div>
									</section>
								</div>
							</div>
						}

						<Link
							to="/winners-and-good-causes"
							className={`navbar-item ${location.pathname.includes('/winners-and-good-causes') ? 'is-active' : ''}`}
						>
							Winners & Good Causes
						</Link>
						{
							game
							&& <Link
								to={`/game/${game.key}/watch`}
								className={`navbar-item ${location.pathname.includes('/winners-and-good-causes') ? 'is-active' : ''}`}
							>
								<strong>Watch The Draws</strong>
							</Link>
						}
						{
							1 !== 1
							&& <Link
								to="/links"
								className={`navbar-item ${location.pathname.includes('/links') ? 'is-active' : ''}`}
							>
								Links
							</Link>
						}
					</div>

					{
						!userContext.isAuthenticated
						&& <div className="navbar-end">
							<div className="navbar-item">
								<div className="field is-grouped">
									<p className="control">
										<Link to="/login" className="button login-button is-rounded">LOG IN</Link>
									</p>
								</div>
							</div>
						</div>
					}
					{
						userContext.isAuthenticated
						&& <div className="navbar-end">
							<div className="navbar-item has-dropdown is-hoverable">
								<p className="navbar-link navbar-link-circle p-0 mr-3">
									<i><FaUserAlt /></i>
								</p>

								<div className="navbar-dropdown navbar-dropdown-right">
									<Link
										to="/settings/profile-and-settings/personal-details"
										className="navbar-item"
									>
										My profile
									</Link>

									<hr className="navbar-divider"/>
									<Link
										to="/settings/general/my-games"
										className="navbar-item"
									>
										My games
									</Link>

									<hr className="navbar-divider"/>
									<Link
										to="/settings/funds-and-payments/crypto-account-details"
										className="navbar-item"
									>
										Crypto account details
									</Link>

									<hr className="navbar-divider"/>
									<Link
										to="/settings/general/messages"
										className="navbar-item"
									>
										Messages
									</Link>

									<hr className="navbar-divider"/>
									<Link
										to="/"
										className="navbar-item"
										onClick={handleLogout}
									>
										Log Out
									</Link>
								</div>
							</div>
						</div>
					}
				</div>
			</nav>

			<div className="navbar has-shadow is-white is-fixed-bottom is-hidden-desktop" id="navbar-mobile">
				<section className="section has-text-centered px-1 py-1">
					<div className="container">
						<div className="columns">
							<div className="column pb-0">
								<Link
									to={`/game/${firstGame.key}`}
									id="games-column"
									className={`navbar-item ${
										(
											location.pathname.includes('/game')
											&& !location.pathname.includes('/results')
											&& !location.pathname.includes('/draws')
										)
										? 'is-active'
										: ''
									}`}
								>
									<div className="navbar-mobile-icons pb-2">
										<img
											id="games-column-image"
											alt="Games"
											src={
												(
													location.pathname.includes('/game')
													&& !location.pathname.includes('/results')
													&& !location.pathname.includes('/draws')
												)
												? gamesActiveImg
												: gamesImg
											}
										/>
									</div>
									Games
								</Link>
							</div>
							<div className="column pb-0">
								<Link
									to={`/game/${firstGame.key}/results`}
									id="results-column"
									className={`navbar-item ${
										(
											location.pathname.includes('/game')
											&& (
												location.pathname.includes('/results')
												|| location.pathname.includes('/draws')
											)
										)
										? 'is-active'
										: ''
									}`}
								>
									<div className="navbar-mobile-icons pb-2">
										<img
											id="results-column-image"
											alt="Results"
											src={
												(
													location.pathname.includes('/game')
													&& (
														location.pathname.includes('/results')
														|| location.pathname.includes('/draws')
													)
												)
												? resultsActiveImg
												: resultsImg
											}
										/>
									</div>
									Results
								</Link>
							</div>
							<div className="column pb-0">
								<Link
									to="/winners-and-good-causes"
									id="winners-column"
									className={`navbar-item ${
										location.pathname.includes('/winners-and-good-causes')
										? 'is-active'
										: ''
									}`}
								>
									<div className="navbar-mobile-icons pb-2">
										<img
											id="winners-column-image"
											alt="Winners"
											src={
												location.pathname.includes('/winners-and-good-causes')
												? winnersActiveImg
												: winnersImg
											}
										/>
									</div>
									Winners
								</Link>
							</div>
							{
								!userContext.isAuthenticated
								&& <div className="column pb-0">
									<Link
										to="/login"
										id="login-column"
										className={`navbar-item ${
											location.pathname === '/login'
											? 'is-active'
											: ''
										}`}
									>
										<div className="navbar-mobile-icons pb-2">
											<img
												id="login-column-image"
												alt="Log In"
												src={
													location.pathname === '/login'
													? loginActiveImg
													: loginImg
												}
											/>
										</div>
										Log In
									</Link>
								</div>
							}
							{
								userContext.isAuthenticated
								&& <div className="column pb-0">
									<Link
										to="/settings/profile-and-settings/personal-details"
										id="profile-column"
										className={`navbar-item ${
											(
												location.pathname.includes('/settings/profile-and-settings')
												|| location.pathname.includes('/settings/funds-and-payments')
												|| location.pathname.includes('/settings/general')
											)
											? 'is-active'
											: ''
										}`}
									>
										<div
											className="navbar-mobile-icons pb-2"
										>
											<img
												id="profile-column-image"
												alt="Profile"
												src={
													(
														location.pathname.includes('/settings/profile-and-settings')
														|| location.pathname.includes('/settings/funds-and-payments')
														|| location.pathname.includes('/settings/general')
													)
													? profileActiveImg
													: profileImg
												}
											/>
										</div>
										Profile
									</Link>
								</div>
							}
						</div>
					</div>
				</section>
			</div>
		</>
  	)
}

export default Navbar