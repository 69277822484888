import { useEffect, useState } from "react";

import { useUserContext } from '../context';


const GameStartsAt = ({startAt: startTimestampUTC, details = true}) => {
    let days = {
        1: 'Monday',
        2: 'Thursday',
        3: 'Wednesday',
        4: 'Tuesday',
        5: 'Friday',
        6: 'Saturday',
        7: 'Sunday'
    };
    let months = {
        0: 'Jan',
        1: 'Feb',
        2: 'Mar',
        3: 'Apr',
        4: 'May',
        5: 'Jun',
        6: 'Jul',
        7: 'Aug',
        8: 'Sep',
        9: 'Oct',
        10: 'Nov',
        11: 'Dec',
    };
    const userContext = useUserContext();
    const [string, setString] = useState('');

	useEffect(() => {
		if (userContext) {
            const timezoneUtcOffsetInSeconds = 60 * (
                userContext.isAuthenticated
                ? userContext.user.timezone_utc_offset
                : (-1) * (new Date().getTimezoneOffset())
            )

            let now = getTimeObject(timezoneUtcOffsetInSeconds);
            let nowLocal = now.getTime()/1000;
            let nowDayLocal = getDay(now);

            let start = getTimeObject(startTimestampUTC);
            let startLocal = start.getTime()/1000;
            let startDayLocal = getDay(start);

            let daysToStart = getDayDiff(nowLocal, startLocal);
            setString(
                getString(
                    nowLocal,
                    nowDayLocal,
                    start,
                    startLocal,
                    startDayLocal,
                    daysToStart
                )
            );
        }
	}, [userContext]);

    const getTimeObject = (timestamp = false, timezoneUtcOffsetInSeconds = 0) => {
        let timeObject = timestamp
            ? new Date(timestamp * 1000)
            : new Date();

        timeObject = new Date(
            timeObject.getTime() + timeObject.getTimezoneOffset() * 60000
        );

        timeObject.setTime(
            timeObject.getTime() + timezoneUtcOffsetInSeconds * 1000
        );

        return timeObject;
    };

    const getDay = (timeObject) => {
        let day = timeObject.getUTCDay();
        if (day === 0) day = 7;

        return day;
    };

    const getDayDiff = (timestampOne, timestampTwo) => {
        return (timestampTwo - timestampOne)/(24*3600);
    }

    const getString = (
        nowLocal,
        nowDayLocal,
        start,
        startLocal,
        startDayLocal,
        daysToStart
    ) => {
        if (nowLocal >= startLocal) return 'Soon';

        let string = '';

        if (nowDayLocal === startDayLocal && daysToStart <= 1) {
            string = start.getHours() > 18 ? 'Tonight' : 'Today';
        } else {
            if (daysToStart <= 7) {
                string =  (
                    nowDayLocal > startDayLocal
                        ? 'Next '
                        : ''
                ) + days[startDayLocal];
            } else {
                let year = start.getFullYear();
                let month = months[start.getMonth()];
                let day = start.getDate();

                string = month + ' ' + day + ', ' + year;
            }
        }

        return string + (
            details
                ? getHourDetails(start)
                : ''
        )
    }

    const getHourDetails = (timeObject) => {
        let hour = timeObject.getHours();
        let min = timeObject.getMinutes();
        min = min < 10 ? '0' + min : min;

        let prefix = '';
        if (hour < 13) {
            prefix = 'AM';
        } else {
            hour -= 1;
            prefix = 'PM';
        }

        return ' at ' + hour + ':' + min + ' ' + prefix;
    }

    return (
        <>{string}</>
    );
}

export default GameStartsAt;