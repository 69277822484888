import { Link, useLocation } from 'react-router-dom';

import { useUserContext } from '../../context';

const MenuSettingsDesktop = () => {
    const location = useLocation();
    const userContext = useUserContext();

  	return (
        <aside className="menu">
            <p className="menu-label">General</p>
            <ul className="menu-list">
                <li>
                    <Link
                        className={`${location.pathname.includes('/settings/general/my-games') ? 'has-background-light' : ''}`}
                        to="/settings/general/my-games"
                    >My games</Link>
                </li>
                <li>
                    <Link
                        className={`${location.pathname.includes('/settings/general/messages') ? 'has-background-light' : ''}`}
                        to="/settings/general/messages"
                    >Messages</Link>
                </li>
            </ul>
            <p className="menu-label">Profile & Settings</p>
            <ul className="menu-list">
                <li>
                    <Link
                        className={`${location.pathname.includes('/settings/profile-and-settings/personal-details') ? 'has-background-light' : ''}`}
                        to="/settings/profile-and-settings/personal-details"
                    >Personal details</Link>
                </li>
                {
                    userContext.user
                    && !userContext.user.is_social_account
                    && <li>
                        <Link
                            className={`${location.pathname.includes('/settings/profile-and-settings/password-change') ? 'has-background-light' : ''}`}
                            to="/settings/profile-and-settings/password-change"
                        >Password change</Link>
                    </li>
                }
                <li>
                    <Link
                        className={`${location.pathname.includes('/settings/profile-and-settings/security') ? 'has-background-light' : ''}`}
                        to="/settings/profile-and-settings/security"
                    >Security</Link>
                </li>
                <li>
                    <Link
                        className={`${location.pathname.includes('/settings/profile-and-settings/email-preferences') ? 'has-background-light' : ''}`}
                        to="/settings/profile-and-settings/email-preferences"
                    >Email preferences</Link>
                </li>
            </ul>
            <p className="menu-label">Funds & Payments</p>
            <ul className="menu-list">
                <li>
                    <Link
                        className={`${location.pathname.includes('/settings/funds-and-payments/transaction-history') ? 'has-background-light' : ''}`}
                        to="/settings/funds-and-payments/transaction-history"
                    >Transaction history</Link>
                </li>
                <li>
                    <Link
                        className={`${location.pathname.includes('/settings/funds-and-payments/crypto-account-details') ? 'has-background-light' : ''}`}
                        to="/settings/funds-and-payments/crypto-account-details"
                    >Crypto account details</Link>
                </li>
            </ul>
        </aside>
    )
}

export default MenuSettingsDesktop