import { Link } from 'react-router-dom';
import { useState, useEffect } from "react";

import ListOfCauses from './Lists/ListOfCauses';
import ListOfWinners from './Lists/ListOfWinners';
import { useGamesContext } from '../../context';


const WinnersAndGoodCauses = () => {
	const [game, setGame] = useState(false);
	const gameContext = useGamesContext();

	useEffect(() => {
		if (gameContext.games && gameContext.games.length > 0) {
			setGame(gameContext.games[0]);
		}
	}, [gameContext]);

	return (
		<div className="container">
			<ListOfWinners />

			<section className="section">
				<div className="content is-flex is-justify-content-center">
					<h1 className="title">Every Time You Play, Society Wins</h1>
				</div>
				<div className="content">
					<div className="columns">
						<div className="column">
							<div className="card">
								<div className="card-content">
									<div className="content">
										<h2 className="title is-4">Where the money goes</h2>
										<p>From scout huts to Olympic champions, we provide funding to a huge variety of projects. Over 660,000 and counting...</p>
										<br />
										<Link to="/where-the-money-goes" className="button is-secondary is-rounded mt-5">Find out more</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="column">
							<div className="card">
								<div className="card-content">
									<div className="content">
										<h2 className="title is-4">How to claim my win</h2>
										<p>This is the beauty of the blockchain - No need to claim anything. Any prize or jackpot you won, is automatically transferred to the crypto address you provided.</p>
										<Link to="/how-to-claim" className="button is-secondary is-rounded mt-5">Read more</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{
					game
					&& <div className="content is-flex is-justify-content-center">
						<Link to={`/game/${game.key}`} className="button is-primary is-rounded mt-5 pl-6 pr-6">Play</Link>
					</div>
				}
			</section>

			<ListOfCauses />

			<div className="block my-6"></div>
		</div>
  	)
}

export default WinnersAndGoodCauses