import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

import { FaMinus, FaPlus } from 'react-icons/fa';

import { useWindowSize } from '../hooks';
import { FiatFormatter } from '../components';


const LuckyDipBox = (
    {
        user = false,
        game = false,
        additionalClassName
    }
) => {
    if (typeof additionalClassName === 'undefined') additionalClassName = '';
    const mobileWindowMaxSize = 1023;
	const size = useWindowSize();

    const [luckyDips, setLuckyDips] = useState(0);
    useEffect(() => {
		if (game) {
            setLuckyDips(0);
        }
	}, [game]);

    const handleDecreaseLuckyDips = () => {
        if (luckyDips > 0) {
            setLuckyDips(luckyDips - 1);
        }
    };

    const handleIncreaseLuckyDips = () => {
        setLuckyDips(luckyDips + 1);
    };

    return (
        user
        && game
        && <div className={`card has-background-${game.color} has-text-white quick-lucky-dip-block ${additionalClassName}`}>
            <div className="card-content">
                <div className="content">
                    <h2 className="title has-text-white is-5 mb-2">Fancy a Lucky Dip?</h2>
                    <p className="is-size-7">Play a quick Lucky Dip in the next SuperGame draw.</p>
                    <div className="columns">
                        <div className="column">
                            <h3 className="title has-text-white is-6">How many lines?</h3>
                            <div className="columns is-flex is-vcentered is-align-content-center">
                                <div className="column">
                                    <p className="tickets-subtract-or-add">
                                        <FaMinus onClick={handleDecreaseLuckyDips}/>
                                    </p>
                                </div>
                                <div className="column number-of-tickets">{luckyDips}</div>
                                <div className="column">
                                    <p className="tickets-subtract-or-add">
                                        <FaPlus onClick={handleIncreaseLuckyDips}/>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="column continue-to-play">
                            <h3 className="title has-text-white is-6">
                                Total:&nbsp;
                                <span className="has-text-weight-normal">
                                    <FiatFormatter
                                        type="TICKET"
                                        data={
                                            {
                                                code: user.currency_code,
                                                symbol: game.ticket_price.fiat[user.currency_code].symbol,
                                                position_of_symbol: game.ticket_price.fiat[user.currency_code].position_of_symbol,
                                                value: (
                                                    luckyDips - game.tickets_won > 0
                                                    ? (luckyDips  - game.tickets_won ) * game.ticket_price.fiat[user.currency_code].value
                                                    : 0
                                                )
                                            }
                                        }
                                    />
                                </span>
                            </h3>
                            {
                                luckyDips > 0
                                && <Link
                                    to={`/game/${game.key}/lucky-dips/${luckyDips}${size.width <= mobileWindowMaxSize ? '#game-settings-block' : ''}`}
                                    className={`button is-primary has-border is-rounded is-rainbow-${game.color}`}
                                >QUICK LUCKY DIP</Link>
                            }
                            {
                                game.tickets_won > 0
                                && <p
                                    className="mt-4 is-size-7"
                                >You have {game.tickets_won} free tickets won during previous draws. They will be deducted from the final price.</p>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LuckyDipBox;