import { Link } from 'react-router-dom';

const Links = () => {
  	return (
        <div className="container my-6">
            <div className="columns">
                <div className="column">
                    <h1>General</h1>
                    <p><Link to="/">Home</Link></p>
                    <p><Link to="/ercfiunhriunnhe">404 Page Not Found</Link></p>
                </div>
                <div className="column">
                    <h1>User Authorization</h1>
                    <p><Link to="/open-an-account">Open an Account</Link></p>
                    <p><Link to="/verify-email/somekey">Verify Email</Link></p>
                    <p><Link to="/login">Log in</Link></p>
                    <p><Link to="/login-secure/somekey">Log In - Secure</Link></p>
                    <p><Link to="/forgotten-password">Forgotten password</Link></p>
                    <p><Link to="/reset-password/somekey">Reset password</Link></p>
                </div>
                <div className="column">
                    <h1>Game</h1>
                    <p><Link to="/game/bitcoin-lotto/draws">Draw History</Link></p>
                    <p><Link to="/game/bitcoin-lotto">Game</Link></p>
                    <p><Link to="/game/bitcoin-lotto/results">Results</Link></p>
                    <p><Link to="/game/bitcoin-lotto/watch">Watch Draw</Link></p>
                </div>
            </div>
            <div className="columns">
                <div className="column">
                    <h1>Settings</h1>
                    <p><Link to="/settings/general/my-games">My Games</Link></p>
                    <p><Link to="/settings/general/messages">Messages</Link></p>
                    <p><Link to="/settings/profile-and-settings/personal-details">Personal details</Link></p>
                    <p><Link to="/settings/profile-and-settings/password-change">Password change</Link></p>
                    <p><Link to="/settings/profile-and-settings/security">Security</Link></p>
                    <p><Link to="/settings/profile-and-settings/email-preferences">Email preferences</Link></p>
                    <p><Link to="/settings/funds-and-payments/transaction-history">Transaction history</Link></p>
                    <p><Link to="/settings/funds-and-payments/crypto-account-details">Crypto account details</Link></p>
                </div>
                <div className="column">
                    <h1>Static pages</h1>
                    <p><Link to="/how-to-claim">How to claim</Link></p>
                    <p><Link to="/where-the-money-goes">Where the money goes</Link></p>
                    <p><Link to="/about-us">About us</Link></p>
                    <p><Link to="/help-and-contact-us">Help & contact us</Link></p>
                    <p><Link to="/terms">Terms</Link></p>
                    <p><Link to="/privacy-policy">Privacy Policy</Link></p>
                    <p><Link to="/cookies">Cookies</Link></p>
                    <p><Link to="/winners-and-good-causes">Winners And Good Causes</Link></p>
                    <p><Link to="/feedback">Feedback Form</Link></p>
                </div>
                <div className="column"></div>
            </div>
        </div>
  	)
}

export default Links