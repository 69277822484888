import { TbLetterA } from 'react-icons/tb';
import { useReducer, useEffect, useState } from "react";
import { FaQuestion, FaCheck, FaExclamationTriangle } from 'react-icons/fa';

import {
    InProgressText,
    DataNotLoadedErrorMessage
} from '../../../../components';
import { useApi } from '../../../../hooks';
import { isValidShortString, isNotEmptyString } from '../../../../services';


const ACTIONS = {
    set: 'SET',

    enableEditRegime: 'ENABLE_SWITCH_REGIME',
    disableEditRegime: 'DISABLE_SWITCH_REGIME',

    setIsActive: 'SET_IS_ACTIVE',
    setSecurityQuestionId: 'SET_SECURITY_QUESTION_ID',
    setAnswer: 'SET_ANSWER',

    startSaving: 'START_SAVING',
    terminateSaving: 'TERMINATE_SAVING'
};

function reducer(form, action) {
    let newForm = {...form};

    const resetFields = (newForm, securitySecurityQuestions) => {
        newForm.data.isActive = securitySecurityQuestions.isActive;
        newForm.data.securityQuestionId = securitySecurityQuestions.securityQuestionId;
        newForm.data.answer = securitySecurityQuestions.answer;
    };
    const resetStates = (newForm) => {
        newForm.state.api.serverError = false;
        newForm.state.api.validationError = false;

        newForm.state.isAnswerValid = true;

        newForm.state.isSaving = false;
        newForm.state.isEditing = false;
    };
    const setFields = (newForm, action) => {
        action.payload.securitySecurityQuestions.isActive = newForm.data.isActive;
        action.payload.securitySecurityQuestions.securityQuestionId = newForm.data.securityQuestionId;
        action.payload.securitySecurityQuestions.answer = newForm.data.answer;
    };
    const validate = (newForm) => {
        if (newForm.data.isActive) {
            newForm.state.isAnswerValid = (
                isNotEmptyString(newForm.data.answer)
                && isValidShortString(newForm.data.answer)
            );
        } else {
            newForm.state.isAnswerValid = true;
        }

    };

    switch (action.type) {
        case ACTIONS.set:
            return {
                data: {
                    isActive: action.payload.securitySecurityQuestions.isActive,
                    securityQuestionId: action.payload.securitySecurityQuestions.securityQuestionId,
                    answer: action.payload.securitySecurityQuestions.answer
                },
                state: {
                    isAnswerValid: true,

                    isEditing: false,
                    isSaving: false,
                    api: {
                        serverError: false,
                        validationError: false
                    }
                }
            };
        case ACTIONS.enableEditRegime:
            newForm.state.isEditing = true;

            return newForm;
        case ACTIONS.disableEditRegime:
            resetFields(newForm, action.payload.securitySecurityQuestions);
            resetStates(newForm);

            return newForm;
        case ACTIONS.setIsActive:
            newForm.data.isActive = action.payload.isActive;
            validate(newForm);

            return newForm;
        case ACTIONS.setSecurityQuestionId:
            newForm.data.securityQuestionId = action.payload.securityQuestionId;
            validate(newForm);

            return newForm;
        case ACTIONS.setAnswer:
            newForm.data.answer = action.payload.answer;
            validate(newForm);

            return newForm;
        case ACTIONS.startSaving:
            newForm.state.isSaving = true;

            return newForm;
        case ACTIONS.terminateSaving:
            if (action.payload.isChanged) {
                if (action.payload.isSaved) {
                    setFields(newForm, action);
                    resetStates(newForm);
                } else {
                    newForm.state.isSaving = false;

                    switch (action.payload.error) {
                        case 'SERVER_ERROR':
                            newForm.state.api.serverError = true;
                            newForm.state.api.validationError = false;
                            break;
                        default:
                            validate(newForm);
                            newForm.state.api.serverError = false;
                            newForm.state.api.validationError = true;
                            break;
                    }
                }
            } else {
                resetFields(newForm, action.payload.securitySecurityQuestions);
                resetStates(newForm);
            }

            return newForm;
        default:
            return newForm;
    }
};

const FormSecurityQuestions = () => {
    const api = useApi();

    const [securitySecurityQuestions, setSecuritySecurityQuestions] = useState({
        isActive: false,
        securityQuestionId: 0,
        answer: ''
    });
    const [errorSecuritySecurityQuestions, setErrorSecuritySecurityQuestions] = useState(false);
    const [isPendingSecuritySecurityQuestions, setIsPendingSecuritySecurityQuestions] = useState(true);

    const [securityQuestions, setSecurityQuestions] = useState([]);
    const [errorSecurityQuestions, setErrorSecurityQuestions] = useState(false);
    const [isPendingSecurityQuestions, setIsPendingSecurityQuestions] = useState(true);

    useEffect(() => {
        api.getSettingsSecuritySecurityQuestions().then(response => {
            setIsPendingSecuritySecurityQuestions(false);

            if (!response.ok) {
                setErrorSecuritySecurityQuestions(true);
                return;
            }

            if (
                response.data.hasOwnProperty('is_active')
                && response.data.hasOwnProperty('security_question_id')
                && response.data.hasOwnProperty('answer')
            ) {
                setSecuritySecurityQuestions({
                    isActive: response.data.is_active,
                    securityQuestionId: response.data.security_question_id,
                    answer: response.data.answer
                });
            }
        });

        api.getSecurityQuestions().then(response => {
            setIsPendingSecurityQuestions(false);

            if (!response.ok) {
                setErrorSecurityQuestions(true);
                return;
            }

            setSecurityQuestions(response.data);
        });
    }, []);

    useEffect(() => {
        if (securitySecurityQuestions) {
            dispatch(
                {
                    type: ACTIONS.set,
                    payload: {
                        securitySecurityQuestions: securitySecurityQuestions
                    }
                }
            );
        }
    }, [securitySecurityQuestions]);





    const [form, dispatch] = useReducer(reducer, {
        data: {
            isActive: false,
            securityQuestionId: 0,
            answer: ''
        },
        state: {
            isAnswerValid: true,

            isEditing: false,
            isSaving: false,
            api: {
                serverError: false,
                validationError: false
            }
        }
    });





    const handleEdit = () => {
        dispatch({type: ACTIONS.enableEditRegime});
    };
    const handleIsActiveChange = (isActive) => {
        dispatch(
            {
                type: ACTIONS.setIsActive,
                payload: { isActive: isActive }
            }
        );
    };
    const handleSecurityQuestionIdChange = (securityQuestionId) => {
        dispatch(
            {
                type: ACTIONS.setSecurityQuestionId,
                payload: { securityQuestionId: securityQuestionId }
            }
        );
    };
    const handleAnswerChange = (answer) => {
        dispatch(
            {
                type: ACTIONS.setAnswer,
                payload: { answer: answer }
            }
        );
    };
    const handleCancel = () => {
        dispatch(
            {
                type: ACTIONS.disableEditRegime,
                payload: { securitySecurityQuestions: securitySecurityQuestions }
            }
        );
    };
    const handleSave = (e) => {
        e.preventDefault();
        e.stopPropagation();

        dispatch({ type: ACTIONS.startSaving });
        if (
            (
                form.data.isActive !== securitySecurityQuestions.isActive
                || form.data.securityQuestionId !== securitySecurityQuestions.securityQuestionId
                || form.data.answer !== securitySecurityQuestions.answer
            )
            && form.state.isAnswerValid
        ) {
            api.updateSettingsSecuritySecurityQuestions(
                {
                    "is_active": form.data.isActive,
                    "security_question_id": 1 * form.data.securityQuestionId,
                    "answer": form.data.answer
                }
            ).then(response => {
                dispatch(
                    {
                        type: ACTIONS.terminateSaving,
                        payload: {
                            isChanged: true,
                            securitySecurityQuestions: securitySecurityQuestions,
                            isSaved: response.ok,
                            error: response.error
                        }
                    }
                );
            });
        } else {
            dispatch(
                {
                    type: ACTIONS.terminateSaving,
                    payload: {
                        isChanged: false,
                        securitySecurityQuestions: securitySecurityQuestions
                    }
                }
            );
        }
    };

    return (
        <form>
            <h2 className="title is-size-4">Security Question</h2>
            {
                errorSecuritySecurityQuestions
                && errorSecurityQuestions
                && <DataNotLoadedErrorMessage />
            }
            {
                isPendingSecuritySecurityQuestions
                && isPendingSecurityQuestions
                && <InProgressText/>
            }
            {
                !errorSecuritySecurityQuestions
                && !isPendingSecuritySecurityQuestions
                && securitySecurityQuestions
                && securityQuestions
                && (
                    <>
                        {
                            form.state.api.serverError
                            && <article className="message is-danger">
                                <div className="message-body">
                                    Error. Please wait a few minutes before you try again.
                                </div>
                            </article>
                        }
                        {
                            form.state.api.validationError
                            && <article className="message is-danger">
                                <div className="message-body">
                                    Please check the fields below and make sure all the required data are filled in.
                                </div>
                            </article>
                        }

                        <div className="field float-left mr-2">
                            <input
                                id="switchSecurityQuestion"
                                type="checkbox"
                                name="switchSecurityQuestion"
                                className="switch is-rounded is-info"
                                onChange={(e) => handleIsActiveChange(e.target.checked)}
                                checked={form.data.isActive || false}
                                disabled={!form.state.isEditing}
                            />
                            <label htmlFor="switchSecurityQuestion">Activate the security question</label>
                        </div>
                        {
                            (
                                (
                                    !form.state.isEditing
                                    && securitySecurityQuestions.isActive
                                )
                                ||
                                (
                                    form.state.isEditing
                                    &&
                                    form.data.isActive
                                )
                            )
                            && <>
                                <div className="field">
                                    <div className="control has-icons-left">
                                        <div className="select">
                                            <select
                                                onChange={(e) => handleSecurityQuestionIdChange(e.target.value)}
                                                value={form.data.securityQuestionId}
                                                disabled={!form.state.isEditing}
                                            >
                                                {
                                                    securityQuestions.map(question => (
                                                        <option
                                                            key={question.id}
                                                            value={question.id}
                                                        >{question.question}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <span className="icon is-small is-left">
                                            <FaQuestion />
                                        </span>
                                    </div>
                                </div>
                                <div className="field">
                                    <div className="control has-icons-left has-icons-right">
                                        <input
                                            className={`input ${
                                                securitySecurityQuestions.answer !== form.data.answer
                                                && !form.state.isAnswerValid
                                                && 'is-danger'
                                            }`}
                                            type="text"
                                            placeholder="Answer to the security question"
                                            value={form.data.answer || ''}
                                            onChange={(e) => handleAnswerChange(e.target.value)}
                                            disabled={!form.state.isEditing}
                                        />
                                        <span className="icon is-small is-left">
                                            <TbLetterA />
                                        </span>

                                        {
                                            securitySecurityQuestions.answer !== form.data.answer
                                            && (
                                                <>
                                                    {
                                                        form.state.isAnswerValid
                                                        && <span className="icon is-small is-right">
                                                            <FaCheck/>
                                                        </span>
                                                    }

                                                    {
                                                        !form.state.isAnswerValid
                                                        && <span className="icon is-small is-right">
                                                            <FaExclamationTriangle/>
                                                        </span>
                                                    }
                                                </>
                                            )
                                        }
                                    </div>
                                    {
                                        securitySecurityQuestions.answer !==form.data.answer
                                        && (
                                            <>
                                                {
                                                    !form.state.isAnswerValid
                                                    && <p className="help is-danger">This answer should not be empty and should not be longer than 250 characters.</p>
                                                }
                                            </>
                                        )
                                    }
                                </div>
                            </>
                        }

                        {
                            !form.state.isEditing
                            && <div className="field is-grouped is-flex is-justify-content-right">
                                <div className="control">
                                    <button
                                        onClick={handleEdit}
                                        className="button is-secondary is-rounded"
                                    >Edit</button>
                                </div>
                            </div>
                        }
                        {
                            form.state.isEditing
                            && <div className="field is-grouped is-flex is-justify-content-right">
                                <div className="control">
                                    <button
                                        onClick={handleCancel}
                                        className="button is-rounded"
                                    >Cancel</button>
                                </div>
                                <div className="control">
                                    <button
                                        onClick={handleSave}
                                        className="button is-primary is-rounded"
                                        disabled={!form.state.isAnswerValid}
                                    >
                                        Save
                                        {
                                            form.state.isSaving
                                            && <span className="cl-button-loader"></span>
                                        }
                                    </button>
                                </div>
                            </div>
                        }
                    </>
                )
            }
        </form>
    );
}

export default FormSecurityQuestions;