import { useEffect } from "react";

const Feedback = () => {
    const styles = {
		feedback_form: {
            width: "100%",
            height: "1000px"
		}
	}

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://embed.typeform.com/next/embed.js";
        script.async = true;
        document.body.appendChild(script);
      return () => {
          document.body.removeChild(script);
        }
      }, []);

    return (
        <>
            <div
                data-tf-widget="Tx4Dllfm"
                id="wrapper"
                data-tf-opacity="100"
                data-tf-inline-on-mobile
                data-tf-iframe-props="title=CoinLottery - Feedback"
                data-tf-transitive-search-params data-tf-auto-focus
                style={styles.feedback_form}
            ></div>
        </>
    );
}

export default Feedback;