import { Outlet, Navigate } from 'react-router-dom'

import { getJwtToken } from '../../../services';


const AuthorizedRoutes = () => {
    return (
        getJwtToken() ? <Outlet/> : <Navigate to="/login"/>
    )
}

export default AuthorizedRoutes;