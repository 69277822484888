import {
    FaKey,
    FaLock,
    FaCheck,
    FaExclamationTriangle
} from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import { useState, useEffect, useCallback, useReducer } from 'react';

import {
	isValidTwoFACode,
	isPasswordMinLength,
	passwordStrengthChecker
} from '../../services';
import { useApi } from '../../hooks';
import { ErrorBlock, SuccessBlock } from '../../components';


const ACTIONS = {
    setPassword: 'SET_PASSWORD',
    setPasswordConfirm: 'SET_PASSWORD_CONFIRM',
	setTwoFAEnabled: 'SET_TWO_FA_ENABLED',
	setTwoFACode: 'SET_TWO_FA_CODE',

    startSending: 'START_SENDING',
    terminateSending: 'TERMINATE_SENDING'
};

function reducer(form, action) {
    let newForm = {...form};
    const resetData = (newForm) => {
        newForm.data.password = '';
        newForm.data.passwordConfirm = '';
		newForm.data.twoFACode = '';
    };
    const resetState = (newForm) => {
        newForm.state.isPasswordValid = true;
        newForm.state.isPasswordConfirmValid = true;
		newForm.state.isPasswordAndPasswordConfirmMatch = true;
		newForm.state.isTwoFACodeValid = true;

        newForm.state.isSending = false;
        newForm.state.api.failed = false;
        newForm.state.api.success = false;
        newForm.state.api.errorMessage = '';
    };
    const validate = (newForm) => {
		newForm.state.isPasswordValid = isPasswordMinLength(
            newForm.data.password
        );
        newForm.state.isPasswordConfirmValid = isPasswordMinLength(
            newForm.data.passwordConfirm
        );

		newForm.state.isPasswordAndPasswordConfirmMatch = true;
		if (
			newForm.data.passwordConfirm !== '' &&
			newForm.state.isPasswordConfirmValid
		) {
			newForm.state.isPasswordAndPasswordConfirmMatch = (
				newForm.data.password === newForm.data.passwordConfirm
			);
		}

		newForm.state.passwordStrength = passwordStrengthChecker(
			newForm.data.password
		)
		newForm.state.isTwoFACodeValid =
            isValidTwoFACode(newForm.data.twoFACode);

        newForm.state.api.failed = false;
        newForm.state.api.success = false;
        newForm.state.api.errorMessage = '';
    };

    switch (action.type) {
        case ACTIONS.setPassword:
            newForm.data.password = action.payload.password;
            validate(newForm);

            return newForm;
        case ACTIONS.setPasswordConfirm:
            newForm.data.passwordConfirm = action.payload.passwordConfirm;
            validate(newForm);

            return newForm;
		case ACTIONS.setTwoFAEnabled:
			newForm.state.isTwoFAEnabled = action.payload;

			return newForm;
		case ACTIONS.setTwoFACode:
			newForm.data.twoFACode = action.payload.twoFACode;
			validate(newForm);

			return newForm;
        case ACTIONS.startSending:
            newForm.state.isSending = true;

            return newForm;
        case ACTIONS.terminateSending:
            newForm.state.isSending = false;

            if (action.payload.ok) {
				newForm.state.api.failed = false;
                newForm.state.api.success = true;
                resetData(newForm);
                // resetState(newForm);
            } else {
				newForm.data.twoFACode = '';
				newForm.state.isTwoFACodeValid = true;

                newForm.state.api.failed = true;
                newForm.state.api.success = false;
                newForm.state.api.errorMessage = action.payload.error.message;
            }

            return newForm;
        default:
            return newForm;
    }
};

const ResetPassword = () => {
	const api = useApi();
    const { key } = useParams();
    const [isPending, setIsPending] = useState(true);
    const [isVerified, setIsVerified] = useState(false);

    useEffect(() => {
        api.velidateResetPasswordKey({ 'key': key }).then(response => {
            setIsPending(false);
            setIsVerified(response.ok);

			if (response.ok) {
				dispatch(
					{
						type: ACTIONS.setTwoFAEnabled,
						payload: response.data.is_two_fa_enabled
					}
				);
			}
        });
    }, []);



    const [form, dispatch] = useReducer(reducer, {
        data: {
            password: '',
            passwordConfirm: '',
			twoFACode: ''
        },
        state: {
            isPasswordValid: true,
            isPasswordConfirmValid: true,

			isPasswordAndPasswordConfirmMatch: true,
			passwordStrength: '',

			isTwoFAEnabled: false,
			isTwoFACodeValid: true,

            isSending: false,
            api: {
                failed: false,
                success: false,
                errorMessage: ''
            }
        }
    });

    const handlePasswordChange = (data) => {
        dispatch(
            {
                type: ACTIONS.setPassword,
                payload: { password: data }
            }
        );
    };
    const handlePasswordConfirmChange = (data) => {
        dispatch(
            {
                type: ACTIONS.setPasswordConfirm,
                payload: { passwordConfirm: data }
            }
        );
    };
	const handleTwoFACodeChange = (data) => {
        dispatch(
            {
                type: ACTIONS.setTwoFACode,
                payload: { twoFACode: data }
            }
        );
    };
    const handleSend = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (
            !form.state.isSending

            && '' !== form.data.password
            && '' !== form.data.passwordConfirm
			&& (
				form.state.isTwoFAEnabled === false
				|| (
					form.state.isTwoFAEnabled
					&& '' !== form.data.twoFACode
				)
			)

            && form.state.isPasswordValid
            && form.state.isPasswordConfirmValid
			&& form.state.isPasswordAndPasswordConfirmMatch
			&& (
				form.state.isTwoFAEnabled === false
				|| (
					form.state.isTwoFAEnabled
					&& form.state.isTwoFACodeValid
				)
			)
        ) {
            dispatch({ type: ACTIONS.startSending });

			let payload = {
				'key': key,
				'password': form.data.password,
				'password_confirm': form.data.passwordConfirm
			};
			if (form.state.isTwoFAEnabled) {
				payload['twofa_code'] = 1 * form.data.twoFACode;
			}

            api.resetPassword(payload).then(response => {
                dispatch(
                    {
                        type: ACTIONS.terminateSending,
                        payload: response
                    }
                );
            });
        }
    };

	const handleEnter = useCallback((e) => {
		if (e.key === "Enter") {
			e.preventDefault();
        	e.stopPropagation();

			if (
				'' !== form.data.passwordConfirm
				&& '' !== form.data.password
				&& (
					form.state.isTwoFAEnabled === false
					|| (
						form.state.isTwoFAEnabled
						&& '' !== form.data.twoFACode
					)
				)

				&& form.state.isPasswordConfirmValid
				&& form.state.isPasswordValid
				&& (
					form.state.isTwoFAEnabled === false
					|| (
						form.state.isTwoFAEnabled
						&& form.state.isTwoFACodeValid
					)
				)
			) {
				handleSend(e);
			}
		}
	}, []);
	useEffect(() => {
		document.addEventListener("keydown", handleEnter, false);

		return () => {
		  	document.removeEventListener("keydown", handleEnter, false);
		};
	}, []);

  	return (
		<div className="container">
			<section className="section">
				<div className="columns">
					<div className="column is-3"></div>
					<div className="column">
						<div className="content">
							<h1 className="title">Reset Password</h1>
							{
								isPending
								&& <h2>The validation of the reset link is pending! Please wait.</h2>
							}

							{
                        		!isPending
								&& !isVerified
								&& <>
									<h2 className="subtitle is-4 has-text-primary">Incorrect or already used link for password reset</h2>
									<p>Please try again or <Link to="/help-and-contact-us" className="link-standard">contact our support</Link>.</p>
								</>
							}

							{
								!isPending
								&& isVerified
								&& <>
									{
										form.state.api.failed
										&& <ErrorBlock message={form.state.api.errorMessage}/>
									}
									{
										form.state.api.success
										&& <SuccessBlock message="Password has been reset successfully."/>
									}

									<div className="field">
										<div className="control has-icons-left has-icons-right">
											<input
												className={`input ${
													'' !== form.data.password
													&& !form.state.isPasswordValid
													&& 'is-danger'
												}`}
												type="password"
												placeholder="Password"
												value={form.data.password}
												onChange={
													(e) => handlePasswordChange(e.target.value)
												}
												autoFocus={true}
											/>
											<span className="icon is-small is-left">
												<FaLock />
											</span>
											{
												'' !== form.data.password
												&& (
													<>
														{
															form.state.isPasswordValid
															&& <span className="icon is-small is-right">
																<FaCheck/>
															</span>
														}

														{
															!form.state.isPasswordValid
															&& <span className="icon is-small is-right">
																<FaExclamationTriangle/>
															</span>
														}
													</>
												)
											}
										</div>
										{
											'' !== form.data.password
											&& !form.state.isPasswordValid
											&& <p className="help is-danger">Must be 8 characters or longer - at least one small and one capital letter, one digit and one symbol (i.e. #, $, @).</p>
										}
									</div>
									{
										'' !== form.data.password
										&& form.state.isPasswordValid
										&& <>
											{
												form.state.passwordStrength === 'WEAK'
												&& <p className="help has-text-grey-light">Strength: Weak</p>
											}
											{
												form.state.passwordStrength === 'MEDIUM'
												&& <p className="help has-text-info">Strength: Medium</p>
											}
											{
												form.state.passwordStrength === 'STRONG'
												&& <p className="help has-text-success">Strength: Strong</p>
											}
										</>
									}

									<div className="field">
										<div className="control has-icons-left has-icons-right">
											<input
												className={`input ${
													'' !== form.data.passwordConfirm
													&& (
														!form.state.isPasswordConfirmValid
														|| !form.state.isPasswordAndPasswordConfirmMatch
													)
													&& 'is-danger'
												}`}
												type="password"
												placeholder="Confirm Password"
												value={form.data.passwordConfirm}
												onChange={
													(e) => handlePasswordConfirmChange(e.target.value)
												}
											/>
											<span className="icon is-small is-left">
												<FaLock />
											</span>
											{
												'' !== form.data.passwordConfirm
												&& (
													<>
														{
															form.state.isPasswordConfirmValid
															&& form.state.isPasswordAndPasswordConfirmMatch
															&& <span className="icon is-small is-right">
																<FaCheck/>
															</span>
														}

														{
															(
																!form.state.isPasswordConfirmValid
																|| !form.state.isPasswordAndPasswordConfirmMatch
															)
															&& <span className="icon is-small is-right">
																<FaExclamationTriangle/>
															</span>
														}
													</>
												)
											}
										</div>
										{
											'' !== form.data.passwordConfirm
											&& !form.state.isPasswordConfirmValid
											&& <p
												className="help is-danger"
											>Invalid password confirm</p>
										}
										{
											'' !==form.data.passwordConfirm
											&& !form.state.isPasswordAndPasswordConfirmMatch
											&& <p
												className="help is-danger"
											>Password and confirm password should match</p>
										}
									</div>

									{
										form.state.isTwoFAEnabled
										&& <>
											<div className="field mb-0">
												<div className="control has-icons-left has-icons-right">
													<input
														className={`input ${
															'' !== form.data.twoFACode
															&& !form.state.isTwoFACodeValid
															&& 'is-danger'
														}`}
														maxLength="6"
														type="numeric"
														placeholder="Authenticator Code"
														value={form.data.twoFACode}
														onChange={
															(e) => handleTwoFACodeChange(e.target.value)
														}
													/>
													<span className="icon is-small is-left">
														<FaKey />
													</span>
													{
														'' !== form.data.twoFACode
														&& (
															<>
																{
																	form.state.isTwoFACodeValid
																	&& <span className="icon is-small is-right">
																		<FaCheck/>
																	</span>
																}

																{
																	!form.state.isTwoFACodeValid
																	&& <span className="icon is-small is-right">
																		<FaExclamationTriangle/>
																	</span>
																}
															</>
														)
													}
												</div>
											</div>
											<p className="help">Enter the 6-digit code from Binance/Google Authenticator</p>
										</>
									}

									<div className="field">
										<p className="control is-pulled-right">
											<button
												className="button is-primary is-rounded"
												onClick={handleSend}
												disabled={
													'' === form.data.passwordConfirm
													|| '' === form.data.password
													|| (
														form.state.isTwoFAEnabled
														&& '' === form.data.twoFACode
													)

													|| !form.state.isPasswordConfirmValid
													|| !form.state.isPasswordValid
													|| (
														form.state.isTwoFAEnabled
														&& !form.state.isTwoFACodeValid
													)
												}
											>
												Reset Password
												{
													form.state.isSending
													&& <span className="cl-button-loader"></span>
												}
											</button>
										</p>
									</div>
									<br />
									<br />
								</>
							}
						</div>

						{
							!isPending
							&& isVerified
							&& <div className="content">
								<div className="content is-flex is-justify-content-center">
									<p>
										Security verification unavailable?&nbsp;
										<Link
											to="/help-and-contact-us"
											className="link-standard"
										>Contact our support</Link>.
									</p>
								</div>
							</div>
						}
					</div>
					<div className="column is-3"></div>
				</div>
			</section>
			<div className="block my-6"></div>
		</div>
  	)
}

export default ResetPassword