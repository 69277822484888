import { useState } from 'react';
import { FaGoogle } from 'react-icons/fa';
import { LoginSocialGoogle } from 'reactjs-social-login';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useApi } from '../../../hooks';
import { authTranslations } from '../../../services';


const LoginSocialGoogleButton = ({setSociaLoginError}) => {
    const api = useApi();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [loginInProgress, setLoginInProgress] = useState(false);

    const onLoginStart = () => {
        setLoginInProgress(true);
    };

    const onSuccess = (response) => {
        setSociaLoginError(false);

        api.loginViaGoogle(
            {
                'access_token': response['access_token']
            }
        ).then(response => {
            setLoginInProgress(false);

            if (!response.ok) {
                setSociaLoginError(authTranslations['google_login_failed']);
            }

            const path = searchParams.get('path');

            if (
                Object.hasOwn(response.data, 'access_token')
                && Object.hasOwn(response.data, 'refresh_token')
            ) {
                if (null !== path) {
                    navigate(path);
                    return;
                }

                navigate('/');
                return;
            }

            navigate('/login-secure/' + response.data.key + (
                null !== path ? '?path=' + path : ''
            ));
        });
    }

    const onFailure = (error) => {
        setSociaLoginError(authTranslations['google_login_failed']);
    }

    return (
        <>
            <LoginSocialGoogle
                client_id={process.env.REACT_APP_GOOGLE_APP_ID}
                isOnlyGetToken={false}

                onLoginStart={onLoginStart}
                onResolve={({ provider, data }) => { onSuccess(data) }}
                onReject={onFailure}

                scope='https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid'

                className="button is-danger m-2"
            >
                <span className="icon">
                    <FaGoogle />
                </span>
                <span>Google</span>
                {
                    loginInProgress
                    && <span className="cl-button-loader"></span>
                }
            </LoginSocialGoogle>
        </>
    );
}

export default LoginSocialGoogleButton;