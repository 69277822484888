import { Link } from 'react-router-dom';
import { useState, useEffect } from "react";

import {
    FiatFormatter,
    InProgressText,
	CryptoFormatter,
    DataNotLoadedErrorMessage,
} from '../../../components';
import { useApi } from '../../../hooks';
import { useUserContext } from '../../../context';


const ListOfWins = ({ gameKey }) => {
    const userContext = useUserContext();

    const api = useApi();
    const [data, setData] = useState([]);
    const [error, setError] = useState(false);
    const [isPending, setIsPending] = useState(true);

    useEffect(() => {
        setData([]);
        setError(false);
        setIsPending(true);

        api.getGameWins(gameKey).then(response => {
            setIsPending(false);

            if (!response.ok) {
                setError(true);
                return;
            }

            setData(response.data);
        });
    }, [gameKey]);

    return (
        <>
            {error && <DataNotLoadedErrorMessage />}
			{isPending && <InProgressText/>}
            {
                data
                && !error
                && !isPending
                && <table className="table">
                    <tbody>
                        {
                            data.length === 0
                            ? (
                                <tr>
                                    <td colSpan="4">No winning matches so far.</td>
                                </tr>
                            )
                            : data.map((win) => (
                                <tr key={win.id}>
                                    <td>{win.date}</td>
                                    <td>{win.win_type === 'JACKPOT' ? 'Jackpot' : 'Prize'}</td>
                                    <td>
                                        <CryptoFormatter
                                            type="DETAIL"
                                            data={win.amount_won.crypto}
                                        />&nbsp;
                                        (<FiatFormatter
                                            type="DETAIL"
                                            data={win.amount_won.fiat[userContext.currency_code]}
                                        />)
                                    </td>
                                    <td>
                                        <Link
                                            to={`/game/${gameKey}/results/${win.play_page}/${win.play_id}`}
                                            className="link-standard"
                                        >More details</Link>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            }
        </>
    );
}

export default ListOfWins;