import Parser from 'html-react-parser';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { FaAngleUp, FaAngleDown } from 'react-icons/fa';

import {
    Pagination,
    InProgressText,
	DataNotLoadedErrorMessage
} from '../../../../components';
import { useApi } from '../../../../hooks';


const ListOfMessages = ({ currentPage = 1 }) => {
    const api = useApi();

    const [data, setData] = useState([]);
    const [error, setError] = useState(false);
    const [results, setResults] = useState(false);
    const [isPending, setIsPending] = useState(true);
    const [totalPages, setTotalPages] = useState(false);
    useEffect(() => {
        api.getSettingsMessages(currentPage).then(response => {
            setIsPending(false);

            if (!response.ok) {
                setError(true);
                return;
            }

            setResults(response.data.results);
            setTotalPages(response.data.total_pages);
        });
    }, [currentPage]);

    useEffect(() => {
        if (data) {
            setResults(data.results);
            setTotalPages(data.totalPages);
        }
    }, [data]);





    const handleToggle = (index) => {
        let newResults = [...results];
		newResults[index].is_open = !newResults[index].is_open
		setData({
            page: currentPage,
            totalPages: totalPages,
            results: newResults,
        });
	}

    const handleMarkMessageAsReadOrUnread = (index) => {
		let newResults = [...results];
		newResults[index].is_read = !newResults[index].is_read;
		setData({
            page: currentPage,
            totalPages: totalPages,
            results: newResults,
        });

        api.updateSettingsMessages(
            newResults[index].id,
            { "is_read": newResults[index].is_read }
        );
	}

    return (
        <>
            {error && <DataNotLoadedErrorMessage />}
			{isPending && <InProgressText/>}
            {
                results
                ? (
                    <>
                        {
                            results.length > 0
                            ? results.map((message, index) => (
                                <div className="card mb-3" key={`message-${message.id}`}>
                                    <header
                                        className="card-header"
                                        onClick={() => handleToggle(index)}
                                    >
                                        <p className={message.is_read ? 'card-header-title has-text-weight-normal' : 'card-header-title has-text-weight-bold'}>
                                            {Parser(message.title)}
                                        </p>
                                        <button className="card-header-icon" aria-label="more options">
                                            <span className="icon">
                                                {
                                                    message.is_open
                                                    ? <FaAngleUp />
                                                    : <FaAngleDown />
                                                }
                                            </span>
                                        </button>
                                    </header>
                                    <div className="card-content" style={{display: message.is_open ? 'block' : 'none' }}>
                                        <div className="content">{Parser(message.content)}</div>
                                    </div>
                                    <footer className="card-footer" style={{display: message.is_open ? 'block' : 'none' }}>
                                        <Link
                                            to="#"
                                            className="card-footer-item link-standard"
                                            onClick={() => handleMarkMessageAsReadOrUnread(index)}
                                        >
                                            {
                                                message.is_read
                                                ? 'Mark as Unread'
                                                : 'Mark as Read'
                                            }
                                        </Link>
                                    </footer>
                                </div>
                            ))
                            : (
                                <p className="has-text-grey ml-3 mb-3">No messages.</p>
                            )
                        }

                        <Pagination
                            url="/settings/general/messages"
                            page={currentPage}
                            totalPages={totalPages}
                            numberOfResults={results.length}
                        />
                    </>
                )
                : <>
                    {
                        !isPending
                        && <p className="has-text-grey ml-3 mb-3">No messages.</p>
                    }
                </>
            }
        </>
    );
}

export default ListOfMessages;