import { useEffect, useState } from 'react';

import {
    ErrorBlock,
    InProgressText,
    DataNotLoadedErrorMessage
} from '../../../../components';
import { useApi } from '../../../../hooks';


const ListOfActiveSessions = () => {
    const api = useApi();

    const [
        errorRemovingActiveSession,
        setErrorRemovingActiveSession
    ] = useState(false);
    const [error, setError] = useState(false);
    const [results, setResults] = useState(false);
    const [isPending, setIsPending] = useState(true);
    useEffect(() => {
        api.getActiveSessions().then(response => {
            setIsPending(false);

            if (!response.ok) {
                setError(true);
                return;
            }

            setResults(response.data);
        });
    }, []);

    const removeToken = (index) => {
        setResults([
          ...results.slice(0, index),
          ...results.slice(index + 1, results.length)
        ]);
    }

    const handleRevokeToken = (e, accessToken, index) => {
        e.preventDefault();
        e.stopPropagation();

        api.revokeTokens({'tokens': [accessToken]}).then(response => {
            if (response.ok) {
                removeToken(index);
                api.refresh();
                setErrorRemovingActiveSession(false);
                return;
            }

            setErrorRemovingActiveSession(true);
        });
    };

    const styles = {
		loader: {
			display: "none"
		}
	}

    return (
        <>
            {error && <DataNotLoadedErrorMessage />}
            {isPending && <InProgressText/>}
            {
                results
                && (
                    <>
                        {
                            errorRemovingActiveSession
                            && <ErrorBlock
                                message="Unable to log out the session."
                            />
                        }
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Authorized</th>
                                    <th>Device</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    results.length > 0
                                    ? results.map((activeSession, index) => (
                                        <tr key={index}>
                                            <td>{activeSession.created_at}</td>
                                            <td>{activeSession.fingerprint} {
                                                activeSession.is_current_session &&
                                                <span><br />Current session</span>
                                            }</td>
                                            <td>
                                                <button
                                                    className="button is-danger is-small"
                                                    onClick={
                                                        (e) => {
                                                            e.target.querySelectorAll('span')[0].style.display = 'inline';
                                                            handleRevokeToken(
                                                                e,
                                                                activeSession.access_token,
                                                                index
                                                            );
                                                        }
                                                    }
                                                >
                                                    Log out
                                                    <span
                                                        className="cl-button-loader"
                                                        style={styles.loader}
                                                    ></span>
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                    : (
                                        <tr className="noHover">
                                            <td colSpan="4">There are no recent records.</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </>
                )
            }
        </>
    );
}

export default ListOfActiveSessions;