import { Link, useLocation } from 'react-router-dom';


const MenuResultsAndDrawsMobile = ({ gameKey }) => {
    const location = useLocation();

    return (
        <div className="tabs is-small" id="nav-mobile-level-two">
            <ul>
                <li className={`${ location.pathname.includes('/results') ? 'is-active' : '' }`}>
                    <Link to={`/game/${gameKey}/results`}>Check my numbers</Link>
                </li>
                <li className={`${ location.pathname.includes('/draws') ? 'is-active' : '' }`}>
                    <Link to={`/game/${gameKey}/draws`}>Draw history</Link>
                </li>
            </ul>
        </div>
    );
}

export default MenuResultsAndDrawsMobile;