const Cookies = () => {
  	return (
    	<div className="container">
			<section className="section">
				<h1 className="title">Cookies</h1>
				<p>CoinLottery, a web application offering a crypto lotto game that uses Bitcoin for accepting payments, is committed to protecting the privacy of its users. As part of this commitment, we would like to explain how we use cookies on our website, coinlottery.bet.</p>


				<h2 className="subtitle">What are cookies</h2>
				<p>Cookies are small text files that are stored on your device when you visit a website. They are used to help websites work more efficiently, as well as to provide information to the owners of the website.</p>


				<h2 className="subtitle">Cookies used by CoinLottery</h2>
				<p>We use cookies on our website to enhance user experience and improve the functionality of our site. These cookies include those used for authorization of logged-in users, allowing them to access their account information and preferences. We also use cookies to collect information on how users interact with our website, such as which pages they visit and for how long, to help us understand user behavior and make data-driven improvements. Additionally, we use third-party services such as Google Analytics, Facebook Pixel, and HotJar to gather information about our users and their website usage.</p>

				<h2 className="subtitle">Third-party cookies</h2>
				<p>In addition to our own cookies, we also use a number of third-party services to collect data from our users, including Google Analytics, Facebook Pixel, and HotJar. These services use cookies to collect information about how users interact with our website, such as which pages they visit and how long they spend on the site.</p>

				<h2 className="subtitle">Managing cookies</h2>
				<p>Most browsers allow you to manage cookies. You can choose to accept all cookies, to be notified when a cookie is set, or to reject all cookies. You can find more information about managing cookies in your browser’s help files.</p>

				<h2 className="subtitle">Contact Us</h2>
				<p>If you have any questions about our cookie policy, or if you would like to request information about the data we hold about you, please contact us by email at <a href="mailto:support@coinlottery.bet">support@coinlottery.bet</a>.</p>

				<h2 className="subtitle">Changes</h2>
				<p>This Cookie Policy was last updated 3th February 2023.</p>
			</section>
			<div className="block my-6"></div>
		</div>
  	)
}

export default Cookies