import {
    FaClock,
    FaCheck,
    FaUserAlt,
    FaEnvelope,
    FaRegMoneyBillAlt,
    FaExclamationTriangle
} from 'react-icons/fa';
import { useReducer, useEffect, useState } from "react";

import {
    isValidUsername,
    isValidEmail,
    isNotEmptyString
} from '../../../../services';
import {
    InProgressText,
    DataNotLoadedErrorMessage
} from '../../../../components';
import { useApi } from '../../../../hooks';


const ACTIONS = {
    set: 'SET',

    enableEditRegime: 'ENABLE_SWITCH_REGIME',
    disableEditRegime: 'DISABLE_SWITCH_REGIME',

    setBuffers: 'SET_BUFFERS',
    setIsUsernameAvailable: 'SET_IS_USERNAME_AVAILABLE',
    setIsEmailAvailable: 'SET_IS_EMAIL_AVAILABLE',

    setUsername: 'SET_USERNAME',
    setEmail: 'SET_EMAIL',
    setTimezoneId: 'SET_TIMEZONE_ID',
    setCurrency: 'SET_CURRENCY',

    startSaving: 'START_SAVING',
    terminateSaving: 'TERMINATE_SAVING'
};

function reducer(form, action) {
    let newForm = {...form};
    const resetFields = (newForm, personalDetails) => {
        newForm.data.username = personalDetails.username;
        newForm.data.email = personalDetails.email;
        newForm.data.timezone_id = personalDetails.timezone_id;
        newForm.data.currency_id = personalDetails.currency_id;
    };
    const resetStates = (newForm, personalDetails) => {
        newForm.state.api.serverError = false;
        newForm.state.api.validationError = false;

        newForm.state.usernameBuffered = personalDetails.username;
        newForm.state.isUsernameValid = true;
        newForm.state.isUsernameAvailable = null;

        newForm.state.emailBuffered = personalDetails.email;
        newForm.state.isEmailValid = true;
        newForm.state.isEmailAvailable = null;

        newForm.state.isSaving = false;
        newForm.state.isEditing = false;
    };
    const setFields = (newForm, action) => {
        action.payload.personalDetails.username = newForm.data.username;
        action.payload.personalDetails.email = newForm.data.email;
        action.payload.personalDetails.timezone_id = newForm.data.timezone_id;
        action.payload.personalDetails.currency_id = newForm.data.currency_id;
    };
    const validate = (newForm) => {
        newForm.state.api.serverError = false;
        newForm.state.api.validationError = false;

        newForm.state.isUsernameValid = isValidUsername(newForm.data.username);
        if (!newForm.state.isUsernameValid) newForm.state.isUsernameAvailable = null;

        newForm.state.isEmailValid = isValidEmail(newForm.data.email);
        if (!newForm.state.isEmailValid) newForm.state.isEmailAvailable = null;
    };

    switch (action.type) {
        case ACTIONS.set:
            return {
                data: {
                    username: action.payload.personalDetails.username,
                    email: action.payload.personalDetails.email,
                    timezone_id: action.payload.personalDetails.timezone_id,
                    currency_id: action.payload.personalDetails.currency_id
                },
                state: {
                    usernameBuffer: action.payload.personalDetails.username,
                    isUsernameValid: true,
                    isUsernameAvailable: null,

                    emailBuffer: action.payload.personalDetails.email,
                    isEmailValid: true,
                    isEmailAvailable: null,

                    isEditing: false,
                    isSaving: false,
                    api: {
                        serverError: false,
                        validationError: false
                    }
                }
             }
        case ACTIONS.enableEditRegime:
            newForm.state.isEditing = true;

            return newForm;
        case ACTIONS.disableEditRegime:
            resetFields(newForm, action.payload.personalDetails);
            resetStates(newForm, action.payload.personalDetails);

            return newForm;
        case ACTIONS.setBuffers:
            newForm.state.usernameBuffer = newForm.data.username;
            newForm.state.emailBuffer = newForm.data.email;

            return newForm;
        case ACTIONS.setIsUsernameAvailable:
            newForm.state.isUsernameAvailable = action.payload.isUsernameAvailable;

            return newForm;
        case ACTIONS.setIsEmailAvailable:
            newForm.state.isEmailAvailable = action.payload.isEmailAvailable;

            return newForm;
        case ACTIONS.setUsername:
            newForm.data.username = action.payload.username;
            validate(newForm);

            return newForm;
        case ACTIONS.setEmail:
            newForm.data.email = action.payload.email;
            validate(newForm);

            return newForm;
        case ACTIONS.setTimezoneId:
            newForm.data.timezone_id = action.payload.timezone_id;
            validate(newForm);

            return newForm;
        case ACTIONS.setCurrency:
            newForm.data.currency_id = action.payload.currency_id;
            validate(newForm);

            return newForm;
        case ACTIONS.startSaving:
            newForm.state.isSaving = true;

            return newForm;
        case ACTIONS.terminateSaving:
            if (action.payload.isChanged) {
                if (action.payload.isSaved) {
                    setFields(newForm, action);
                    resetStates(newForm, action.payload.personalDetails);
                } else {
                    newForm.state.isSaving = false;

                    switch (action.payload.error) {
                        case 'SERVER_ERROR':
                            newForm.state.api.serverError = true;
                            newForm.state.api.validationError = false;
                            break;
                        default:
                            validate(newForm);
                            newForm.state.api.serverError = false;
                            newForm.state.api.validationError = true;
                            break;
                    }
                }
            } else {
                resetFields(newForm, action.payload.personalDetails);
                resetStates(newForm, action.payload.personalDetails);
            }

            return newForm;
        default:
            return newForm;
    }
};

const FormPersonalDetails = () => {
    const api = useApi();

    const [personalDetails, setPersonalDetails] = useState({
        username: '',
        email: '',
        timezone_id: 202,
        currency_id: 1
    });
    const [errorPersonalDetails, setErrorPersonalDetails] = useState(false);
    const [isPendingPersonalDetails, setIsPendingPersonalDetails] = useState(true);

    const [timezones, setTimezones] = useState([]);
    const [errorTimezones, setErrorTimezones] = useState(false);
    const [isPendingTimezones, setIsPendingTimezones] = useState(true);

    const [currencies, setCurrencies] = useState([]);
    const [errorCurrencies, setErrorCurrencies] = useState(false);
    const [isPendingCurrencies, setIsPendingCurrencies] = useState(true);

    useEffect(() => {
        api.getSettingsPersonalDetails().then(response => {
            setIsPendingPersonalDetails(false);

            if (!response.ok) {
                setErrorPersonalDetails(true);
                return;
            }

            if (
                response.data.hasOwnProperty('username')
                && response.data.hasOwnProperty('email')
                && response.data.hasOwnProperty('timezone_id')
                && response.data.hasOwnProperty('currency_id')
            ) {
                setPersonalDetails(
                    {
                        username: response.data.username,
                        email: response.data.email,
                        timezone_id: response.data.timezone_id,
                        currency_id: response.data.currency_id
                    }
                );
            }
        });

        api.getTimezones().then(response => {
            setIsPendingTimezones(false);

            if (!response.ok) {
                setErrorTimezones(true);
                return;
            }

            setTimezones(response.data);
        });

        api.getCurrencies().then(response => {
            setIsPendingCurrencies(false);

            if (!response.ok) {
                setErrorCurrencies(true);
                return;
            }

            setCurrencies(response.data);
        });
    }, []);

    useEffect(() => {
        if (personalDetails) {
            dispatch(
                {
                    type: ACTIONS.set,
                    payload: { personalDetails: personalDetails }
                }
            );
        }
    }, [personalDetails]);





    const requestDelay = 400;
    const [form, dispatch] = useReducer(reducer, {
        data: {
            username: '',
            email: '',
            timezone_id: 0,
            currency_id: 0
        },
        state: {
            usernameBuffer: '',
            isUsernameValid: true,
            isUsernameAvailable: null,

            emailBuffer: '',
            isEmailValid: true,
            isEmailAvailable: null,

            isEditing: false,
            isSaving: false,
            api: {
                serverError: false,
                validationError: false
            }
        }
    });

    const [usernameAvailabilityData, setUsernameAvailabilityData] = useState(false);
    useEffect(() => {
        if (
            form.state.usernameBuffer !== '' &&
            form.state.usernameBuffer !== personalDetails.username
        ) {
            api.getSettingsPersonalDetailsUsernameAvailability(form.state.usernameBuffer).then(response => {
                if (response.ok) setUsernameAvailabilityData(response.data);
            });
        }
    }, [form.state.usernameBuffer, personalDetails.username]);

    const [emailAvailabilityData, setEmailAvailabilityData] = useState(false);
    useEffect(() => {
        if (
            form.state.emailBuffer !== '' &&
            form.state.emailBuffer !== personalDetails.email
        ) {
            api.getSettingsPersonalDetailsEmailAvailability(form.state.emailBuffer).then(response => {
                if (response.ok) setEmailAvailabilityData(response.data);
            });
        }
    }, [form.state.emailBuffer, personalDetails.email]);

    useEffect(() => {
        if (
            form.state.isUsernameValid
            && form.data.username !== personalDetails.username
        ) {
            dispatch(
                {
                    type: ACTIONS.setIsUsernameAvailable,
                    payload: {
                        'isUsernameAvailable': usernameAvailabilityData[0]
                    }
                }
            );
        }
    }, [
        usernameAvailabilityData,
        form.state.isUsernameValid,
        form.data.username,
        personalDetails
    ]);

    useEffect(() => {
        if (
            form.state.isEmailValid
            && form.data.email !== personalDetails.email
        ) {
            dispatch(
                {
                    type: ACTIONS.setIsEmailAvailable,
                    payload: {
                        'isEmailAvailable': emailAvailabilityData[0]
                    }
                }
            );
        }
    }, [
        emailAvailabilityData,
        form.state.isEmailValid,
        form.data.email,
        personalDetails
    ]);

    useEffect(() => {
        const interval = setInterval(() => {
            dispatch({type: ACTIONS.setBuffers});
        }, requestDelay);

        return () => clearInterval(interval);
      }, [form.data.username, form.data.email]);





    const handleEdit = () => {
        dispatch({type: ACTIONS.enableEditRegime});
    };
    const handleUsernameChange = (e) => {
        dispatch(
            {
                type: ACTIONS.setUsername,
                payload: { username: e.target.value }
            }
        );
    };
    const handleEmailChange = (e) => {
        dispatch(
            {
                type: ACTIONS.setEmail,
                payload: { email: e.target.value }
            }
        );
    };
    const handleTimezoneIdChange = (timezone_id) => {
        dispatch(
            {
                type: ACTIONS.setTimezoneId,
                payload: { timezone_id: timezone_id }
            }
        );
    };
    const handleCurrencyChange = (currency_id) => {
        dispatch(
            {
                type: ACTIONS.setCurrency,
                payload: { currency_id: currency_id }
            }
        );
    };
    const handleCancel = () => {
        dispatch(
            {
                type: ACTIONS.disableEditRegime,
                payload: { personalDetails: personalDetails }
            }
        );
    };
    const handleSave = (e) => {
        e.preventDefault();
        e.stopPropagation();

        dispatch({ type: ACTIONS.startSaving });

        if (
            (
                form.data.username !== personalDetails.username
                || form.data.email !== personalDetails.email
                || form.data.timezone_id !== personalDetails.timezone_id
                || form.data.currency_id !== personalDetails.currency_id
            )
            && form.state.isUsernameValid
            && form.state.isUsernameAvailable !== false
            && form.state.isEmailValid
            && form.state.isEmailAvailable !== false
        ) {
            api.updateSettingsPersonalDetails(
                {
                    "username": form.data.username,
                    "email": form.data.email,
                    "timezone_id": 1 * form.data.timezone_id,
                    "currency_id": 1 * form.data.currency_id
                }
            ).then(response => {
                dispatch(
                    {
                        type: ACTIONS.terminateSaving,
                        payload: {
                            isChanged: true,
                            personalDetails: personalDetails,
                            isSaved: response.ok,
                            error: response.error
                        }
                    }
                );
            });
        } else {
            dispatch(
                {
                    type: ACTIONS.terminateSaving,
                    payload: {
                        isChanged: false,
                        personalDetails: personalDetails
                    }
                }
            );
        }
    };

    return (
        <>
            {
                errorPersonalDetails
                && errorTimezones
                && errorCurrencies
                && <DataNotLoadedErrorMessage />
            }
            {
                isPendingPersonalDetails
                && isPendingTimezones
                && isPendingCurrencies
                && <InProgressText/>
            }
            {
                !errorPersonalDetails
                && !isPendingPersonalDetails
                && personalDetails
                && timezones
                && currencies
                && <form>
                    {
                        form.state.api.serverError
                        && <article className="message is-danger">
                            <div className="message-body">
                                Error. Please wait a few minutes before you try again.
                            </div>
                        </article>
                    }
                    {
                        form.state.api.validationError
                        && <article className="message is-danger">
                            <div className="message-body">
                                Please check the fields below and make sure all the required data are filled in.
                            </div>
                        </article>
                    }

                    <div className="field">
                        <label className="label">Username</label>
                        <div className="control has-icons-left has-icons-right">
                            <input
                                className={`input ${
                                    personalDetails.username !== form.data.username
                                    && (
                                        !form.state.isUsernameValid
                                        || form.state.isUsernameAvailable === false
                                    )
                                    && 'is-danger'
                                }`}
                                type="text"
                                placeholder=""
                                value={form.data.username || ''}
                                onChange={(e) => handleUsernameChange(e)}
                                disabled={!form.state.isEditing}
                            />
                            <span className="icon is-small is-left">
                                <FaUserAlt/>
                            </span>

                            {
                                personalDetails.username !== form.data.username
                                && (
                                    <>
                                        {
                                            form.state.isUsernameValid
                                            && form.state.isUsernameAvailable !== false
                                            && <span className="icon is-small is-right">
                                                <FaCheck/>
                                            </span>
                                        }

                                        {
                                            (
                                                !form.state.isUsernameValid
                                                || form.state.isUsernameAvailable === false
                                            )
                                            && <span className="icon is-small is-right">
                                                <FaExclamationTriangle/>
                                            </span>
                                        }
                                    </>
                                )
                            }
                        </div>

                        {
                            personalDetails.username !== form.data.username
                            && (
                                <>
                                    {
                                        !form.state.isUsernameValid
                                        && <p className="help is-danger">This username is invalid. It should contain numbers, letters, spaces and the following characters: -, ', _.</p>
                                    }
                                    {
                                        form.state.isUsernameAvailable === true
                                        && <p className="help is-success">This username is available.</p>
                                    }

                                    {
                                        form.state.isUsernameAvailable === false
                                        && <p className="help is-danger">This username is not available.</p>
                                    }
                                </>
                            )
                        }
                    </div>
                    <div className="field">
                        <label className="label">Email</label>
                        <div className="control has-icons-left has-icons-right">
                            <input
                                className={`input ${
                                    personalDetails.email !== form.data.email
                                    && (
                                        !form.state.isEmailValid
                                        || form.state.isEmailAvailable === false
                                    )
                                    && 'is-danger'
                                }`}
                                type="email"
                                placeholder=""
                                value={form.data.email || ''}
                                onChange={(e) => handleEmailChange(e)}
                                disabled={!form.state.isEditing}
                            />
                            <span className="icon is-small is-left">
                                <FaEnvelope/>
                            </span>

                            {
                                personalDetails.email !== form.data.email
                                && (
                                    <>
                                        {
                                            form.state.isEmailValid
                                            && form.state.isEmailAvailable !== false
                                            && <span className="icon is-small is-right">
                                                <FaCheck/>
                                            </span>
                                        }

                                        {
                                            (
                                                !form.state.isEmailValid
                                                || form.state.isEmailAvailable === false
                                            )
                                            && <span className="icon is-small is-right">
                                                <FaExclamationTriangle/>
                                            </span>
                                        }
                                    </>
                                )
                            }
                        </div>

                        {
                            personalDetails.email !==form.data.email
                            && (
                                <>
                                    {
                                        !form.state.isEmailValid
                                        && <p className="help is-danger">This email is invalid.</p>
                                    }

                                    {
                                        form.state.isEmailAvailable === true
                                        && <p className="help is-success">This email is available.</p>
                                    }

                                    {
                                        form.state.isEmailAvailable === false
                                        && <p className="help is-danger">This email is not available.</p>
                                    }
                                </>
                            )
                        }
                    </div>
                    <div className="field">
                        <label className="label">Timezone</label>
                        <p className="control has-icons-left">
                            <span className="select">
                                <select
                                    disabled={!form.state.isEditing}
                                    onChange={(e) => handleTimezoneIdChange(e.target.value)}
                                    value={form.data.timezone_id}
                                >
                                    {
                                        timezones.map((timezone) => (
                                            <option
                                                key={timezone.id}
                                                value={timezone.id}
                                            >
                                                {timezone.label}
                                            </option>
                                        ))
                                    }
                                </select>
                            </span>
                            <span className="icon is-small is-left">
                                <FaClock />
                            </span>
                        </p>
                    </div>
                    <div className="field">
                        <label className="label">Currency</label>
                        <p className="control has-icons-left">
                            <span className="select">
                                <select
                                    disabled={!form.state.isEditing}
                                    onChange={(e) => handleCurrencyChange(e.target.value)}
                                    value={form.data.currency_id}
                                >
                                    {
                                        currencies.map((currency) => (
                                            <option
                                                key={currency.id}
                                                value={currency.id}
                                            >
                                                {currency.code}
                                            </option>
                                        ))
                                    }
                                </select>
                            </span>
                            <span className="icon is-small is-left">
                                <FaRegMoneyBillAlt/>
                            </span>
                        </p>
                    </div>

                    {
                        !form.state.isEditing
                        && <div className="field is-grouped is-flex is-justify-content-right">
                            <div className="control">
                                <button
                                    onClick={handleEdit}
                                    className="button is-secondary is-rounded"
                                >Edit</button>
                            </div>
                        </div>
                    }
                    {
                        form.state.isEditing
                        && <div className="field is-grouped is-flex is-justify-content-right">
                            <div className="control">
                                <button
                                    onClick={handleCancel}
                                    className="button is-rounded"
                                >Cancel</button>
                            </div>
                            <div className="control">
                                <button
                                    onClick={handleSave}
                                    className="button is-primary is-rounded"
                                    disabled={
                                        !isNotEmptyString(form.data.username)
                                        || !isNotEmptyString(form.data.email)
                                        || !form.state.isUsernameValid
                                        || form.state.isUsernameAvailable === false
                                        || !form.state.isEmailValid
                                        || form.state.isEmailAvailable === false
                                    }
                                >
                                    Save
                                    {
                                        form.state.isSaving
                                        && <span className="cl-button-loader"></span>
                                    }
                                </button>
                            </div>
                        </div>
                    }
                </form>
            }
        </>
    );
}

export default FormPersonalDetails;