import { createContext, useState, useEffect, useContext } from "react";

import { useApi } from '../hooks';


const GamesContext = createContext("GamesContext");

const GamesContextProvider = ({children}) => {
    const api = useApi();
    const [games, setGames] = useState([]);
    const [gameGroups, setGameGroups] = useState([]);

    const getGameGroups = (games) => {
        if (!games) return false;

        const gamesPerRow = 3;
        let gameGroups = [[]];
        let k = 0;

        for (let i = 0; i < games.length; i++) {
            gameGroups[k].push(games[i]);

            if ((i + 1)%gamesPerRow === 0 && (i +1 ) < games.length) {
                k += 1;
                gameGroups.push([]);
            }
        }

        return gameGroups;
    };

    useEffect(() => {
        api.getGames().then(response => {
            if (response.ok) setGames(response.data);
        });
    }, []);

    useEffect(() => {
        setGameGroups(getGameGroups(games));
    }, [games]);

    return (
        <GamesContext.Provider
            value={{
                games: games,
                gameGroups: gameGroups
            }}
        >
            {children}
        </GamesContext.Provider>
    );
};

const useGamesContext = () => {
    const gamesContext = useContext(GamesContext);
    if (gamesContext === undefined) {
        throw new Error('gamesContext must be used within a GamesContext.')
    }

    return gamesContext;
};

export { useGamesContext, GamesContextProvider };