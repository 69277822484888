import { createContext, useState, useEffect, useContext } from "react";

import {
    getJwtToken,
    getRefreshToken,
    activateEmailVerifiedEventListener
} from "../services";
import { useApi } from '../hooks';


const UserContext = createContext("UserContext");

const UserContextProvider = ({children}) => {
    const [user, setUser] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const setUserContext = (data) => {
        setIsAuthenticated(true);
        setUser(data);
    };
    const resetUserContext = () => {
        setIsAuthenticated(false);
        setUser(null);
    };
    const setEmailAsVerified = () => {
        let newUser = {...user};
        newUser.is_email_verified = true;
        setUser(newUser);
    };



    useEffect(() => {
        activateEmailVerifiedEventListener(setEmailAsVerified);
    }, []);



    const api = useApi();
    const [isInitialGetProfileReady, setIsInitialGetProfileReady] = useState(false);
    const [isGetProfilePending, setIsGetProfilePending] = useState(false);
    async function getProfile() {
        if (isGetProfilePending) return;
        if (!getRefreshToken()) {
            resetUserContext();
            return;
        }

        setIsGetProfilePending(true);
        await api.getProfile(false).then(response => {
            setIsGetProfilePending(false);

            if (response.ok) setUserContext(response.data);
            else resetUserContext();
        });
    };
    useEffect(() => {
        getProfile().then(response => {
            setIsInitialGetProfileReady(true);

            const timeoutInterval = 50;
            const timeoutForceGetProfile = 90000;

            let access_token = getJwtToken();
            let timeLeftToForceGetProfile = timeoutForceGetProfile;

            const interval = setInterval(() => {
                timeLeftToForceGetProfile -= timeoutInterval;

                if (access_token !== getJwtToken()) {
                    access_token = getJwtToken();

                    if (access_token) getProfile();
                    else resetUserContext();
                } else if (timeLeftToForceGetProfile <= 0) {
                    timeLeftToForceGetProfile = timeoutForceGetProfile;

                    getProfile();
                }
            }, timeoutInterval);

            return () => clearInterval(interval);
        });
    }, []);

    return (
        <>
            {
                isInitialGetProfileReady
                && <UserContext.Provider
                    value = {{
                        isAuthenticated: isAuthenticated,
                        currency_code: isAuthenticated && typeof user.currency_code !== 'undefined' ? user.currency_code : 'GBP',
                        user: user,
                        setEmailAsVerified: setEmailAsVerified
                    }}
                >
                    {children}
                </UserContext.Provider>
            }
            {
                !isInitialGetProfileReady
                && <div>
                    <div className="preloader">
                        <div className="preloader-inner">
                            <div className="preloader-icon">
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

const useUserContext = () => {
    const userContext = useContext(UserContext);
    if (userContext === undefined) {
        throw new Error('userContext must be used within a UserContext.')
    }

    return userContext;
};

export { useUserContext, UserContextProvider };