import { Link, useParams } from 'react-router-dom';
import { Fragment, useState, useEffect } from "react";

import {
	GameStartsAt,
	FiatFormatter,
	CryptoFormatter,
	MenuListOfGamesMobile,
} from '../../components';
import { useApi } from '../../hooks';
import GameCountdown from './Components/GameCountdown';
import { getCurrentUTCUnixTimestamp } from '../../services';
import { useUserContext, useGamesContext } from '../../context';

import popcornImg from '../../assets/img/popcorn.png';


const WatchDraw = () => {
	const api = useApi();
	const intervalWatchingTheDraw = 1;
	const intervalCheckingGameChange = 10;

  	const { key } = useParams();
	const [draw, setDraw] = useState([]);
	const [game, setGame] = useState(false);
	const [prizes, setPrizes] = useState(false);
	const [currentUTCUnixTimestamp, setCurrentUTCUnixTimestamp] = useState(0);

	const userContext = useUserContext();
	const gameContext = useGamesContext();

	useEffect(() => {
		if (gameContext.games) {
			for (let i = 0; i < gameContext.games.length; i++) {
				if (gameContext.games[i].key === key) {
					const game = gameContext.games[i];
					setGame(game);
					setDraw(
						[...Array(game.sets[0].n).keys()].map(i => false)
					);
					break;
				}
			}
		}
	}, [gameContext, key]);

	useEffect(() => {
		if (game) {
			const interval = setInterval(() => {
				let t = getCurrentUTCUnixTimestamp();
				setCurrentUTCUnixTimestamp(t);

				// IMPORTANT: It is important the values of the draw array to be set to none false values
				// as well as the "prizes" property to be set to a none false value BEFORE game.next_draw.end_at!
				if (
					t >= game.next_draw.start_at
					&& t < game.next_draw.end_at
					&& t % intervalWatchingTheDraw === 0
					&& (
						draw.includes(false)
						|| draw.includes(true)
						|| prizes === false
					)
				) {
					api.getGameWatch().then(response => {
						if (response.ok) {
							let watch = response.data;

							setDraw(watch.draw);
							setPrizes(
								(
									!watch.draw.includes(false)
									&& !watch.draw.includes(true)
									&& watch.prizes !== false
								)
								? watch.prizes
								: false
							);
						}
					});
				}

				if (
					t >= game.next_draw.end_at
					&& t % intervalCheckingGameChange === 0
				) {
					api.getGames().then(response => {
						if (response.ok) {
							let games = response.data;

							for (let i = 0; i < games.length; i++) {
								if (
									games[i].key === key
									&& (
										game.next_draw.ticket_sell_closes_at !== games[i].next_draw.ticket_sell_closes_at
										|| game.next_draw.start_at !== games[i].next_draw.start_at
										|| game.next_draw.end_at !== games[i].next_draw.end_at
									)
								) {
									setGame(games[i]);
									setDraw(
										[
											...Array(games[i].sets[0].n).keys()
										].map(i => false)
									);
									setPrizes(false);
									break;
								}
							}
						};
					});

					if (
						draw.includes(false)
						|| draw.includes(true)
						|| prizes === false
					) {
						api.getGameWatch().then(response => {
							if (response.ok) {
								let watch = response.data;

								if (
									!watch.draw.includes(false)
									&& !watch.draw.includes(true)
									&& watch.prizes !== false
								) {
									setDraw(watch.draw);
									setPrizes(watch.prizes);
								}
							}
						});
					}
				}
			}, 1000);

			return () => clearInterval(interval);
		}
	}, [game, draw, prizes]);

  	return (
		game
		&& gameContext.games
		&& <>
			<div className="container is-fixed-top is-hidden-desktop">
				<MenuListOfGamesMobile
					games={gameContext.games}
					urlEnding="/watch"
				/>
			</div>
			<section className={`section has-background-${game.color} has-text-white pl-0 pr-0 pt-5 m-0`}>
				<div className="container">
					<div className="columns">
						<div className="column is-4 ml-5 mr-5">
							<div className="content">
								<h1 className="title is-size-4 has-text-white">{game.name}</h1>
							</div>
							<div className="content mt-6">
								{
									currentUTCUnixTimestamp > 0
									&& game.next_draw.start_at > currentUTCUnixTimestamp
									&& <h2 className="title is-size-5 has-text-white has-text-weight-normal mb-2">
										<GameStartsAt startAt={game.next_draw.start_at}/>
									</h2>
								}
								<p className="mt-0">Game starts in:</p>
							</div>
							<div className="content is-flex is-vcentered mb-0 mt-0">
								<GameCountdown game={game} />
							</div>
							<div className="content mt-0 mb-6">
								{
									currentUTCUnixTimestamp > 0
									&& game.next_draw.ticket_sell_closes_at > currentUTCUnixTimestamp
									&& (
										<>
											<Link
												to={`/game/${key}`}
												className={`button is-rainbow-${game.color} has-border is-rounded mt-3 mr-3`}
											>PLAY THE GAME</Link>
											<Link
												to={`/game/${key}/results`}
												className={`button is-${game.color} has-border is-rounded mt-3`}
											>CHECK PREVIOUS RESULTS</Link>
										</>
									)
								}
								{
									currentUTCUnixTimestamp > 0
									&& (
										currentUTCUnixTimestamp >= game.next_draw.end_at
										|| (
											!draw.includes(false)
											&& !draw.includes(true)
											&& prizes !== false
										)
									)
									&& <Link
										to={`/game/${key}/results`}
										className={`button is-${game.color} has-border is-rounded mt-3`}
									>CHECK RESULTS</Link>
								}
							</div>
						</div>
						<div className="column mt-6 pl-5 pr-5">
							<div className="content">
								<h1 className="title is-size-2 has-text-white mt-3">
									Watch the Draw&nbsp;&nbsp;
									{
										currentUTCUnixTimestamp > 0
										&& currentUTCUnixTimestamp >= game.next_draw.ticket_sell_closes_at
										&& game.next_draw.start_at > currentUTCUnixTimestamp
										&& <span className="tag is-dark">STARTS SOON</span>
									}
									{
										currentUTCUnixTimestamp > 0
										&& currentUTCUnixTimestamp >= game.next_draw.start_at
										&& game.next_draw.end_at > currentUTCUnixTimestamp
										&& (
											draw.includes(false)
											|| draw.includes(true)
											|| prizes === false
										)
										&& <span className="tag is-dark ml-3">LIVE</span>
									}
									{
										currentUTCUnixTimestamp > 0
										&& (
											currentUTCUnixTimestamp >= game.next_draw.end_at
											|| (
												!draw.includes(false)
												&& !draw.includes(true)
												&& prizes !== false
											)
										)
										&& <span className="tag is-dark ml-3">ENDED</span>
									}
								</h1>
							</div>
							{
								currentUTCUnixTimestamp > 0
								&& currentUTCUnixTimestamp >= game.next_draw.start_at
								&& <div className="content">
									{draw.map((number, index) => (
										<p className="draws-number" key={index}>
											{
												number === false
												&& <>X</>
											}
											{
												number === true
												&& <span className="cl-loader"></span>
											}
											{
												number !== false
												&& number !== true
												&& <>{number}</>
											}
										</p>
									))}
									<br /><br /><br /><br /><br />
								</div>
							}
							{
								false !== prizes
								&& !draw.includes(false)
								&& !draw.includes(true)
								&& currentUTCUnixTimestamp > 0
								&& currentUTCUnixTimestamp >= game.next_draw.start_at
								&& <div className="content">
									<div className="columns">
										<div className="column mt-0 is-7">
											<table className="table watch-the-draw-result-table">
												<thead>
													<tr>
														<th></th>
														<th>Size</th>
														<th>Wins</th>
													</tr>
												</thead>
												<tbody>
													{prizes.map((prize, index) => (
														<tr
															key={index}
															className={`${prize.youWin ? 'blinking' : ''}`}
														>
															<th>
																{
																	prize.type === 'jackpot'
																	? 'Jackpot'
																	: prize.matches[0] + ' numbers'
																}
															</th>
															<td>
																{
																	prize.prize.crypto.value === 'Free ticket'
																	? 'Free ticket'
																	: <>
																		<CryptoFormatter data={prize.prize.crypto}/>&nbsp;
																		(<FiatFormatter type="JACKPOT" data={prize.prize.fiat[userContext.currency_code]}/>)
																	</>
																}
																{
																	prize.type === 'jackpot'
																	&& prize.partners.length > 0
																	&& <p className="is-size-7 mt-3">
																		Donations distributed between -

																		{prize.partners.map((partner, indexTwo) => (
																			<span key={indexTwo}>
																				{0 < indexTwo && indexTwo + 1 < prize.partners.length ? ', ' : ''}
																				{0 < indexTwo && indexTwo + 1 == prize.partners.length ? ' and ' : ''}
																				<a href={partner.url} target="_blank" rel="noreferrer nofollow" className="link-white">{partner.name}</a>
																			</span>
																		))}
																	</p>
																}
															</td>
															<td>
																<span>{prize.wins === 0 ? '- - -' : prize.wins}</span>
																{
																	prize.youWin
																	&& <span className="is-size-7">
																		<br />You won!
																		<br /><Link to={`/game/${key}/results`} className="link-white">Check results</Link>
																	</span>
																}
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
										<div className="column"></div>
									</div>
								</div>
							}

							<div className="content mt-6">
								<div className="jackpot-size has-text-left is-size-1 has-text-weight-bold">
									<CryptoFormatter data={game.jackpot.crypto}/>&nbsp;
									(<FiatFormatter type="JACKPOT" data={game.jackpot.fiat[userContext.currency_code]}/>)
								</div>
								<div className="jackpot-subtitle has-text-left is-size-2 has-text-weight-bold">
									JACKPOT
								</div>
							</div>
							<div className="content has-text-right is-hidden-touch mt-6">
								<img src={popcornImg} alt="Popcorn when watching" width="160"/>
							</div>
						</div>
					</div>

					<hr />

					<div className="columns is-flex is-vcentered px-3 py-3">
						<div className="column is-4">
							<h2 className="title is-size-2 has-text-white mt-3">
								Watch the draws of
							</h2>
						</div>
						<div className="column">
							{
								gameContext.games.map((g) => (
									<Fragment key={g.key}>
										{
											g.key !== key
											&& <Link
												to={`/game/${g.key}/watch`}
												className={`button is-rainbow-${g.color} has-border is-rounded mr-3 mt-3`}
											>{g.name}</Link>
										}
									</Fragment>
								))
							}
						</div>
					</div>
				</div>
				<div className="block my-6"></div>
			</section>
		</>
  	)
}

export default WatchDraw