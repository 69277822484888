import { useReducer, useEffect, useState } from "react";

import {
    InProgressText,
    DataNotLoadedErrorMessage
} from '../../../../components';
import { useApi } from '../../../../hooks';


const ACTIONS = {
    set: 'SET',

    enableEditRegime: 'ENABLE_SWITCH_REGIME',
    disableEditRegime: 'DISABLE_SWITCH_REGIME',

    setIsLotteryGamesActive: 'SET_IS_LOTTERY_GAMES_ACTIVE',
    setIsWinnersAndGoodCausesActive: 'SET_IS_WINNERS_AND_GOOD_CAUSES_ACTIVE',

    startSaving: 'START_SAVING',
    terminateSaving: 'TERMINATE_SAVING'
};

function reducer(form, action) {
    let newForm = {...form};
    const resetFields = (newForm, emailPreferences) => {
        newForm.data.isLotteryGamesActive = emailPreferences.isLotteryGamesActive;
        newForm.data.isWinnersAndGoodCausesActive = emailPreferences.isWinnersAndGoodCausesActive;
    };
    const resetStates = (newForm) => {
        newForm.state.api.serverError = false;
        newForm.state.api.validationError = false;

        newForm.state.isSaving = false;
        newForm.state.isEditing = false;
    };
    const setFields = (newForm, action) => {
        action.payload.emailPreferences.isLotteryGamesActive = newForm.data.isLotteryGamesActive;
        action.payload.emailPreferences.isWinnersAndGoodCausesActive = newForm.data.isWinnersAndGoodCausesActive;
    };

    switch (action.type) {
        case ACTIONS.set:
            return {
                data: {
                    isLotteryGamesActive: action.payload.emailPreferences.isLotteryGamesActive,
                    isWinnersAndGoodCausesActive: action.payload.emailPreferences.isWinnersAndGoodCausesActive
                },
                state: {
                    isEditing: false,
                    isSaving: false,
                    api: {
                        serverError: false,
                        validationError: false
                    }
                }
            };
        case ACTIONS.enableEditRegime:
            newForm.state.isEditing = true;

            return newForm;
        case ACTIONS.disableEditRegime:
            resetFields(newForm, action.payload.emailPreferences);
            resetStates(newForm);

            return newForm;
        case ACTIONS.setIsLotteryGamesActive:
            newForm.data.isLotteryGamesActive = action.payload.isLotteryGamesActive;

            return newForm;
        case ACTIONS.setIsWinnersAndGoodCausesActive:
            newForm.data.isWinnersAndGoodCausesActive = action.payload.isWinnersAndGoodCausesActive;

            return newForm;
        case ACTIONS.startSaving:
            newForm.state.isSaving = true;

            return newForm;
        case ACTIONS.terminateSaving:
            if (action.payload.isChanged) {
                if (action.payload.isSaved) {
                    setFields(newForm, action);
                    resetStates(newForm);
                } else {
                    newForm.state.isSaving = false;

                    switch (action.payload.error) {
                        case 'SERVER_ERROR':
                            newForm.state.api.serverError = true;
                            newForm.state.api.validationError = false;
                            break;
                        default:
                            newForm.state.api.serverError = false;
                            newForm.state.api.validationError = true;
                            break;
                    }
                }
            } else {
                resetFields(newForm, action.payload.emailPreferences);
                resetStates(newForm);
            }

            return newForm;
        default:
            return newForm;
    }
}

const FormEmailPreferences = () => {
    const api = useApi();

    const [emailPreferences, setEmailPreferences] = useState({
        isLotteryGamesActive: 1,
        isWinnersAndGoodCausesActive: 1
    });
    const [error, setError] = useState(false);
    const [isPending, setIsPending] = useState(true);
    useEffect(() => {
        api.getSettingsEmailPreferences().then(response => {
            setIsPending(false);

            if (!response.ok) {
                setError(true);
                return;
            }

            if (
                response.data.hasOwnProperty('is_lottery_games_active')
                && response.data.hasOwnProperty('is_winners_and_good_causes_active')
            ) {
                const d = {
                    isLotteryGamesActive: response.data.is_lottery_games_active,
                    isWinnersAndGoodCausesActive: response.data.is_winners_and_good_causes_active
                };

                setEmailPreferences(d);
                dispatch(
                    {
                        type: ACTIONS.set,
                        payload: { emailPreferences: d }
                    }
                );
            }
        });
    }, []);



    const [form, dispatch] = useReducer(reducer, {
        data: {
            isLotteryGamesActive: false,
            isWinnersAndGoodCausesActive: false
        },
        state: {
            isEditing: false,
            isSaving: false,
            api: {
                serverError: false,
                validationError: false
            }
        }
    });



    const handleEdit = () => {
        dispatch({type: ACTIONS.enableEditRegime});
    };
    const handleIsLotteryGamesActiveChange = (isLotteryGamesActive) => {
        dispatch(
            {
                type: ACTIONS.setIsLotteryGamesActive,
                payload: { isLotteryGamesActive: isLotteryGamesActive }
            }
        );
    };
    const handleIsWinnersAndGoodCausesActiveChange = (isWinnersAndGoodCausesActive) => {
        dispatch(
            {
                type: ACTIONS.setIsWinnersAndGoodCausesActive,
                payload: { isWinnersAndGoodCausesActive: isWinnersAndGoodCausesActive }
            }
        );
    };
    const handleCancel = () => {
        dispatch(
            {
                type: ACTIONS.disableEditRegime,
                payload: { emailPreferences: emailPreferences }
            }
        );
    };
    const handleSave = (e) => {
        e.preventDefault();
        e.stopPropagation();

        dispatch({ type: ACTIONS.startSaving });
        if (
            form.data.isLotteryGamesActive !== emailPreferences.isLotteryGamesActive
            || form.data.isWinnersAndGoodCausesActive !== emailPreferences.isWinnersAndGoodCausesActive
        ) {
            api.updateSettingsEmailPreferences(
                {
                    "is_lottery_games_active": form.data.isLotteryGamesActive,
                    "is_winners_and_good_causes_active": form.data.isWinnersAndGoodCausesActive
                }
            ).then(response => {
                dispatch(
                    {
                        type: ACTIONS.terminateSaving,
                        payload: {
                            isChanged: true,
                            emailPreferences: emailPreferences,
                            isSaved: response.ok,
                            error: response.error
                        }
                    }
                );
            });
        } else {
            dispatch(
                {
                    type: ACTIONS.terminateSaving,
                    payload: {
                        isChanged: false,
                        emailPreferences: emailPreferences
                    }
                }
            );
        }
    };

    return (
        <>
            <p>
                Your email preferences, which you can change at any time, are set out below. If you'd like to opt out of email marketing, simply untick all the buttons. You can also opt out having your personal data used
                by Coin Lottery for other forms of marketing in your personalisation settings.
            </p>
            <p>
                You'll still receive emails with essentual informaiton, and most importantly, we'll let you know when you win!
            </p>
            <h2 className="title is-size-4 mt-6">
                I would like to receive emails about:
            </h2>

            {error && <DataNotLoadedErrorMessage />}
            {isPending && <InProgressText/>}
            {
                !error
                && !isPending
                && emailPreferences
                && <>
                    {
                        form.state.api.serverError
                        && <article className="message is-danger">
                            <div className="message-body">
                                Error. Please wait a few minutes before you try again.
                            </div>
                        </article>
                    }

                    <hr />

                    <div className="columns">
                        <div className="column is-10">
                            <h4 className="mb-0">Lottery games</h4>
                            Don't miss out on jackpots, special events, promotions, competitions, new game launches and changes to our game.
                        </div>
                        <div className="column">
                            <div className="field float-left mr-2">
                                <input
                                    id="switchLotteryGames"
                                    type="checkbox"
                                    name="switchLotteryGames"
                                    className="switch is-rounded is-info"
                                    onChange={(e) => handleIsLotteryGamesActiveChange(e.target.checked)}
                                    checked={form.data.isLotteryGamesActive}
                                    disabled={!form.state.isEditing}
                                />
                                <label htmlFor="switchLotteryGames"></label>
                            </div>
                        </div>
                    </div>

                    <hr />

                    <div className="columns">
                        <div className="column is-10">
                            <h4 className="mb-0">Winners and Good causes</h4>
                            See how your money is used to transform lives.
                        </div>
                        <div className="column">
                            <div className="field float-left mr-2">
                                <input
                                    id="switchWinnersAndGoodCauses"
                                    type="checkbox"
                                    name="switchWinnersAndGoodCauses"
                                    className="switch is-rounded is-info"
                                    onChange={(e) => handleIsWinnersAndGoodCausesActiveChange(e.target.checked)}
                                    checked={form.data.isWinnersAndGoodCausesActive}
                                    disabled={!form.state.isEditing}
                                />
                                <label htmlFor="switchWinnersAndGoodCauses"></label>
                            </div>
                        </div>
                    </div>

                    {
                        !form.state.isEditing
                        && <div className="field is-grouped is-flex is-justify-content-right">
                            <div className="control">
                                <button
                                    onClick={handleEdit}
                                    className="button is-secondary is-rounded"
                                >Edit</button>
                            </div>
                        </div>
                    }
                    {
                        form.state.isEditing
                        && <div className="field is-grouped is-flex is-justify-content-right">
                            <div className="control">
                                <button
                                    onClick={handleCancel}
                                    className="button is-rounded"
                                >Cancel</button>
                            </div>
                            <div className="control">
                                <button
                                    className="button is-primary is-rounded"
                                    onClick={handleSave}
                                >
                                    Save
                                    {
                                        form.state.isSaving
                                        && <span className="cl-button-loader"></span>
                                    }
                                </button>
                            </div>
                        </div>
                    }
                </>
            }
        </>
    );
}

export default FormEmailPreferences;