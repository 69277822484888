import { useRef, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';


const GameMobileListOfGamesMenu = ({games, urlEnding = ''}) => {
    let openRef = useRef();
    const { key } = useParams();

    useEffect(() => {
        const pixels = 36;

        let gameNumber = 0;
        for (let i = 0; i < games.length; i++) {
            if (key === games[i].key) {
                gameNumber = i;
                break;
            }
        }

        openRef.current.scrollLeft = gameNumber * pixels;
    }, [key]);

    return (
        <div
            ref={openRef}
            className="tabs is-small has-background-light mb-1"
            id="nav-mobile-level-one"
        >
            <ul>
                {
                    games.map((game) => (
                        <li
                            key={game.id}
                            className={key === game.key ? 'is-active' : ''}
                        >
                            <Link to={`/game/${game.key}${urlEnding}`}>{game.name}</Link>
                        </li>
                    ))
                }
            </ul>
        </div>
    );
}

export default GameMobileListOfGamesMenu;