export const authTranslations = {
	// API -> General
	'INVALID_PAYLOAD': 'One or more of the required fields below are not filled in. Please check and try again!',
	'UNEXPECTED_ERROR': 'Unexpected error!',



	// API -> Auth
	'REQUIRE_EMAIL': 'Email is required.',
	'REQUIRE_EMAIL_AND_PASSWORD': 'Email and password are both required.',

	'INVALID_KEY': 'Invalid key.',
	'INVALID_EMAIL': 'Invalid email address.',
	'INVALID_2FA_CODE': 'Invalid 2FA authorization code.',
	'INVALID_PASSWORD': 'Password not strong enought.',
	'INVALID_CREDENTIALS': 'Invalid credentials.',
	'INVALID_SECURITY_ANSWER': 'Invalid security answer.',
	'INVALID_SECURITY_QUESTION': 'Invalid security question.',
	'INVALID_SECURITY_QUESTION_OR_ANSWER': 'Invalid security question or answer.',

	'MISMATCH_EMAIL_AND_CONFIRM_EMAIL': 'Email and confirm email do not match.',
	'MISMATCH_PASSWORD_AND_CONFIRM_PASSWORD': 'Password and confirm password do not match',

	'ACCESS_DENIED': 'Access denied!',
	'EMAIL_OCCUPIED': 'Email address is already occipied.',
	'SOCIAL_LOGIN_FAILED': 'Login via social network unsuccessful! Please try again.',
	'INTERNAL_SERVER_ERROR': 'Unexpected error! Please try again.',



	// Auth
	'facebook_login_failed': 'Unable to log in via Facebook.',
	'google_login_failed': 'Unable to log in via Google.',
	'twitter_login_failed': 'Unable to log in via Twitter.',
}