const DataNotLoadedErrorMessage = ({message = false}) => {
    return (
        <p className="has-text-grey-light ml-3 mb-3">
            {message}
            {
                !message
                && <>Data cannot be loaded! Please try again later.</>
            }
        </p>
    );
}

export default DataNotLoadedErrorMessage;