import { Link, useLocation } from 'react-router-dom';


const MenuResultsAndDrawsDesktop = ({ gameKey, gameColor }) => {
    const location = useLocation();

    return (
        <>
            <Link
                to={`/game/${gameKey}/results`}
                className={`button is-rounded ${ location.pathname.includes('/results') ? 'is-rainbow-' + gameColor : '' }`}
            >Check my Numbers</Link>
			<Link
                to={`/game/${gameKey}/draws`}
                className={`button is-rounded ml-2 ${ location.pathname.includes('/draws') ? 'is-rainbow-' + gameColor : '' }`}
            >Draw History</Link>
        </>
    );
}

export default MenuResultsAndDrawsDesktop;