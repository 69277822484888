const WhereTheMoneyGoes = () => {
    return (
      <div className="container">
          <section className="section">
              <h1 className="title">Where the Money Goes?</h1>
              {/* <h2 className="subtitle">
                  Add some text here...
              </h2> */}
              <p>When you purchase a lottery ticket, you'll be doing so using Bitcoin (BTC). A portion of the collection of crypto assets is then distributed to winners of small prizes a few times a week. The remaining funds are kept and accumulated for the next draw, and this continues until one or more people win the jackpot.</p>
              <p>When a jackpot is won, the collected Bitcoin is distributed as follows:</p>
              <p className="ml-5">
                <ul>
                    <li>&bull; 15% of the collected funds are paid to CoinLottery as an operating fee, to help cover the cost of running the platform and ensure that we can continue to provide a reliable and trustworthy service to our users.</li>
                    <li>&bull; Small prize winners will receive their portion of the collected funds, ensuring that many people are able to benefit from our platform even if they don't win the jackpot.</li>
                    <li>&bull; The winners of the jackpot will receive the largest portion of the collected funds, allowing them to potentially change their lives for the better.</li>
                    <li>&bull; Finally, the rest of the funds are distributed to our partners: NGOs and organisations that we support and contribute to. We believe in using our platform to give back and make a positive impact in the world.</li>
                </ul>
              </p>
              <p>We want to assure you that we take the distribution of funds very seriously and are committed to being transparent about how we use the funds collected via the purchased lottery tickets. By playing CoinLottery games, you're not only giving yourself the chance to win big, but you're also supporting a good cause and contribute to making the world a better place.</p>
          </section>
          <div className="block my-6"></div>
      </div>
    )
}

export default WhereTheMoneyGoes