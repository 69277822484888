import { Link } from 'react-router-dom';


const Pagination = ({url, page, totalPages, numberOfResults}) => {
    return (
        <>
            {(
                () => {
                    const prevPage = page - 1;
                    const nextPage = page + 1;
                    const onePageBeforeLast = totalPages - 1;
                    const twoPagesBeforeLast = totalPages - 2;
                    const threePagesBeforeLast = totalPages - 3;

                    if (totalPages > 1 && numberOfResults) {
                        if (totalPages <= 6) {
                            let rows = [];
                            for (let i = 1; i <= totalPages; i++) {
                                let currentClassName = '';
                                if (page === i) {
                                    currentClassName = 'is-current'
                                }

                                rows.push(
                                    <li key={`page-${i}`}>
                                        <Link to={`${url}/${i}`} className={`pagination-link ${currentClassName}`} aria-label={`Page ${i}`}>{i}</Link>
                                    </li>
                                );
                            }

                            return (
                                <nav className="pagination is-rounded is-centered" role="navigation" aria-label="pagination">
                                    <ul className="pagination-list">
                                        {rows}
                                    </ul>
                                </nav>
                            )
                        } else {
                            return (
                                <nav className="pagination is-rounded is-centered" role="navigation" aria-label="pagination">
                                    <ul className="pagination-list">
                                        <li>
                                            <Link to={`${url}/1`} className={ (page === 1) ? `pagination-link is-current` : `pagination-link` } aria-label="Page 1">1</Link>
                                        </li>

                                        {
                                            (1 <= page && page <= 3)
                                            &&
                                            <>
                                                <li>
                                                    <Link to={`${url}/2`} className={ (page === 2) ? `pagination-link is-current` : `pagination-link` } aria-label="Page 2">2</Link>
                                                </li>
                                                <li>
                                                    <Link to={`${url}/3`} className={ (page === 3) ? `pagination-link is-current` : `pagination-link` } aria-label="Page 3">3</Link>
                                                </li>
                                                {
                                                    (page === 3)
                                                    &&
                                                    <li>
                                                        <Link to={`${url}/4`} className="pagination-link" aria-label="Page 4">4</Link>
                                                    </li>
                                                }
                                            </>
                                        }

                                        <li><span className="pagination-ellipsis">&hellip;</span></li>

                                        {
                                            (3 <= prevPage && nextPage <= twoPagesBeforeLast)
                                            &&
                                            <>
                                                <li>
                                                    <Link to={`${url}/${prevPage}`} className="pagination-link" aria-label={`Page ${prevPage}`}>{prevPage}</Link>
                                                </li>
                                                <li>
                                                    <Link to={`${url}/${page}`} className="pagination-link is-current" aria-label={`Page ${page}`}>{page}</Link>
                                                </li>
                                                <li>
                                                    <Link to={`${url}/${nextPage}`} className="pagination-link" aria-label={`Page ${nextPage}`}>{nextPage}</Link>
                                                </li>
                                                <li><span className="pagination-ellipsis">&hellip;</span></li>
                                            </>
                                        }

                                        {
                                            (twoPagesBeforeLast <= page && page <= totalPages)
                                            &&
                                            <>
                                                {
                                                    (page === twoPagesBeforeLast)
                                                    &&
                                                    <li>
                                                        <Link to={`${url}/${threePagesBeforeLast}`} className={ (page === threePagesBeforeLast) ? `pagination-link is-current` : `pagination-link` } aria-label={`Page ${threePagesBeforeLast}`}>{threePagesBeforeLast}</Link>
                                                    </li>
                                                }
                                                <li>
                                                    <Link to={`${url}/${twoPagesBeforeLast}`} className={ (page === twoPagesBeforeLast) ? `pagination-link is-current` : `pagination-link` } aria-label={`Page ${twoPagesBeforeLast}`}>{twoPagesBeforeLast}</Link>
                                                </li>
                                                <li>
                                                    <Link to={`${url}/${onePageBeforeLast}`} className={ (page === onePageBeforeLast) ? `pagination-link is-current` : `pagination-link` } aria-label={`Page ${onePageBeforeLast}`}>{onePageBeforeLast}</Link>
                                                </li>
                                            </>
                                        }

                                        <li>
                                            <Link to={`${url}/${totalPages}`} className={ (page === totalPages) ? `pagination-link is-current` : `pagination-link` } aria-label={`Page ${totalPages}`}>{totalPages}</Link>
                                        </li>
                                    </ul>
                                </nav>
                            )
                        }
                    }
                }
            )()}
        </>
     );
}

export default Pagination;