import { Outlet, Navigate } from 'react-router-dom'

import { getJwtToken } from '../../../services';


const UnauthorizedRoutes = () => {
    return (
        getJwtToken() ? <Navigate to="/settings/profile-and-settings/personal-details"/> : <Outlet/>
    )
}

export default UnauthorizedRoutes;