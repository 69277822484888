import { setJwtToken, removeTokens } from '../../services';


function loginEventListenerAmongTabs(e, navigate) {
    if (e.key === "login" && e.newValue !== null && e.newValue !== '') {
        const data = JSON.parse(e.newValue);
        for (let key in data) {
            if (key === 'jwt') {
                setJwtToken(data[key]);
                break;
            }
        }

        navigate('/');
    }
};
export function triggerLoginEventListener() {
    window.localStorage.setItem('login', JSON.stringify(sessionStorage));
    window.localStorage.removeItem("login");
};
export function activateLoginEventListener(navigate) {
    window.addEventListener('storage', function(e) {
        loginEventListenerAmongTabs(e, navigate);
    });
};



function logoutEventListenerAmongTabs(e, navigate) {
    if (e.key === 'logout' && e.newValue !== null && e.newValue !== '') {
        removeTokens();
        navigate('/login');
    }
};
export function triggerLogoutEventListener() {
    window.localStorage.setItem('logout', Date.now());
    window.localStorage.removeItem("logout");
};
export function activateLogoutEventListener(navigate) {
    window.addEventListener('storage', function(e) {
        logoutEventListenerAmongTabs(e, navigate);
    });
};



function emailVerifiedEventListenerAmongTabs(e, setEmailAsVerified) {
    if (e.key === 'emailVerified' && e.newValue !== null && e.newValue !== '') {
        setEmailAsVerified();
    }
};
export function triggerEmailVerifiedEventListener() {
    window.localStorage.setItem('emailVerified', Date.now());
    window.localStorage.removeItem("emailVerified");
};
export function activateEmailVerifiedEventListener(setEmailAsVerified) {
    window.addEventListener('storage', function(e) {
        emailVerifiedEventListenerAmongTabs(e, setEmailAsVerified);
    });
};