import { useUserContext } from '../../../context';

import { FiatFormatter } from '../../../components';


const ListOfPrizes = ({game}) => {
    const userContext = useUserContext();

    return (
        game
        &&
        <table className="table">
            <tbody>
                {
                    game.prizes.map((prize, index) => (
                        <tr key={index}>
                            <th>
                                {prize.matches[0]} NUMBERS
                            </th>
                            <td className="what-you-can-win-desktop">
                                {
                                    [...Array(prize.matches[0]).keys()].map((i) => (
                                        <span key={i} className={`round-${game.color}-dot`}>.</span>
                                    ))
                                }
                            </td>
                            <td>
                                {
                                    prize.prize.fiat[userContext.currency_code].value !== 'Jackpot'
                                    && prize.prize.fiat[userContext.currency_code].value !== 'Free ticket'
                                    && <FiatFormatter
                                        type="DETAIL_NO_ZEROS"
                                        data={prize.prize.fiat[userContext.currency_code]}
                                    />
                                }
                                {
                                    (
                                        prize.prize.fiat[userContext.currency_code].value === 'Jackpot'
                                        || prize.prize.fiat[userContext.currency_code].value === 'Free ticket'
                                    )
                                    &&
                                    prize.prize.fiat[userContext.currency_code].value
                                }
                            </td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    );
}

export default ListOfPrizes;