import { useReducer, useState, useEffect, useCallback } from "react";

import {
    validate as validateBtcAddress,
    getAddressInfo
} from 'bitcoin-address-validation';
import { Link, useParams } from 'react-router-dom';
import { BsFillQuestionCircleFill } from 'react-icons/bs';
import { FaBtc, FaCheck, FaExclamationTriangle } from 'react-icons/fa';

import { useWindowSize } from '../../hooks';

import {
	ErrorBlock,
	GameStartsAt,
	FiatFormatter,
	CryptoFormatter,
	MenuListOfGamesMobile,
	DataNotLoadedErrorMessage
} from '../../components';
import { useApi } from '../../hooks';
import ListOfPrizes from './Lists/ListOfPrizes';
import GameCountdown from './Components/GameCountdown';
import { getCurrentUTCUnixTimestamp } from '../../services';
import { useUserContext, useGamesContext } from '../../context';

import howToPlay1 from '../../assets/img/howtoplay/how-to-play-coinlottery-1-click-lucky-dip.jpg';
import howToPlay2 from '../../assets/img/howtoplay/how-to-play-coinlottery-2-click-choose-numbers.jpg';
import howToPlay3 from '../../assets/img/howtoplay/how-to-play-coinlottery-3-exphesize-lucky-dip-and-choose-numbers.jpg';
import howToPlay4 from '../../assets/img/howtoplay/how-to-play-coinlottery-4-which-days.jpg';
import howToPlay5 from '../../assets/img/howtoplay/how-to-play-coinlottery-5-for-how-many-weeks.jpg';
import howToPlay6 from '../../assets/img/howtoplay/how-to-play-coinlottery-6-play.jpg';

import TreasureLogIn from '../../assets/img/games/treasure-log-in.jpg';
import BitcoinHeroLogIn from '../../assets/img/games/bitcoin-hero-log-in.jpg';
import BitcoinLottoLogIn from '../../assets/img/games/bitcoin-lotto-log-in.jpg';

import './game.css';


const ACTIONS = {
	reset: 'RESET',

    setSettingsDays: 'SET_SETTINGS_DAYS',

	setDataDays: 'SET_DATA_DAYS',
	setDataWeek: 'SET_DATA_WEEK',

	toggleModal: 'TOGGLE_MODAL',
	closeAllModals: 'CLOSE_ALL_MODALS',

	addOrRemoveNumberToTicket: 'ADD_OR_REMOVE_NUMBER_TO_TICKET',
	clearTicketNumbers: 'CLEAR_TICKET_NUMBERS',
	confirmTicketNumbers: 'CONFIRM_TICKET_NUMBERS',
	revertTicketNumbers: 'REVERT_NUMBERS',

	deleteTicket: 'DELETE_TICKET',
	setLuckyDips: 'SET_LUCKY_DIPS',
	replayTickets: 'REPLAY_TICKETS',
	resetTickets: 'RESET_TICKETS',

	setHasLastNumbers: 'SET_HAS_LAST_NUMBERS',
	setHasSavedNumbers: 'SET_HAS_SAVED_NUMBERS',

	switchScreen: 'SWITCH_SCREEN',
	setHowToPlayScreen: 'SET_HOW_TO_PLAY_SCREEN',

	setBtcAddress: 'SET_BTC_ADDRESS',

	setIsInitiatingPayment: 'SET_IS_INITIATING_PAYMENT',
	setErrorInitiatingPayment: 'SET_ERROR_INITIATING_PAYMENT',
	setIsPaid: 'SET_IS_PAID',
};

function reducer(form, action) {
    let newForm = {...form};

    const getEmptyTicket = () => {
		return {
			isLuckyDip: false,
			numbers: [],
			confirmedNumbers: []
		};
    };

	const generateNumber = (N, sequence) => {
		let n = Math.floor(Math.random() * (N - 1)) + 1;
		if (sequence.includes(n)) n = generateNumber(N, sequence);

		return n;
	};

	const generateTicketNumbers = (n, N) => {
		let sequence = [];

		for (let i = 1; i <= n; i++) {
			sequence.push(generateNumber(N, sequence));
		}

		return sequence
	};

	const canSaveNumbers = (tickets) => {
		let canSaveNumbers = false;
		for (let i = 0; i < tickets.length; i++) {
			if (
				tickets[i].isLuckyDip === false
				&& tickets[i].confirmedNumbers.length > 0
			) {
				canSaveNumbers = true;
				break
			}
		}

		return canSaveNumbers
	};

    switch (action.type) {
		case ACTIONS.reset:
			newForm.data.week = 1;

			newForm.settings.modals.isModalChooseNumbersOpened = false;
			newForm.settings.modals.isModalHowToPlayOpened = false;

			newForm.settings.screen = 1;
			newForm.settings.isSwitchingScreen = false;

			newForm.settings.howToPlayScreen = 1;

			newForm.settings.isPaid = null;

			newForm.state.isProcessing = false;
			newForm.state.isCompleted = false;
			newForm.state.isInitiatingPayment = false;
			newForm.state.api.serverError = false;
			newForm.state.api.serverErrorInitiatingPayment = false;

			return newForm;
        case ACTIONS.setSettingsDays:
			newForm.settings.days = action.payload;

			let days = [];
			for (let i = 0; i < newForm.settings.days.length; i++) {
				if (newForm.settings.days[i].checked === true) {
					days.push(newForm.settings.days[i].index);
				}
			}

			newForm.data.days = days;
			newForm.data.daysCount = days.length;

            return newForm;
		case ACTIONS.setDataDays:
			for (let i = 0; i < newForm.settings.days.length; i++) {
				if (newForm.settings.days[i].index === action.payload.dayIndex) {
					newForm.settings.days[i].checked = action.payload.checked
					break;
				}
			}

			if (
				action.payload.checked
				&&
				!newForm.data.days.includes(action.payload.dayIndex)
			) {
				newForm.data.days.push(action.payload.dayIndex)
			} else {
				newForm.data.days = newForm.data.days.filter(
					item => item !== action.payload.dayIndex
				)
			}

			newForm.data.daysCount = newForm.data.days.length

			return newForm;
		case ACTIONS.setDataWeek:
			newForm.data.week = action.payload;

			return newForm;
		case ACTIONS.toggleModal:
			switch (action.payload.name) {
				case 'modalChooseNumbers':
					newForm.settings.modals.isModalChooseNumbersOpened = action.payload.status
					break;
				case 'modalHowToPlay':
					newForm.settings.modals.isModalHowToPlayOpened = action.payload.status
					break;
				default:
					//
			}

			return newForm;
		case ACTIONS.closeAllModals:
			for (const modal in newForm.settings.modals) {
				newForm.settings.modals[modal] = false;
			}

			newForm.settings.howToPlayScreen = 1;

			return newForm;
		case ACTIONS.addOrRemoveNumberToTicket:
			if (newForm.data.tickets[action.payload.index].numbers.includes(action.payload.number)) {
				newForm.data.tickets[action.payload.index].numbers =
					newForm.data.tickets[action.payload.index].numbers.filter(
						item => item !== action.payload.number
					)
			} else {
				if (newForm.data.tickets[action.payload.index].numbers.length < action.payload.maxNumbers) {
					newForm.data.tickets[action.payload.index].numbers.push(
						action.payload.number
					)
				}
			}

			return newForm;
		case ACTIONS.clearTicketNumbers:
			newForm.data.tickets[action.payload.index].numbers = [];
			newForm.data.tickets[action.payload.index].confirmedNumbers = [];

			return newForm;
		case ACTIONS.confirmTicketNumbers:
			if (0 === newForm.data.tickets[action.payload.index].confirmedNumbers.length) {
				newForm.data.tickets.push(getEmptyTicket());
			}

			newForm.data.tickets[action.payload.index].confirmedNumbers =
				[...newForm.data.tickets[action.payload.index].numbers]
			newForm.data.ticketsCount = newForm.data.tickets.length - 1;
			newForm.settings.canSaveNumbers = canSaveNumbers(newForm.data.tickets);

			return newForm;
		case ACTIONS.revertTicketNumbers:
			newForm.data.tickets[action.payload.index].numbers =
				[...newForm.data.tickets[action.payload.index].confirmedNumbers]

			return newForm;
		case ACTIONS.deleteTicket:
			newForm.data.tickets.splice(action.payload.index, 1);
			newForm.data.ticketsCount = newForm.data.tickets.length - 1;
			newForm.settings.canSaveNumbers = canSaveNumbers(newForm.data.tickets);

			return newForm;
		case ACTIONS.setLuckyDips:
			let index = action.payload.index;
			if (index === 0) newForm.data.tickets = [];

			for (let i = 0; i < action.payload.count; i++) {
				let numbers = generateTicketNumbers(
					action.payload.n,
					action.payload.N
				)

				newForm.data.tickets[index] = {
					isLuckyDip: true,
					numbers: [...numbers],
					confirmedNumbers: [...numbers]
				};

				index++;
			}

			newForm.data.tickets.push(getEmptyTicket());
			newForm.data.ticketsCount = newForm.data.tickets.length - 1;

			return newForm;
		case ACTIONS.replayTickets:
			newForm.data.tickets = action.payload.tickets;
			newForm.data.tickets.push(getEmptyTicket());
			newForm.data.ticketsCount = newForm.data.tickets.length - 1;
			newForm.settings.canSaveNumbers = true;

			return newForm;
		case ACTIONS.resetTickets:
			newForm.data.tickets = [getEmptyTicket()];
			newForm.data.ticketsCount = newForm.data.tickets.length - 1;
			newForm.settings.canSaveNumbers = false;

			return newForm;
		case ACTIONS.setHasLastNumbers:
			newForm.settings.hasLastNumbers = action.payload;

			return newForm;
		case ACTIONS.setHasSavedNumbers:
			newForm.settings.hasSavedNumbers = action.payload;

			return newForm;
		case ACTIONS.switchScreen:
			newForm.settings.screen = action.payload.screen;
			newForm.state.api.serverErrorInitiatingPayment = false;

			return newForm;
		case ACTIONS.setHowToPlayScreen:
			const newHowToPlayScreen = newForm.settings.howToPlayScreen + action.payload.step
			if (
				1 <= newHowToPlayScreen
				&& newHowToPlayScreen <= newForm.settings.howToPlayScreenMax
			) {
				newForm.settings.howToPlayScreen = newHowToPlayScreen
			}

			return newForm;
		case ACTIONS.setBtcAddress:
			newForm.data.btcAddress = action.payload.btcAddress;
			newForm.state.isBtcAddressValid = validateBtcAddress(
				newForm.data.btcAddress
			)

			return newForm;
		case ACTIONS.setIsInitiatingPayment:
			newForm.state.isInitiatingPayment = action.payload;

			return newForm;
		case ACTIONS.setErrorInitiatingPayment:
			newForm.state.api.serverErrorInitiatingPayment = true;

			return newForm;
		case ACTIONS.setIsPaid:
			newForm.settings.isPaid = action.payload;

			return newForm;
        default:
            return newForm;
    }
}


const Game = () => {
	const intervalCheckingGameChange = 10;
	const intervalDisplayWatchDrawButton = 3600;

	const mobileWindowMaxSize = 1023;
	const size = useWindowSize();

	const api = useApi();
    const { key, luckyDips } = useParams();
	const [game, setGame] = useState(false);
	const [chooseNumbersIndex, setChooseNumbersIndex] = useState(false);
	const [currentUTCUnixTimestamp, setCurrentUTCUnixTimestamp] = useState(0);

	const userContext = useUserContext();
	const gameContext = useGamesContext();

	// Used by useEffects
	const getDayIndexCurrentUTC = () => {
		let dayIndexCurrentUTC = (new Date()).getUTCDay();
		return dayIndexCurrentUTC === 0 ? 7 : dayIndexCurrentUTC
	};

	const getTimezoneUtcOffsetInSeconds = () => {
		return 60 * (
			userContext.isAuthenticated
			? userContext.user.timezone_utc_offset
			: (-1) * (new Date().getTimezoneOffset())
		);
	};

	const setGameDays = (game) => {
		const days = {
			1: 'Mon',
			2: 'Tue',
			3: 'Wed',
			4: 'Thu',
			5: 'Fri',
			6: 'Sat',
			7: 'Sun',
		};
		const dayIndexCurrentUTC = getDayIndexCurrentUTC();
		const timezoneUtcOffsetInSeconds = getTimezoneUtcOffsetInSeconds();

		let gameDays = [];
		let allDaysAreDisabled = true;
		for (
			let k = 0;
			k < game.advanced_ticket_purchase.days.length;
			k++
		) {
			let dayIndexUTC = game.advanced_ticket_purchase.days[k].day;
			let dayIndexLocal = dayIndexUTC + (
				86400 > timezoneUtcOffsetInSeconds + game.advanced_ticket_purchase.days[k].time_delta_midnight_utc
				? 0
				: 1
			)

			gameDays.push(
				{
					index: dayIndexUTC,
					label: days[dayIndexLocal],
					enabled: dayIndexCurrentUTC <= dayIndexUTC,
					checked: false
				}
			)

			if (dayIndexCurrentUTC <= dayIndexUTC) allDaysAreDisabled = false;
		}

		if (allDaysAreDisabled) {
			for (let k = 0; k < gameDays.length; k++) {
				gameDays[k].enabled = true;
			}
		}

		for (let k = 0; k < gameDays.length; k++) {
			if (gameDays[k].enabled === true) {
				gameDays[k].checked = true;
				break;
			}
		}

		dispatch(
            {
                type: ACTIONS.setSettingsDays,
                payload: gameDays
            }
        );
	};

	useEffect(() => {
		document.addEventListener("keydown", handleEscape, false);

		return () => {
		  	document.removeEventListener("keydown", handleEscape, false);
		};
	}, []);

	useEffect(() => {
		if (gameContext.games) {
			for (let i = 0; i < gameContext.games.length; i++) {
				if (gameContext.games[i].key === key) {
					setGame(gameContext.games[i]);
					setGameDays(gameContext.games[i]);
					break;
				}
			}
		}
	}, [gameContext, key]);

	useEffect(() => {
		if (game) {
			if (window.location.href.includes('game-settings-block')) {
				document.getElementById('game-settings-block').scrollIntoView({
					behavior: 'smooth'
				});
			}

			setChooseNumbersIndex(false);

			const queryParams = new URLSearchParams(window.location.search);
  			const transaction_id = queryParams.get("transaction_id");
			if (transaction_id !== null) {
				dispatch(
					{
						type: ACTIONS.switchScreen,
						payload: { screen: 3 }
					}
				);

				setTimeout(() => {
					api.getPaymentStatus(transaction_id).then(response => {
						dispatch(
							{
								type: ACTIONS.setIsPaid,
								payload: response.ok && response.data.status === 'PAID'
							}
						);
					});
				}, 1000)
			} else {
				dispatch( { type: ACTIONS.reset } );
			}

			if (luckyDips > 0) {
				dispatch(
					{
						type: ACTIONS.setLuckyDips,
						payload: {
							index: 0,
							n: game.sets[0].n,
							N: game.sets[0].N,
							count: luckyDips
						}
					}
				);
			} else {
				dispatch( { type: ACTIONS.resetTickets } );
			}

			if (userContext.isAuthenticated) {
				api.getGameLastPlayedNumbers(key).then(response => {
					if (response.ok) {
						dispatch(
							{
								type: ACTIONS.setHasLastNumbers,
								payload: response.data.length > 0
							}
						);
					};

					api.getGameSavedNumbers(key).then(response => {
						if (response.ok) {
							dispatch(
								{
									type: ACTIONS.setHasSavedNumbers,
									payload: response.data.length > 0
								}
							);
						};
					});
				});


			}

			const interval = setInterval(() => {
				let t = getCurrentUTCUnixTimestamp();
				setCurrentUTCUnixTimestamp(t);

				if (
					t >= game.next_draw.end_at
					&& t % intervalCheckingGameChange === 0
				) {
					api.getGames().then(response => {
						if (response.ok) {
							let games = response.data;

							for (let i = 0; i < games.length; i++) {
								if (
									games[i].key === key
									&& (
										game.next_draw.ticket_sell_closes_at !== games[i].next_draw.ticket_sell_closes_at
										|| game.next_draw.start_at !== games[i].next_draw.start_at
										|| game.next_draw.end_at !== games[i].next_draw.end_at
									)
								) {
									setGame(games[i]);
									setGameDays(games[i]);
									break;
								}
							}
						};
					});
				}
			}, 1000);

			return () => clearInterval(interval);
		}
	}, [game]);



	// Used by the form
	const isAtLeastOneGameDayDisabled = () => {
		for (let k = 0; k < form.settings.days.length; k++) {
			if (form.settings.days[k].enabled === false) return true;
		}

		return false;
	};

	const isAtLeastOneGameDayEnabled = () => {
		for (let k = 0; k < form.settings.days.length; k++) {
			if (form.settings.days[k].enabled === true) return true;
		}

		return false;
	};

	const isGameAvailable = () => {
		if (
			game.advanced_ticket_purchase.days.length === 0
			||
			game.advanced_ticket_purchase.max_weeks_ahead === 0
		) return false;
		if (game.advanced_ticket_purchase.max_weeks_ahead >= 2) return true;

		return isAtLeastOneGameDayEnabled();
	};

	const blinkChooseNumbersProgressBar = (id) => {
		var el = document.getElementById(id);
		const interval = setInterval(() => {
			if (el.style.opacity === '0.5') {
				el.style.opacity = '1';
				clearInterval(interval);
				return;
			}

			el.style.opacity = '0.5';
		}, 150);
	 }



	// Reducer
	const [form, dispatch] = useReducer(reducer, {
        data: {
            days: [],
			daysCount: 0,
            week: 1,
			tickets: [
				{
					isLuckyDip: false,
					numbers: [],
					confirmedNumbers: []
				}
			],
			ticketsCount: 0,
			btcAddress: false
        },
		settings: {
			modals: {
				isModalChooseNumbersOpened: false,
				isModalHowToPlayOpened: false
			},
			days: false,

			hasSavedNumbers: false,
			canSaveNumbers: false,
			hasLastNumbers: false,

			screen: 1,
			isSwitchingScreen: false,

			howToPlayScreen: 1,
			howToPlayScreenMax: 6,

			isPaid: null,
		},
        state: {
			isBtcAddressValid: true,

            isProcessing: false,
            isCompleted: false,
			isInitiatingPayment: false,
            api: {
                serverError: false,
				serverErrorInitiatingPayment: false
            }
        }
    });

	const handleHowToPlayModal = () => {
		dispatch(
            {
                type: ACTIONS.toggleModal,
                payload: {
					name: 'modalHowToPlay',
					status: true
				}
            }
        );
	};

	const handleChooseNumbersModal = (index) => {
		dispatch(
            {
                type: ACTIONS.toggleModal,
                payload: {
					name: 'modalChooseNumbers',
					status: true
				}
            }
        );

		setChooseNumbersIndex(index);
	};

	const handleEscape = useCallback((event) => {
		if (event.key === "Escape") handleCloseModals();
	}, []);

	const handleCloseModals = () => {
		if (false !== chooseNumbersIndex) {
			if (form.data.tickets[chooseNumbersIndex].confirmedNumbers.length === game.sets[0].n) {
				dispatch(
					{
						type: ACTIONS.revertTicketNumbers,
						payload: {
							index: chooseNumbersIndex
						}
					}
				);
			} else {
				dispatch(
					{
						type: ACTIONS.clearTicketNumbers,
						payload: {
							index: chooseNumbersIndex
						}
					}
				);
			}
		}

		setChooseNumbersIndex(false);
		dispatch({ type: ACTIONS.closeAllModals });
	};

	const handleNumberClick = (number) => {
		if (
			form.data.tickets[chooseNumbersIndex].numbers.length >= game.sets[0].n
			&& false === form.data.tickets[chooseNumbersIndex].numbers.includes(number)
		) {
			blinkChooseNumbersProgressBar('choose-number-progress');
		}

		dispatch(
            {
                type: ACTIONS.addOrRemoveNumberToTicket,
                payload: {
					number: number,
					index: chooseNumbersIndex,
					maxNumbers: game.sets[0].n
				}
            }
        );
	};

	const clearTicketNumbers = () => {
		dispatch(
            {
                type: ACTIONS.clearTicketNumbers,
                payload: {
					index: chooseNumbersIndex
				}
            }
        );
	};

	const handleConfirm = () => {
		if (
			false !== chooseNumbersIndex
			&& form.data.tickets[chooseNumbersIndex].numbers.length === game.sets[0].n
		) {
			dispatch(
				{
					type: ACTIONS.confirmTicketNumbers,
					payload: { index: chooseNumbersIndex }
				}
			);

			setChooseNumbersIndex(false);
			dispatch({ type: ACTIONS.closeAllModals });
		}
	};

	const handleLuckyDip = (index) => {
		if (form.data.tickets[index].confirmedNumbers.length === 0) {
			dispatch(
				{
					type: ACTIONS.setLuckyDips,
					payload: {
						index: index,
						n: game.sets[0].n,
						N: game.sets[0].N,
						count: 1
					}
				}
			);
		}
	};

	const handleDelete = (index) => {
		dispatch(
			{
				type: ACTIONS.deleteTicket,
				payload: { index: index }
			}
		);
	};

	const handleDayClick = (dayIndex, checked) => {
		dispatch(
            {
                type: ACTIONS.setDataDays,
                payload: { dayIndex: dayIndex, checked: checked }
            }
        );
	};

	const handleWeekChange = (weekIndex) => {
		dispatch(
            {
                type: ACTIONS.setDataWeek,
                payload: weekIndex
            }
        );
	};

	const handlePlaySavedNumbers = () => {
		api.getGameSavedNumbers(key).then(response => {
			if (response.ok && response.data.length > 0) {
				let tickets = [];
				for (let i = 0; i < response.data.length; i++) {
					tickets.push(
						{
							isLuckyDip: false,
							numbers: response.data[i],
							confirmedNumbers: response.data[i]
						}
					);
				}

				dispatch(
					{
						type: ACTIONS.replayTickets,
						payload: { tickets: tickets }
					}
				);
			};
		});
	};

	const handleDeleteSavedNumbers = () => {
		api.deleteGameSavedNumbers(key).then(response => {
			if (response.ok) {
				dispatch(
					{
						type: ACTIONS.setHasSavedNumbers,
						payload: false
					}
				);
			};
		});
	};

	const handleSaveNumbers = () => {
		let tickets = [];
		for (let i = 0; i < form.data.tickets.length; i++) {
			if (
				form.data.tickets[i].isLuckyDip === false
				&& form.data.tickets[i].confirmedNumbers.length === game.sets[0].n
			) {
				tickets.push(form.data.tickets[i].confirmedNumbers);
			}
		}

		if (tickets.length > 0) {
			api.addGameSavedNumbers(key, tickets).then(response => {
				if (response.ok) {
					dispatch(
						{
							type: ACTIONS.setHasSavedNumbers,
							payload: true
						}
					);
				};
			});
		}
	};

	const handleReplayLastNumbers = () => {
		api.getGameLastPlayedNumbers(key).then(response => {
			if (response.ok && response.data.length > 0) {
				let tickets = [];
				for (let i = 0; i < response.data.length; i++) {
					tickets.push(
						{
							isLuckyDip: false,
							numbers: response.data[i],
							confirmedNumbers: response.data[i]
						}
					);
				}

				dispatch(
					{
						type: ACTIONS.replayTickets,
						payload: { tickets: tickets }
					}
				);
			};
		});
	};

	const initiatePayment = (e) => {
		e.preventDefault();
        e.stopPropagation();

		let tickets = [];
		for (let i = 0; i < form.data.tickets.length; i++) {
			if (form.data.tickets[i].numbers.length === game.sets[0].n) {
				tickets.push(
					{
						isLuckyDip: form.data.tickets[i].isLuckyDip,
						numbers: form.data.tickets[i].numbers
					}
				);
			}
		}

		dispatch(
			{
				type: ACTIONS.setIsInitiatingPayment,
				payload: true
			}
		);

        api.initiatePayment({
			'type': 'lotto',
			'key': game.key,
			'days': form.data.days,
			'weeks': form.data.week,
			'tickets': tickets
		}).then(response => {
			dispatch(
				{
					type: ACTIONS.setIsInitiatingPayment,
					payload: false
				}
			);

			if (response.ok) {
				window.location.href = response.data.checkout_url;
			} else {
				dispatch(
					{
						type: ACTIONS.setErrorInitiatingPayment,
						payload: {}
					}
				);
			}
        });
	};

	const handlePlay = () => {
		if (form.data.ticketsCount * form.data.daysCount * form.data.week > 0) {
			dispatch(
				{
					type: ACTIONS.switchScreen,
					payload: { screen: 2 }
				}
			);
		}
	};

	const handleBackToScreenOne = () => {
		dispatch(
			{
				type: ACTIONS.switchScreen,
				payload: { screen: 1 }
			}
		);
	};

	const handleBtcAddressChange = (btcAddress) => {
        dispatch(
            {
                type: ACTIONS.setBtcAddress,
                payload: { btcAddress: btcAddress }
            }
        );
    };

	const handleHowToPlayPrev = () => {
		dispatch(
            {
                type: ACTIONS.setHowToPlayScreen,
                payload: { step: -1 }
            }
        );
	};

	const handleHowToPlayNext = () => {
		dispatch(
            {
                type: ACTIONS.setHowToPlayScreen,
                payload: { step: 1 }
            }
        );
	};

	const handlePlayMore = () => {
		dispatch(
			{
				type: ACTIONS.switchScreen,
				payload: { screen: 1 }
			}
		);
	};

	const styles = {
		logInToPlay: {
			firstDiv: {
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				position: "relative"
			},
			secondDiv: {
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center"
			},
			button: {
				position: "absolute",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)"
			}
		}
	}

    return (
		game
		&&
		<>
			<div className="is-fixed-top is-hidden-desktop">
				<MenuListOfGamesMobile games={gameContext.games} />
			</div>
			<section className={`section has-background-${game.color} has-text-white pl-0 pr-0 pt-5 m-0`}>
				<div className="container">
					<div className="columns">
						<div className="column is-4 ml-5 mr-5">
							<div className="content">
								<h1 className="title is-size-4 has-text-white">Play {game.name}</h1>
							</div>
							<div className="content mt-6">
								<h2 className="title is-size-5 has-text-white has-text-weight-normal mb-2">
									<GameStartsAt startAt={game.next_draw.start_at}/>,&nbsp;
									<CryptoFormatter data={game.jackpot.crypto}/>&nbsp;
									(<FiatFormatter
										type="JACKPOT"
										data={game.jackpot.fiat[userContext.currency_code]}
									/>) jackpot
								</h2>
								<p className="mt-0">Game closes in:</p>
							</div>
							<div className="content is-flex is-vcentered mb-0 mt-0">
								<GameCountdown game={game} />
							</div>
							<div className="content mt-0">
								{
									currentUTCUnixTimestamp >= game.next_draw.start_at - intervalDisplayWatchDrawButton
									&& currentUTCUnixTimestamp < game.next_draw.end_at
									&& <Link
										to={`/game/${key}/watch`}
										className={`button is-rainbow-${game.color} has-border is-rounded mt-3`}
									>WATCH THE DRAWS</Link>
								}
								{
									currentUTCUnixTimestamp >= game.next_draw.end_at
									&& <Link
										to={`/game/${game.key}/draws`}
										className={`button is-rainbow-${game.color} has-border is-rounded mt-3`}
									>CHECK DRAW HISTORY</Link>
								}
							</div>
						</div>
						{
							isGameAvailable()
							? <div className="column mt-6 pl-5 pr-5" id="game-settings-block">
								<button
									className={`button is-rainbow-${game.color} has-border is-rounded js-modal-trigger`}
									data-target="modal-how-to-play"
									onClick={handleHowToPlayModal}
								>HOW TO PLAY</button>
								<div className="card mt-2" id="game-settings">
									{
										!userContext.isAuthenticated
										? <div
											className="card-content"
											style={styles.logInToPlay.firstDiv}
										>
											<div
												className="content mt-4"
												style={styles.logInToPlay.secondDiv}
											>
												{
													game.key === 'bitcoin-lotto'
													&& <img src={BitcoinLottoLogIn} alt="Log In Before You Play"/>
												}
												{
													game.key === 'bitcoin-hero'
													&& <img src={BitcoinHeroLogIn} alt="Log In Before You Play"/>
												}
												{
													game.key === 'treasure'
													&& <img src={TreasureLogIn} alt="Log In Before You Play"/>
												}

												<Link
													to={`/login?path=/game/${game.key}`}
													className={`button is-rounded is-rainbow-${game.color} is-rounded`}
													style={styles.logInToPlay.button}
												>Log in To Play</Link>
											</div>
										</div>
										: <>
											<div className={`card-content ${form.settings.screen === 1 ? 'fadeIn' : 'fadeOut' }`} id="game-configuration">
												<p id="price-per-game">
													<span className="has-text-weight-bold">
														<FiatFormatter
															type="TICKET"
															data={game.ticket_price.fiat[userContext.currency_code]}
														/>
													</span> per play
												</p>

												<div className="content mt-4">
													<h2 className="title is-size-5 mb-1">Play {game.name}</h2>
													<p className="is-size-7 mt-0">Enter {game.sets[0].n} numbers for each line you play or choose a Lucky Dip for randomly selected numbers.</p>

													{
														userContext.isAuthenticated
														&& <>
															<button
																onClick={handlePlaySavedNumbers}
																className={`link-standard is-size-6 has-text-weight-semibold float-left mr-4 ${form.settings.hasSavedNumbers ? '' : 'is-inactive'}`}
															>Play saved numbers</button>
															<button
																onClick={handleDeleteSavedNumbers}
																className={`link-standard is-size-6 has-text-weight-semibold float-left mr-4 ${form.settings.hasSavedNumbers ? '' : 'is-inactive'}`}
															>Delete saved numbers</button>
															<button
																onClick={handleSaveNumbers}
																className={`link-standard is-size-6 has-text-weight-semibold float-left mr-4 ${form.settings.canSaveNumbers ? '' : 'is-inactive'}`}
															>Save numbers</button>
															<button
																onClick={handleReplayLastNumbers}
																className={`link-standard is-size-6 has-text-weight-semibold float-left mr-4 ${form.settings.hasLastNumbers ? '' : 'is-inactive'}`}
															>Replay last numbers</button>
														</>
													}

													<br />
												</div>

												<div className="content clearfix is-hidden-touch" id="game-settings-desktop">
													{form.data.tickets.map((ticket, index) => (
														<div
															key={index}
															className={`columns is-flex is-vcentered mb-0 ${index === 0 ? '' : 'mt-0'}`}
														>
															<div className="column is-4">
																<button
																	className={`button is-game-setter ${ticket.isLuckyDip ? 'is-active' : (ticket.confirmedNumbers.length === game.sets[0].n ? 'is-disabled' : '')}`}
																	onClick={() => handleLuckyDip(index)}
																>LUCKY DIP</button>
															</div>
															<div className="column">
																{
																	ticket.confirmedNumbers.length !== game.sets[0].n
																	&& <>
																		<p className="none-selected-empty mb-0">.</p>
																		<button
																			onClick={() => handleChooseNumbersModal(index)}
																			className="button is-game-setter js-modal-trigger mt-0 mb-0 ml-0 mr-3"
																			data-target="modal-choose-numbers"
																		>CHOOSE NUMBERS</button>
																		<p className="none-selected-empty mb-0">.</p>
																	</>
																}
																{
																	ticket.confirmedNumbers.length === game.sets[0].n
																	&& <>
																		{
																			ticket.isLuckyDip
																			&& <>
																				{[...Array(game.sets[0].n).keys()].map((n) => (
																					<p
																						key={n}
																						className="selected-number mb-0"
																					>LD</p>
																				))}
																			</>
																		}
																		{
																			!ticket.isLuckyDip
																			&& <>
																				{ticket.confirmedNumbers.map((number, indexTwo) => (
																					<p
																						key={indexTwo}
																						className="selected-number mb-0"
																					>{number}</p>
																				))}
																			</>
																		}
																		<button
																			onClick={() => handleChooseNumbersModal(index)}
																			className={`edit-numbers ${ticket.isLuckyDip ? 'is-inactive' : 'link-standard'}`}
																		>Edit</button>
																		<button
																			className="delete-numbers link-standard"
																			onClick={() => handleDelete(index)}
																		>Delete</button>
																	</>
																}
															</div>
														</div>
													))}
												</div>

												<div className="content is-hidden-desktop mt-5" id="game-settings-mobile">
													{form.data.tickets.map((ticket, index) => (
														<span key={index}>
															<div className="columns is-flex is-vcentered mb-0">
																<div className="column choose-lucky-dip-column is-4">
																	<button
																		className={`button is-game-setter ${ticket.isLuckyDip ? 'is-active' : '' }`}
																		onClick={() => handleLuckyDip(index)}
																	>LUCKY DIP</button>
																</div>
																{
																	ticket.confirmedNumbers.length !== game.sets[0].n
																	&& <div className="column">
																		<span className="or">- or -</span>
																		<button
																			className="button is-game-setter js-modal-trigger mt-0 mb-0 ml-0 mr-3"
																			data-target="modal-choose-numbers"
																			onClick={() => handleChooseNumbersModal(index)}
																		>CHOOSE NUMBERS</button>
																	</div>
																}
																{
																	ticket.confirmedNumbers.length === game.sets[0].n
																	&& <div className="column is-mobile-screen-adaptive">
																		{
																			ticket.isLuckyDip
																			&& <>
																				{[...Array(game.sets[0].n).keys()].map((n) => (
																					<p
																						key={n}
																						className="selected-number mb-0"
																					>LD</p>
																				))}
																			</>
																		}
																		{
																			!ticket.isLuckyDip
																			&& <>
																				{ticket.confirmedNumbers.map((number, indexTwo) => (
																					<p
																						key={indexTwo}
																						className="selected-number mb-0"
																					>{number}</p>
																				))}
																			</>
																		}
																	</div>
																}
															</div>
															{
																ticket.confirmedNumbers.length === game.sets[0].n
																&& <div className="columns is-flex is-vcentered mb-0">
																	<div className="column choose-lucky-dip-column is-4"></div>
																	<div className="column pt-0">
																		<button
																			onClick={() => handleChooseNumbersModal(index)}
																			className={`edit-numbers ml-0 ${ticket.isLuckyDip ? 'is-inactive' : 'link-standard'}`}
																		>Edit</button>
																		<button
																			className="delete-numbers link-standard"
																			onClick={() => handleDelete(index)}
																		>Delete</button>
																	</div>
																</div>
															}
														</span>
													))}
												</div>

												<div className="content">
													<div className="columns">
														<div className="column overflow-auto is-4">
															{
																form.settings.days
																&& (
																	<>
																		<div className="content">
																			<h3 className="title is-size-6 mb-2">Which days?</h3>
																			{form.settings.days.map((gameDay) => (
																				<div
																					key={gameDay.index}
																					className="field float-left mr-2"
																				>
																					<input
																						id={`switchDay${gameDay.label}`}
																						type="checkbox"
																						name={`switchDay${gameDay.label}`}
																						className="switch is-rounded is-info"
																						disabled={`${ gameDay.enabled ? '' : 'disabled' }`}
																						checked={`${gameDay.checked ? 'checked' : '' }`}
																						onChange={(e) => handleDayClick(gameDay.index, e.target.checked)}
																					/>
																					<label htmlFor={`switchDay${gameDay.label}`}>{gameDay.label}</label>
																				</div>
																			))}
																		</div>

																		{
																			isAtLeastOneGameDayDisabled()
																			&& <div className="content is-size-7">
																				<br/><br/><br/><br/><br/>
																				<p>You cannot choose some of the days because they've already passed.</p>
																			</div>
																		}
																	</>
																)
															}
														</div>
														<div className="column is-5">
															{
																game
																&& (
																	<>
																		<h3 className="title is-size-6 mb-2">For how many weeks?</h3>
																		<div className="control">
																			{[...Array(game.advanced_ticket_purchase.max_weeks_ahead).keys()].map((weekNumber) => (
																				<span key={weekNumber}>
																					<input
																						className="is-checkradio is-info"
																						id={`week-${ weekNumber + 1 }`}
																						type="radio"
																						name="week"
																						checked={`${ ( weekNumber +1 ) === form.data.week ? 'checked' : '' }`}
																						onChange={() => handleWeekChange(weekNumber + 1)}
																					/>
																					<label htmlFor={`week-${ weekNumber + 1 }`}>{ weekNumber + 1 }</label>
																				</span>
																			))}
																		</div>
																	</>
																)
															}
														</div>
														<div className="column has-text-centered">
															<div className="content">
																<p>
																	Total: <span
																		className="has-text-weight-bold"
																	>
																		<FiatFormatter
																			type="TICKET"
																			data={
																				{
																					code: userContext.currency_code,
																					symbol: game.ticket_price.fiat[userContext.currency_code].symbol,
																					position_of_symbol: game.ticket_price.fiat[userContext.currency_code].position_of_symbol,
																					value: (
																						form.data.ticketsCount * form.data.daysCount * form.data.week - game.tickets_won > 0
																						? form.data.ticketsCount * form.data.daysCount * form.data.week * game.ticket_price.fiat[userContext.currency_code].value - game.tickets_won * game.ticket_price.fiat[userContext.currency_code].value
																						: 0
																					)
																				}
																			}
																		/>
																	</span>
																</p>
																<button
																	id="play-button"
																	onClick={handlePlay}
																	className={`button is-rainbow-${game.color} is-rounded ${form.data.ticketsCount * form.data.daysCount * form.data.week > 0 ? '' : 'is-disabled' }`}
																>
																	Play
																	{
																		form.settings.isSwitchingScreen
																		&& <span className="cl-button-loader"></span>
																	}
																</button>
																{/* <button className="button is-primary is-disabled is-rounded" id="play-button">Play</button> */}
															</div>
															{
																currentUTCUnixTimestamp >= game.next_draw.ticket_sell_closes_at
																&& currentUTCUnixTimestamp < game.next_draw.start_at
																&& 	<div className="content has-text-primary is-size-7">
																	<p>Tickets purchased {Math.ceil((game.next_draw.start_at - game.next_draw.ticket_sell_closes_at)/60)} min. before the draw are going to be used for the next draw</p>
																</div>
															}
															{
																game.tickets_won > 0
																&& <div className="content has-text-secondary is-size-7">
																	<p>You have {game.tickets_won} free tickets won during previous draws. They will be deducted from the final price.</p>
																</div>
															}
														</div>
													</div>
												</div>
											</div>

											<div className={`card-content ${ form.settings.screen === 2 ? 'fadeIn' : 'fadeOut' }`} id="game-configuration-final">
												<div className="columns">
													<div className="column"></div>
													<div className="column is-10">
														{
															1 !== 1
															&& <div className="content">
																<div className="field">
																	<label className="label">BTC address</label>
																	<div className="control has-icons-left has-icons-right">
																		<input
																			className={`input ${
																				!form.state.isBtcAddressValid
																				&& 'is-danger'
																			}`}
																			type="text"
																			placeholder=""
																			onChange={(e) => handleBtcAddressChange(e.target.value)}
																		/>
																		<span className="icon is-small is-left">
																			<FaBtc />
																		</span>
																		{
																			form.state.isBtcAddressValid
																			&& <span className="icon is-small is-right">
																				<FaCheck/>
																			</span>
																		}
																		{
																			!form.state.isBtcAddressValid
																			&& <span className="icon is-small is-right">
																				<FaExclamationTriangle/>
																			</span>
																		}
																	</div>
																	{
																		form.data.btcAddress !== ''
																		&& (
																			<>
																				{
																					!form.state.isBtcAddressValid
																					&& <p className="help is-danger">Invalid BTC (Bitcoin) address.</p>
																				}
																			</>
																		)
																	}
																	<p className="help">This is where we'll pay you won prizes and jackpots (can be changed later)</p>
																</div>
															</div>
														}

														<div className="content has-text-centered">
															<div className="notification is-warning">
																CoinLottery is currently in test mode and only accepts tBTC on Testnet for payments. Do not send real bitcoin!
															</div>
														</div>
														{
															size.width <= mobileWindowMaxSize
															&& <>
																<div className="content has-text-centered">
																	<img
																		style={{ width: "300px" }}
																		src="https://app.opennode.com/pay-with-bitcoin.svg"
																		alt="OpenNode Bitcoin payment"
																		onClick={initiatePayment}
																	/>
																</div>
																<div className="content has-text-centered">
																	<a
																		target="_blank"
																		className="button is-white-with-gray-text is-rounded"
																		href="https://help.opennode.com/en/articles/4404445-what-wallet-should-i-use"
																	>How to pay&nbsp;<BsFillQuestionCircleFill/></a>
																</div>
															</>
														}
														{
															size.width > mobileWindowMaxSize
															&& <div className="content has-text-centered">
																<div className="columns is-mobile is-flex has-text-centered is-vcentered mb-0">
																	<div className="column is-2"></div>
																	<div className="column">
																		<img
																			style={{ width: "300px" }}
																			src="https://app.opennode.com/pay-with-bitcoin.svg"
																			alt="OpenNode Bitcoin payment"
																			onClick={initiatePayment}
																		/>
																	</div>
																	<div className="column">
																		<a
																			target="_blank"
																			className="button is-white-with-gray-text is-rounded"
																			href="https://help.opennode.com/en/articles/4404445-what-wallet-should-i-use"
																		>How to pay&nbsp;<BsFillQuestionCircleFill/></a>
																	</div>
																	<div className="column is-2"></div>
																</div>
															</div>
														}
														{
															(
																form.state.isInitiatingPayment
																|| form.state.api.serverErrorInitiatingPayment
															) && <>
																<div className="content has-text-centered mt-6">
																	{
																		form.state.isInitiatingPayment
																		&& <>
																			<br/>
																			<span className="cl-button-loader-primary"></span>
																		</>
																	}

																	{
																		form.state.api.serverErrorInitiatingPayment
																		&& <ErrorBlock message="An unexpected error occurred while initiating the payment. Please try again later."/>
																	}
																</div>
															</>
														}
													</div>
													<div className="column"></div>
												</div>

												<div className="content">
													<div className="columns is-mobile is-flex has-text-centered is-vcentered mb-0">
														<div className="column has-text-center">
															<button
																onClick={handleBackToScreenOne}
																id="game-configuration-final-back"
																className="link-standard is-size-6"
															>Back</button>
														</div>
													</div>
												</div>
											</div>

											<div className={`card-content ${ form.settings.screen === 3 ? 'fadeIn' : 'fadeOut' }`} id="game-payment-status">
												<div className="columns">
													<div className="column"></div>
													<div className="column is-10">
														<div className="content has-text-centered">
															{
																true === form.settings.isPaid
																&& <h2>Payment confirmed!</h2>
															}
															{
																false === form.settings.isPaid
																&& <>
																	<h2>Transaction still processing</h2>
																	<p>Bitcoin payments take 10-30 minutes to confirm, depending on network load. Check back for your purchased tickets later.</p>
																</>
															}
															{
																null === form.settings.isPaid
																&&
																	<h2>
																		Checking payment status
																		<span className="loader-dot">.</span>
																		<span className="loader-dot">.</span>
																		<span className="loader-dot">.</span>
																	</h2>

															}
														</div>
													</div>
													<div className="column"></div>
												</div>

												{
													true === form.settings.isPaid
													&& <div className="content">
														<div className="columns is-mobile is-flex has-text-centered is-vcentered mb-0">
															<div className="column has-text-center">
																<Link
																	to={`/game/${game.key}`}
																	className={`button is-rounded is-rainbow-${game.color} is-rounded mr-2`}
																	onClick={handlePlayMore}
																>Play More</Link>
																<Link
																	to={`/game/${game.key}/results`}
																	className="button is-rounded"
																>Check Your Numbers</Link>
															</div>
														</div>
													</div>
												}
												{
													false === form.settings.isPaid
													&& <div className="content">
														<div className="columns is-mobile is-flex has-text-centered is-vcentered mb-0">
															<div className="column has-text-center">
																<Link
																	to={`/game/${game.key}`}
																	className={`button is-rounded is-rainbow-${game.color} is-rounded mr-2`}
																	onClick={handlePlayMore}
																>Play More</Link>
																<Link
																	to={`/game/${game.key}/results`}
																	className="button is-rounded"
																>Check Purchased Tickets</Link>
															</div>
														</div>
													</div>
												}
											</div>

											<div className="card-content" id="payment-configuration" style={{ display: "" }}>
												<div className="content">
													<div className="columns is-mobile mb-0">
														<div className="column has-text-left is-size-7 mb-0 pb-0">Step</div>
														<div className="column has-text-right is-size-7 mb-0 pb-0"><span id="payment-configuration-progress-number">{form.settings.screen}</span> of 3</div>
													</div>
													<progress
														className={`progress is-small is-${game.color}`}
														value={(form.settings.screen * 100) / 3}
														max="100"
														id="payment-configuration-progress"
													></progress>
												</div>
												{/* <div className="content">

												</div>
												<div className="content">
													<div className="columns is-mobile is-flex has-text-centered is-vcentered mb-0">
														<div className="column has-text-right">
															<Link to="#" className="link-standard is-size-6" id="payment-configuration-prev">Previous</Link>
														</div>
														<div className="column has-text-left">
															<button className="button is-secondary is-rounded" id="payment-configuration-next">NEXT</button>
														</div>
													</div>
												</div> */}
											</div>
										</>
									}
								</div>
							</div>
							: <div className="column mt-6 pl-5 pr-5">
								<div className="content">
									<h1 className="title is-size-2 has-text-white mt-3">
										The game is not available at the moment
									</h1>
								</div>
							</div>
						}
					</div>
				</div>
			</section>

			<div className="container">
				<section className="section">
					<div className="content">
						<h1
							className={`title is-flex is-justify-content-center has-${game.color}`}
							id="see-what-you-can-win"
						>SEE WHAT YOU CAN WIN</h1>

						<ListOfPrizes game={game} />
					</div>
				</section>
				<section className="section pt-0">
					<div className="content">
						<div className="columns">
							<div className="column is-3">
								<Link
									to={`/game/${key}/results`}
									className={`button is-rainbow-${game.color} is-rounded`}
								>CHECK RESULTS</Link>
							</div>
							<div className="column is-2">
								<Link to="/terms" className="link-standard">Account Terms & Rules</Link>
							</div>
							<div className="column">
								<Link to="/how-to-claim" className="link-standard">How to claim?</Link>
							</div>
						</div>
					</div>
				</section>
			</div>





			<div
				id="modal-how-to-play"
				className={`modal ${form.settings.modals.isModalHowToPlayOpened ? 'is-active' : '' }`}
			>
				<div
					className="modal-background"
					onClick={handleCloseModals}
				></div>

				<div className="modal-content">
					<div className="box">
						<div className="content">
							<div className={`how-to-play-box ${form.settings.howToPlayScreen === 1 ? '' : 'is-hidden' }`}>
								<img src={howToPlay1} alt="How to Play, Step 1: Click Lucky Dip" className="has-border"/>
								<p className="is-size-6">Take your chances with Lucky Dip to play randomly selected numbers OR</p>
								<br />
							</div>

							<div className={`how-to-play-box ${form.settings.howToPlayScreen === 2 ? '' : 'is-hidden' }`}>
								<img src={howToPlay2} alt="How to Play, Step 2: Click choose numbers"/>
								<p className="is-size-6">Click to enter your own {game.sets[0].n} numbers.</p>
								<br />
							</div>

							<div className={`how-to-play-box ${form.settings.howToPlayScreen === 3 ? '' : 'is-hidden' }`}>
								<img src={howToPlay3} alt="How to Play, Step 3: Choose Lucky Dip or choose your own numbers"/>
								<p className="is-size-6">More lines and playslips will be automatically added as you fill them up, for those who want to play more.</p>
							</div>

							<div className={`how-to-play-box ${form.settings.howToPlayScreen === 4 ? '' : 'is-hidden' }`}>
								<img src={howToPlay4} alt="How to Play, Step 4: Choose which day"/>
								<p className="is-size-6">Now choose which draw days you want to play.</p>
								<br />
							</div>

							<div className={`how-to-play-box ${form.settings.howToPlayScreen === 5 ? '' : 'is-hidden' }`}>
								<img src={howToPlay5} alt="How to Play, Step 5: Choose for how many weeks"/>
								<p className="is-size-6">Select '1' for this week's draw! You can also choose to play for up to {game.advanced_ticket_purchase.max_weeks_ahead} weeks.</p>
							</div>

							<div className={`how-to-play-box ${form.settings.howToPlayScreen === 6 ? '' : 'is-hidden' }`}>
								<img src={howToPlay6} alt="How to Play, Step 6: Play"/>
								<p className="is-size-6">After you hit play, you will be asked to sign in before before confirming your selection. Then you're good to go.</p>
							</div>
						</div>
						<div className="content">
							<div className="columns is-mobile mb-0">
								<div className="column has-text-left is-size-7 mb-0 pb-0">Steps</div>
								<div className="column has-text-right is-size-7 mb-0 pb-0">
									{form.settings.howToPlayScreen} of {form.settings.howToPlayScreenMax}
								</div>
							</div>
							<progress
								className={`progress is-small is-${game.color}`}
								value={form.settings.howToPlayScreen * 100 / form.settings.howToPlayScreenMax}
								max="100"
							></progress>
						</div>
						<div className="content">
							<div className="columns is-mobile is-flex has-text-centered is-vcentered mb-0">
								<div className="column has-text-right">
									{
										form.settings.howToPlayScreen > 1
										&& <button
											className="link-standard is-size-6"
											onClick={handleHowToPlayPrev}
										>Previous</button>
									}
								</div>
								<div className="column has-text-left">
									{
										form.settings.howToPlayScreen < form.settings.howToPlayScreenMax
										&& <button
											className="button is-secondary is-rounded"
											onClick={handleHowToPlayNext}
										>NEXT</button>
									}
									{
										form.settings.howToPlayScreen === form.settings.howToPlayScreenMax
										&& <button
											className="button is-secondary is-rounded"
											onClick={handleCloseModals}
										>GOT IT</button>
									}
								</div>
							</div>
						</div>
					</div>
				</div>

				<button
					className="modal-close is-large"
					aria-label="close"
					onClick={handleCloseModals}
				></button>
			</div>

			<div
				id="modal-choose-numbers"
				className={`modal ${form.settings.modals.isModalChooseNumbersOpened ? 'is-active' : ''}`}
			>
				<div
					className="modal-background"
					onClick={handleCloseModals}
				></div>

				<div className="modal-content">
					<div className="box">
						<div className="content">
							<h2 className="title has-text-centered is-size-4">SELECT NUMBERS</h2>
						</div>

						{
							chooseNumbersIndex !== false
							&& <>
								<div className="content">
									<div className="columns is-mobile mb-0">
										<div className="column has-text-left is-size-7 mb-0 pb-0">Numbers</div>
										<div
											className="column has-text-right is-size-7 mb-0 pb-0"
										>
											{form.data.tickets[chooseNumbersIndex].numbers.length} of {game.sets[0].n}
										</div>
									</div>
									<progress
										id="choose-number-progress"
										className={`progress is-small is-${game.color}`}
										value={(form.data.tickets[chooseNumbersIndex].numbers.length * 100) / game.sets[0].n}
										max="100"
									>{(form.data.tickets[chooseNumbersIndex].numbers.length * 100) / game.sets[0].n}%</progress>
								</div>

								<div className="content number-picker-container is-mobile-screen-adaptive">
									{[...Array(game.sets[0].N).keys()].map((number) => (
										<p
											key={number}
											className={`${form.data.tickets[chooseNumbersIndex].numbers.includes(number + 1) ? '' : 'none-'}selected-number`}
											onClick={() => handleNumberClick(number + 1)}
										>{number + 1}</p>
									))}
								</div>

								<div className="content has-text-centered">
									<button
										className="link-standard is-size-7"
										onClick={clearTicketNumbers}
									>Clear All</button>
									<br />
									<button
										onClick={handleConfirm}
										className={`button is-secondary is-rounded ${form.data.tickets[chooseNumbersIndex].numbers.length === game.sets[0].n ? '' : 'is-disabled'}`}
									>CONFIRM</button>
								</div>
							</>
						}
					</div>
				</div>

				<button
					className="modal-close is-large"
					aria-label="close"
					onClick={handleCloseModals}
				></button>
			</div>
			<div className="block my-6"></div>
		</>
    )
}

export default Game