const containsOnlyNumbers = (str) => {
    return /^[0-9]+$/.test(str);
}

export const getValidPage = (page) => {
    if (page === undefined) return 1;
    if (!containsOnlyNumbers(page)) return false;

    page = parseInt(page);
    if (page <= 0) return false;

    return page;
}