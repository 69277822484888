const TermsAndRules = () => {
  	return (
		<div className="container">
			<section className="section">
				<h1 className="title">Terms and rules</h1>
				<p>CoinLottery is a cryptocurrency-based lottery platform that operates at coinlottery.bet (the "Website"). By accessing or using the Website, you agree to be bound by these Terms and Conditions ("Terms"). Please review these Terms carefully before using the Website. If you do not agree to these Terms, you must not access or use the Website.</p>

				<h2 className="subtitle">Eligibility</h2>
				<p>By accessing and using the Website, you represent and warrant that you are at least 18 years old and have the legal capacity to enter into a binding agreement. The Website is not intended for use by anyone under the age of 18. If you are under the age of 18, you must not use the Website.</p>

				<h2 className="subtitle">Services</h2>
				<p>The Website provides an online platform for users to participate in a cryptocurrency-based lottery. The platform operates by accepting Bitcoin as payment for lottery entries, which are then entered into a drawing for the chance to win a prize.</p>

				<h2 className="subtitle">Acceptable Use</h2>
				<p>You agree to use the Website only for lawful purposes. You must not use the Website in any way that breaches any applicable local, national, or international law or regulation, or that is fraudulent, or has any unlawful or fraudulent purpose or effect. You must not use the Website in any way that could damage, disable, overburden, or impair the Website or its servers or networks, or interfere with any other person's use and enjoyment of the Website.</p>

				<h2 className="subtitle">Intellectual Property</h2>
				<p>The Website, its content, and its software are protected by intellectual property rights, including copyright and trademark rights. The Website and its content may not be copied, modified, or otherwise used without our express written consent. The software may not be reverse-engineered, disassembled, or otherwise used without our express written consent.</p>

				<h2 className="subtitle">Payments</h2>
				<p>Payments for entries into the cryptocurrency-based lottery must be made using Bitcoin. Payments are processed through a secure payment gateway. CoinLottery is not responsible for any errors or issues that occur during payment processing.</p>

				<h2 className="subtitle">Drawings and Prizes</h2>
				<p>Drawings for the cryptocurrency-based lottery will be held at regular intervals, as specified on the Website. The prize pool for each drawing will be determined by the number of entries received for that drawing. Prizes will be awarded in Bitcoin.</p>

				<h2 className="subtitle">Taxes</h2>
				<p>Winners are responsible for paying any taxes that may be owed on their prize winnings. CoinLottery is not responsible for determining the amount of taxes owed or for collecting taxes on behalf of the winner.</p>

				<h2 className="subtitle">Limitation of Liability</h2>
				<p>CoinLottery will not be liable to you for any loss or damage arising from your use of the Website or from your participation in the cryptocurrency-based lottery. This includes, but is not limited to, loss of data, loss of profits, loss of business, loss of opportunity, or loss of goodwill.</p>

				<h2 className="subtitle">Indemnification</h2>
				<p>You agree to indemnify, defend, and hold harmless CoinLottery, its affiliates, and its and their respective officers, directors, employees, agents, licensors, and suppliers, from and against all claims, losses, liabilities, expenses, damages, and costs, including reasonable attorneys' fees, arising from or relating to your use of the Website or your participation in the cryptocurrency-based lottery.</p>

				<h2 className="subtitle">Termination</h2>
				<p>CoinLottery may terminate your use of the Website at any time, with or without cause, and with or without notice. Upon termination, you must cease all use of the Website.</p>

				<h2 className="subtitle">Dispute Resolution</h2>
				<p>Any dispute arising from these Terms or your use of the Website will be resolved through binding arbitration in accordance with the rules of the American Arbitration Association.</p>

				<h2 className="subtitle">Changes</h2>
				<p>These Terms and Conditions were last updated 3th February 2023.</p>
			</section>
			<div className="block my-6"></div>
		</div>
  	)
}

export default TermsAndRules