import { useParams, useNavigate } from 'react-router-dom';

import FormTwoFA from './Forms/FormTwoFA';
import { useWindowSize } from '../../../hooks';
import { getValidPage } from '../../../services';
import ListOfActiveSessions from './Lists/ListOfActiveSessions';
import FormSecurityQuestions from './Forms/FormSecurityQuestions';
import { MenuSettingsDesktop, MenuSettingsMobile } from '../../../components';


const Security = () => {
	const navigate = useNavigate();

	let { page } = useParams();
	page = getValidPage(page);
	if (!page) navigate.push('/page-not-found');

	const mobileWindowMaxSize = 1023;
	const size = useWindowSize();

  	return (
		<>
			{
				size.width > mobileWindowMaxSize
				&& <div className="container is-hidden-touch">
					<section className="section">
						<div className="columns">
							<div className="column is-one-fifth">
								<MenuSettingsDesktop/>
							</div>
							<div className="column">
								<article className="panel is-primary">
									<p className="panel-heading">Security</p>
									<div className="content p-3">
										<h2 className="title is-size-4">Two-Factor Authentication</h2>
										<FormTwoFA />
									</div>

									<hr />

									<div className="content p-3">
										<FormSecurityQuestions />
									</div>

									<hr />

									<div className="content p-3">
										<h2 className="title is-size-4">Active Sessions</h2>
										<ListOfActiveSessions currentPage={page} />
									</div>
								</article>
							</div>
						</div>
					</section>
				</div>
			}

			{
				size.width <= mobileWindowMaxSize
				&& <div className="container is-fixed-top is-hidden-desktop">
					<MenuSettingsMobile />
					<section className="section pt-0">
						<div className="content">
							<h2 className="title is-size-4">Two-Factor Authentication</h2>
							<FormTwoFA />
						</div>

						<hr />

						<div className="content">
							<FormSecurityQuestions />
						</div>

						<hr />

						<div className="content">
							<h2 className="title is-size-4">Active Sessions</h2>
							<ListOfActiveSessions currentPage={page} />
						</div>
					</section>
				</div>
			}
			<div className="block my-6"></div>
		</>
  	)
}

export default Security