import { Link } from 'react-router-dom';

import { GameStartsAt, FiatFormatter } from '../../components';
import { useUserContext, useGamesContext } from '../../context';


const ListOfGames = ({ location }) => {
    const userContext = useUserContext();
    const gameContext = useGamesContext();

    let jackpotClassName = 'jackpot-size';
    if (location === 'nav') jackpotClassName = 'jackpot-size-nav';

    return (
        <>
            {
				gameContext.gameGroups.map((gameGroup, index) => (
                    <div key={`gameGroup-${index}`} className="columns">
                        {
                            gameGroup.map((game) => (
                                <Link to={`/game/${game.key}`} key={game.id} className="column p-1">
                                    <div className={`lotto-card lotto-card-${game.color} is-clickable border-radius-10 has-text-white px-3 py-3`}>
                                        <div className="when is-size-6 border-white-bottom">
                                            <GameStartsAt
                                                startAt={game.next_draw.start_at}
                                                details={false}
                                            />
                                        </div>
                                        <div className="game-name has-text-weight-bold is-size-5">
                                            {game.name}
                                        </div>
                                        <div className={`${jackpotClassName} has-text-centered is-size-2 has-text-weight-bold`}>
                                            <FiatFormatter
                                                type="JACKPOT"
                                                data={game.jackpot.fiat[userContext.currency_code]}
                                            />
                                        </div>
                                    </div>
                                </Link>
                            ))
                        }
                    </div>
				))
			}
        </>
    );
}

export default ListOfGames;