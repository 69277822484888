import { Link } from 'react-router-dom';
import { useState, useEffect } from "react";
import { FaMinus, FaPlus, FaFlag } from 'react-icons/fa';

import { useUserContext, useGamesContext } from '../context';
import { ListOfGames, GameStartsAt, FiatFormatter } from '../components';

import t3nMediaImg from '../assets/img/featured-in/t3n-logo.png';
import bitcionMediaImg from '../assets/img/featured-in/Bitcion.png';
import casinoOrgMediaImg from '../assets/img/featured-in/Casino_org.png';
import cointelegraphMediaImg from '../assets/img/featured-in/cointelegraph.png';


const Home = () => {
	const userContext = useUserContext();

	const currentDatetimeUTC = Math.ceil(new Date().getTime()/1000)
	const displayWatchDrawsXSecondsBeforeGameStart = 3600;

	const [game, setGame] = useState(false);
	const gameContext = useGamesContext();

	useEffect(() => {
		if (gameContext.games) {
			let g = false;
			let ticketSellClosestIn = 100000000000000000000000000000000000;

			for (let i = 0; i < gameContext.games.length; i++) {
				if (
					gameContext.games[i].next_draw.ticket_sell_closes_at > currentDatetimeUTC
					&& gameContext.games[i].next_draw.ticket_sell_closes_at - currentDatetimeUTC < ticketSellClosestIn
				) {
					ticketSellClosestIn = gameContext.games[i].next_draw.ticket_sell_closes_at - currentDatetimeUTC;
					g = gameContext.games[i];
				}
			}

			if (false === g) g = gameContext.games[0];
			setGame(g);
		}
	}, [gameContext])

	const styles = {
		header_when: {
			borderBottom: "1px solid #333333 !important"
		}
	}

  	return (
		<>
			<section className="section has-background-light m-0 p-0">
				<div className="columns">
					<div className="column is-1 has-background-warning">

					</div>
					<div className="column has-background-warning p-6">
						{
							game &&
							<span>
								<div
									style={styles.header_when}
									className="is-size-6 border-white-bottom"
								>
									<GameStartsAt
										startAt={game.next_draw.start_at}
										details={false}
									/>
								</div>
								<div className="has-text-weight-bold is-size-3">
									{game.name}
								</div>
								<div className="jackpot-size has-text-centered is-size-1 has-text-weight-bold">
									<FiatFormatter
										type="JACKPOT"
										data={game.jackpot.fiat[userContext.currency_code]}
									/>
								</div>
								<div className="jackpot-subtitle has-text-centered is-size-2 has-text-weight-bold">
									JACKPOT
								</div>
								{
									game.next_draw.ticket_sell_closes_at > currentDatetimeUTC
									&& <Link
										to={`/game/${game.key}`}
										className="button is-secondary is-rounded mr-3 mt-3"
									>PLAY FOR <FiatFormatter
										type="TICKET"
										data={game.ticket_price.fiat[userContext.currency_code]}
									/>
									</Link>
								}
								{
									game.next_draw.end_at > currentDatetimeUTC
									&& displayWatchDrawsXSecondsBeforeGameStart > game.next_draw.start_at - currentDatetimeUTC
									&& <Link
										to={`/game/${game.key}/watch`}
										className="button is-transparent-with-gray-color is-rounded mt-3"
									>WATCH THE DRAWS</Link>
								}
								{
									game.next_draw.ticket_sell_closes_at <= currentDatetimeUTC
									&& game.next_draw.end_at <= currentDatetimeUTC
									&& <Link
										to={`/game/${game.key}/draws`}
										className="button is-secondary is-rounded mr-3 mt-3"
									>CHECK DRAW HISTORY</Link>
								}
							</span>
						}

						<p className="is-size-5 mt-6">Crypto lottery that can be played from anywhere, prizes sent immediately and received within an hour.</p>
					</div>
					<div className="column has-background-primary has-text-white p-6">
						<div className="has-text-weight-bold is-size-3 mt-6">
							CHECK YOUR RESULTS
						</div>
						<p className="is-size-5">Did you win big?</p>
						{
							game
							&& <Link to={`/game/${game.key}/results`} className="button is-primary has-border is-rounded mt-6">CHECK RESULTS</Link>
						}
					</div>
					<div className="column is-1 has-background-primary">

					</div>
				</div>
			</section>
			<div className="container">
				<section className="section is-hidden-touch">
					<div className="content">
						<h1 className="title is-size-1">All games</h1>
						<ListOfGames />
					</div>
				</section>

				{
					1 !== 1
					&& <section className="section">
						<div className="content is-flex is-justify-content-center mb-0">
							<h1 className="title is-size-1 mb-0">Featured In</h1>
						</div>
						<div className="content mt-6">
							<div className="columns">
								<div className="column">
									<a
										href="#"
										target="_blank"
										rel="noreferrer nofollow"
									>
										<img src={bitcionMediaImg} alt="Bitcion media" className="mt-5 mr-6" width="220"/>
									</a>
									<a
										href="#"
										target="_blank"
										rel="noreferrer nofollow"
									>
										<img src={cointelegraphMediaImg} alt="cointelegraoh" className="mt-5 mr-6" width="220"/>
									</a>
									<a
										href="#"
										target="_blank"
										rel="noreferrer nofollow"
									>
										<img src={casinoOrgMediaImg} alt="casino.org" className="mt-5 mr-6" width="220"/>
									</a>
									<a
										href="#"
										target="_blank"
										rel="noreferrer nofollow"
									>
										<img src={t3nMediaImg} alt="t3n" className="mt-5" width="220"/>
									</a>
								</div>
							</div>
						</div>
					</section>
				}

				{
					1 !== 1
					&& <hr />
				}

				<section className="section">
					<div className="content is-flex is-justify-content-center mb-0">
						<h1 className="title is-size-1 mb-0">Why?</h1>
					</div>
					<div className="content">
						<div className="columns">
							<div className="column">
								<div className="content">
									<h2 className="title is-size-3 mt-6">Fiat (Classic) Lottery</h2>
									<h3 className="title is-size-5 mb-0">
										<FaMinus className="has-text-primary mr-1"/>
										Prize Pool Up To 60%
									</h3>
									<p>
										The majority of the funds that the lottery brings in — usually around 50 to 60% — is distributed to the winners. This includes big jackpots and the smaller prizes for matching fewer lottery numbers.
									</p>

									<h3 className="title is-size-5 mb-0">
										<FaMinus className="has-text-primary mr-1"/>
										Low Odds Of Winning 1 In 300 Million
									</h3>
									<p>
										The odds of winning the Powerball jackpot are 1 in 292.2 million. The odds of winning the Mega Millions jackpot are even lower, 1 in 302.6 million. Even if you buy two tickets, doubling your odds of winning, they are still extremely low.
									</p>

									<h3 className="title is-size-5 mb-0">
										<FaMinus className="has-text-primary mr-1"/>
										Claiming Is Slow, Up To 180 Days In The USA
									</h3>
									<p>
										Claiming a winning lottery ticket from Mega Millions or Powerball takes at least 180 days in the U.S. In the UK that process takes from a few days to two weeks.
									</p>

									<h3 className="title is-size-5 mb-0">
										<FaMinus className="has-text-primary mr-1"/>
										1 Million Is Not 1 Million In A Year
									</h3>
									<p>
										Big lottery prizes are usually paid in portions. But because of the inflation 1 000 000 is worth much less in a year.
									</p>

									<h3 className="title is-size-5 mb-0">
										<FaMinus className="has-text-primary mr-1"/>
										Privacy Not Guaranteed
									</h3>
									<p>
										Your privacy is not guaranteed.
									</p>

									<h3 className="title is-size-5 mb-0">
										<FaMinus className="has-text-primary mr-1"/>
										Region Based
									</h3>
									<p>
										The classic fiat lottery is region based meaning that you can't play a UK based lottery from the USA.
									</p>
								</div>
							</div>
							<div className="column">
								<div className="content">
									<h2 className="title is-size-3 mt-6">Coin Lottery</h2>
									<h3 className="title is-size-5 mb-0">
										<FaPlus className="has-text-secondary mr-1"/>
										Prize Pool 80%
									</h3>
									<p>
										CoinLottery is based on blockchain technology allowing automation of most of the business processes via smart contracts and reducing the running costs in favour to increasing the prize pool.
									</p>

									<h3 className="title is-size-5 mb-0">
										<FaPlus className="has-text-secondary mr-1"/>
										Higher Odds Of Winning
									</h3>
									<p>
										By reducing the running costs of the business - and increasing the prize pool - we are able to increase the odds of winning.
									</p>

									<h3 className="title is-size-5 mb-0">
										<FaPlus className="has-text-secondary mr-1"/>
										Claiming Is Painless, Within 1 Hour
									</h3>
									<p>
										CoinLottery transacts the prizes immediately after drawing the numbers. Meaning you get your winnings right away!
									</p>

									<h3 className="title is-size-5 mb-0">
										<FaPlus className="has-text-secondary mr-1"/>
										1 Million Today Is 1 Million Today
									</h3>
									<p>
										The winner receives the prize within an hour depending on the busyness of the blockchain network.
									</p>

									<h3 className="title is-size-5 mb-0">
										<FaPlus className="has-text-secondary mr-1"/>
										Play From Anywhere
									</h3>
									<p>
										CoinLottery is not region based. It can be played from anywhere with Internet connection.
									</p>

									{
										1 !== 1
										&& <a
											href="#"
											className="button is-rounded"
											rel="noreferrer nofollow"
										>Read the White Paper</a>
									}

									{
										game &&
										<Link to={`/game/${game.key}`} className="button is-primary is-rounded ml-3">Play</Link>
									}
								</div>
							</div>
						</div>
					</div>
				</section>

				{
					1 !== 1
					&& <hr />
				}

				{
					1 !== 1
					&& <section className="section">
						<div className="content is-flex is-justify-content-center mb-0">
							<h1 className="title is-size-1">Roadmap</h1>
						</div>
						<div className="content">
							<div className="timeline is-centered">
								<header className="timeline-header">
								<span className="tag is-medium is-primary">2022</span>
								</header>

								<div className="timeline-item is-primary">
								<div className="timeline-marker is-primary"></div>
								<div className="timeline-content">
									<p className="heading">Feb 2022</p>
									<p>
										▪ Complete mathematical model, technical and product specifications<br />
										▪ Research, design marketing strategy & business plan<br />
										▪ Develop a white paper for the community to learn more about CoinLottery team
									</p>
								</div>
								</div>
								<div className="timeline-item is-primary">
									<div className="timeline-marker is-primary"></div>
									<div className="timeline-content">
									<p className="heading">May 2022</p>
									<p>
										Develop a web and mobile app based on Bitcoin Lightning Network ▪<br />
										Deploy the web and the mobile apps ▪
									</p>
									</div>
								</div>
								<div className="timeline-item is-warning">
									<div className="timeline-marker is-warning is-image is-32x32">
										<img src="https://bulma.io/images/placeholders/32x32.png" alt="timeline" />
									</div>
									<div className="timeline-content">
									<p className="heading">Aug 2022</p>
									<p>
										▫ Identify investors for staking<br />
										▫ Identify charity organisations and create partnerships<br />
										▫ Identify businesses and potential celebrity endorsements and partnerships<br />
										▫ Generate global press releases about CoinLottery charity organization and our community efforts to donate<br />
										▫ Develop Discord & social media communities<br />
										▫ Run CoinLottery
									</p>
									</div>
								</div>



								<header className="timeline-header">
								<span className="tag is-primary">2023</span>
								</header>

								<div className="timeline-item is-danger">
								<div className="timeline-marker is-danger is-icon">
									<FaFlag />
								</div>
								<div className="timeline-content">
									<p className="heading">Jan 2023</p>
									<p>
										Make the first donation ▫<br />
										Implement KYC/AML ▫<br />
										Implement payments via debit and credit cards ▫
									</p>
								</div>
								</div>
								<div className="timeline-item is-danger">
									<div className="timeline-marker is-danger"></div>
									<div className="timeline-content">
									<p className="heading">Feb 2023</p>
									<p>
										▫ Implement new lottery games based on Ethereum blockchain and layer 2 solutions<br />
										▫ Certify our smart contract with an independent reputable audit service
									</p>
									</div>
								</div>
								<div className="timeline-item is-danger">
									<div className="timeline-marker is-danger"></div>
									<div className="timeline-content">
									<p className="heading">Jun 2023</p>
									<p>
										Increase outreach ▫<br />
										Create CoinLottery merchandise store to help support the charity cause ▫<br />
										Show dedication to donating through the organization by hosting fundraising events, social gatherings, and more ▫
									</p>
									</div>
								</div>
								<div className="timeline-item is-danger">
									<div className="timeline-marker is-danger"></div>
									<div className="timeline-content">
									<p className="heading">Sep 2023</p>
									<p>
										▫ Metaverse incorporation<br />
										▫ Create and register our own foundation for global causes
									</p>
									</div>
								</div>

								<header className="timeline-header">
									<span className="tag is-medium is-primary">Future</span>
								</header>
							</div>
						</div>
					</section>
				}
			</div>
		</>
  	)
}

export default Home