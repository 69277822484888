import { useState } from "react";
import { Link } from 'react-router-dom';

import { useApi } from '../../hooks';
import { useUserContext } from '../../context';


const EmailVerifiedBar = () => {
    const api = useApi();
    const userContext = useUserContext();
    const [inProgress, setInProgress] = useState(false);

    const handleResendEmail = () => {
        setInProgress(true);
        api.resendVerificationEmail().then(response => {
            setInProgress(false);
        });
    };

    return (
        <>
            {
                userContext.isAuthenticated
                && !userContext.user.is_email_verified
                && <article className="message is-warning mb-2">
                    <div className="message-body">
                        Your email is not verified! Please check you mail inbox or
                        <Link
                            to=""
                            className="ml-1"
                            onClick={handleResendEmail}
                        >resend email</Link>
                        {
                            inProgress
                            ? <>
                                <span className="loader-dot">.</span>
                                <span className="loader-dot">.</span>
                                <span className="loader-dot">.</span>
                                <span className="loader-dot">.</span>
                                <span className="loader-dot">.</span>
                            </>
                            : '.'
                        }
                    </div>
                </article>
            }
        </>
    );
}

export default EmailVerifiedBar;