import { useReducer, useEffect, useCallback } from "react";
import { RiEyeCloseLine, RiEyeLine } from 'react-icons/ri';
import { FaLock, FaCheck, FaExclamationTriangle } from 'react-icons/fa';

import {
    areStringsEqual,
    isNotEmptyString,
    isValidShortString,
    isPasswordMinLength,
    passwordStrengthChecker
} from '../../../../services';
import { useApi } from '../../../../hooks';
import { useUserContext } from '../../../../context';
import { DataNotLoadedErrorMessage } from '../../../../components';


const ACTIONS = {
    enableEditRegime: 'ENABLE_SWITCH_REGIME',
    disableEditRegime: 'DISABLE_SWITCH_REGIME',

    setIsCurrentPasswordVisible: 'SET_IS_CURRENT_PASSWORD_VISIBLE',
    setIsNewPasswordVisible: 'SET_IS_NEW_PASSWORD_VISIBLE',

    setCurrenctPassword: 'SET_CURRENT_PASSWORD',
    setNewPassword: 'SET_NEW_PASSWORD',
    setConfirmPassword: 'SET_CONFIRM_PASSWORD',

    startSaving: 'START_SAVING',
    terminateSaving: 'TERMINATE_SAVING'
};
function reducer(form, action) {
    let newForm = {...form};
    const resetFields = (newForm) => {
        newForm.data.currentPassword = '';
        newForm.data.newPassword = '';
        newForm.data.confirmPassword = '';
    };
    const resetStates = (newForm) => {
        newForm.state.api.serverError = false;
        newForm.state.api.validationError = false;

        newForm.state.isCurrentPasswordValid = true;
        newForm.state.isNewPasswordValid = true;
        newForm.state.newPasswordMatchesCurrentPassword = false;
        newForm.state.newPasswordStrength = '';
        newForm.state.confirmPasswordMatchesNewPassword = true;

        newForm.state.isCurrentPasswordVisible = false;
        newForm.state.isNewPasswordVisible = false;

        newForm.state.isSaving = false;
        newForm.state.isEditing = false;
    };
    const validate = (newForm) => {
        newForm.state.api.serverError = false;
        newForm.state.api.validationError = false;

        newForm.state.isCurrentPasswordValid = true;
        newForm.state.isNewPasswordValid = true;
        newForm.state.newPasswordMatchesCurrentPassword = false;
        newForm.state.newPasswordStrength = '';
        newForm.state.confirmPasswordMatchesNewPassword = true;

        newForm.state.isCurrentPasswordValid = (
            isValidShortString(newForm.data.currentPassword)
            && isPasswordMinLength(newForm.data.currentPassword)
        );

        if (isPasswordMinLength(newForm.data.newPassword)) {
            newForm.state.newPasswordMatchesCurrentPassword = areStringsEqual(
                newForm.data.currentPassword,
                newForm.data.newPassword
            );
        }

        if (!newForm.state.newPasswordMatchesCurrentPassword) {
            newForm.state.isNewPasswordValid = (
                isValidShortString(newForm.data.newPassword)
                && isPasswordMinLength(newForm.data.newPassword)
            );

            if (newForm.state.isNewPasswordValid) {
                newForm.state.newPasswordStrength = passwordStrengthChecker(
                    newForm.data.newPassword
                )
            }
        }

        if (
            isPasswordMinLength(newForm.data.newPassword)
            && !newForm.state.newPasswordMatchesCurrentPassword
            && newForm.state.isNewPasswordValid
            && isNotEmptyString(newForm.data.confirmPassword)
        ) {
            newForm.state.confirmPasswordMatchesNewPassword = areStringsEqual(
                newForm.data.newPassword,
                newForm.data.confirmPassword
            )
        }
    };

    switch(action.type) {
        case ACTIONS.enableEditRegime:
            newForm.state.isEditing = true;

            return newForm;
        case ACTIONS.disableEditRegime:
            resetFields(newForm);
            resetStates(newForm);

            return newForm;
        case ACTIONS.setIsCurrentPasswordVisible:
            newForm.state.isCurrentPasswordVisible = action.payload.isShown;

            return newForm;
        case ACTIONS.setIsNewPasswordVisible:
            newForm.state.isNewPasswordVisible = action.payload.isShown;

            return newForm;
        case ACTIONS.setCurrenctPassword:
            newForm.data.currentPassword = action.payload.currentPassword;
            validate(newForm);

            return newForm;
        case ACTIONS.setNewPassword:
            newForm.data.newPassword = action.payload.newPassword;
            validate(newForm);

            return newForm;
        case ACTIONS.setConfirmPassword:
            newForm.data.confirmPassword = action.payload.confirmPassword;
            validate(newForm);

            return newForm;
        case ACTIONS.startSaving:
            newForm.state.isSaving = true;

            return newForm;
        case ACTIONS.terminateSaving:
            if (action.payload.isChanged) {
                if (action.payload.isSaved) {
                    resetStates(newForm);
                    resetFields(newForm);
                } else {
                    newForm.state.isSaving = false;

                    switch (action.payload.error) {
                        case 'SERVER_ERROR':
                            newForm.state.api.serverError = true;
                            newForm.state.api.validationError = false;
                            resetFields(newForm);
                            break;
                        default:
                            validate(newForm);
                            newForm.state.api.serverError = false;
                            newForm.state.api.validationError = true;
                            break;
                    }
                }
            }

            return newForm;
        default:
            return newForm;
    }
};


const FormPasswordChange = () => {
    const api = useApi();
    const userContext = useUserContext();

    const [form, dispatch] = useReducer(reducer, {
        data: {
            currentPassword: '',
            newPassword: '',
            confirmPassword: ''
        },
        state: {
            isCurrentPasswordValid: true,
            isNewPasswordValid: true,
            newPasswordMatchesCurrentPassword: false,
            newPasswordStrength: '',
            confirmPasswordMatchesNewPassword: true,

            isCurrentPasswordVisible: false,
            isNewPasswordVisible: false,

            isEditing: false,
            isSaving: false,
            api: {
                serverError: false,
                validationError: false
            }
        }
    });




    const timeShowingThePassword = 5000;
    const handleEdit = () => {
        dispatch({ type: ACTIONS.enableEditRegime });
    };
    const showCurrentPassword = () => {
        dispatch({
            type: ACTIONS.setIsCurrentPasswordVisible,
            payload: { isShown: true }
        });

        setTimeout(() => {
            dispatch({
                type: ACTIONS.setIsCurrentPasswordVisible,
                payload: { isShown: false }
            });
        }, timeShowingThePassword);
    };
    const hideCurrentPassword = () => {
        dispatch({
            type: ACTIONS.setIsCurrentPasswordVisible,
            payload: { isShown: false }
        });
    };
    const showNewPasswordVisualizing = () => {
        dispatch({
            type: ACTIONS.setIsNewPasswordVisible,
            payload: { isShown: true }
        });

        setTimeout(() => {
            dispatch({
                type: ACTIONS.setIsNewPasswordVisible,
                payload: { isShown: false }
            });
        }, timeShowingThePassword);
    };
    const hideNewPasswordVisualizing = () => {
        dispatch({
            type: ACTIONS.setIsNewPasswordVisible,
            payload: { isShown: false }
        });
    };
    const handleCurrentPasswordChange = (currentPassword) => {
        dispatch(
            {
                type: ACTIONS.setCurrenctPassword,
                payload: { currentPassword: currentPassword }
            }
        );
    };
    const handleNewPasswordChange = (newPassword) => {
        dispatch(
            {
                type: ACTIONS.setNewPassword,
                payload: { newPassword: newPassword }
            }
        );
    };
    const handleConfirmPasswordChange = (confirmPassword) => {
        dispatch(
            {
                type: ACTIONS.setConfirmPassword,
                payload: { confirmPassword: confirmPassword }
            }
        );
    };
    const handleCancel = () => {
        dispatch({ type: ACTIONS.disableEditRegime });
    };
    const handleSave = (e) => {
        e.preventDefault();
        e.stopPropagation();

        dispatch({ type: ACTIONS.startSaving });

        if (
            form.data.currentPassword !== ''
            && form.data.newPassword !== ''
            && form.data.confirmPassword !== ''

            && form.state.isCurrentPasswordValid
            && !form.state.newPasswordMatchesCurrentPassword
            && form.state.isNewPasswordValid
            && form.state.confirmPasswordMatchesNewPassword
        ) {
            api.updateSettingsPassword(
                {
                    "current_password": form.data.currentPassword,
                    "new_password": form.data.newPassword,
                    "confirm_password": form.data.confirmPassword
                }
            ).then(response => {
                dispatch(
                    {
                        type: ACTIONS.terminateSaving,
                        payload: {
                            isChanged: true,
                            isSaved: response.ok,
                            error: response.error
                        }
                    }
                );
            });
        } else {
            dispatch(
                {
                    type: ACTIONS.terminateSaving,
                    payload: { isChanged: false }
                }
            );
        }
    };

    const handleEnter = useCallback((e) => {
		if (e.key === "Enter") {
			e.preventDefault();
        	e.stopPropagation();

			if (
                form.data.currentPassword !== ''
                && form.data.newPassword !== ''
                && form.data.confirmPassword !== ''

                && form.state.isCurrentPasswordValid
                && !form.state.newPasswordMatchesCurrentPassword
                && form.state.isNewPasswordValid
                && form.state.confirmPasswordMatchesNewPassword
			) {
				handleSave(e);
			}
		}
	}, []);
	useEffect(() => {
		document.addEventListener("keydown", handleEnter, false);

		return () => {
		  	document.removeEventListener("keydown", handleEnter, false);
		};
	}, []);

    return (
        <>
            {
                userContext.user === null
                && <DataNotLoadedErrorMessage />
            }
            {
                userContext.user !== null
                && !form.state.isEditing
                && <>
                    <div className="field">
                        <label className="label">Current password</label>
                        <p className="control has-icons-left">
                            <input className="input" type="password" value="********" disabled/>
                            <span className="icon is-small is-left">
                                <FaLock />
                            </span>
                        </p>
                    </div>

                    <div className="field is-grouped is-flex is-justify-content-right">
                        <div className="control">
                            <button
                                onClick={handleEdit}
                                className="button is-secondary is-rounded"
                            >Change</button>
                        </div>
                    </div>
                </>
            }
            {
                userContext.user !== null
                && form.state.isEditing
                && <>
                    {
                        form.state.api.serverError
                        && <article className="message is-danger">
                            <div className="message-body">
                                Error. Please wait a few minutes before you try again.
                            </div>
                        </article>
                    }
                    {
                        form.state.api.validationError
                        && <article className="message is-danger">
                            <div className="message-body">
                                Please check the fields below and make sure all the required data are filled in.
                            </div>
                        </article>
                    }



                    <div className="field">
                        <label className="label">Current password</label>
                        <p className="control has-icons-left has-icons-right">
                            <input
                                className={`input ${
                                    form.data.currentPassword !== ''
                                    && !form.state.isCurrentPasswordValid
                                    && 'is-danger'
                                }`}
                                type={`${form.state.isCurrentPasswordVisible ? "text" : "password" }`}
                                value={form.data.currentPassword}
                                onChange={(e) => handleCurrentPasswordChange(e.target.value)}
                                autoFocus={true}
                            />
                            <span className="icon is-small is-left">
                                <FaLock />
                            </span>

                            {
                                !form.state.isCurrentPasswordVisible
                                && <span
                                    className="icon is-small is-right"
                                    data="test"
                                    onClick={showCurrentPassword}
                                    style={{pointerEvents: "all", pointer: "cursor"}}
                                >
                                    <RiEyeCloseLine/>
                                </span>
                            }

                            {
                                form.state.isCurrentPasswordVisible
                                && <span
                                    className="icon is-small is-right"
                                    onClick={hideCurrentPassword}
                                    style={{pointerEvents: "all", pointer: "cursor"}}
                                >
                                    <RiEyeLine/>
                                </span>
                            }
                        </p>
                        {
                            form.data.currentPassword !== ''
                            && (
                                <>
                                    {
                                        !form.state.isCurrentPasswordValid
                                        && <p className="help is-danger">The currency password should be at least 6 characters long and no longer than 250 characters.</p>
                                    }
                                </>
                            )
                        }
                    </div>
                    <div className="field">
                        <label className="label">New password</label>
                        <p className="control has-icons-left has-icons-right">
                            <input
                                className={`input ${
                                    form.data.newPassword !== ''
                                    && (
                                        form.state.newPasswordMatchesCurrentPassword
                                        || !form.state.isNewPasswordValid
                                    )
                                    && 'is-danger'
                                }`}
                                type={`${form.state.isNewPasswordVisible ? "text" : "password" }`}
                                value={form.data.newPassword}
                                onChange={(e) => handleNewPasswordChange(e.target.value)}
                            />
                            <span className="icon is-small is-left">
                                <FaLock />
                            </span>

                            {
                                !form.state.isNewPasswordVisible
                                && <span
                                    className="icon is-small is-right"
                                    data="test"
                                    onClick={showNewPasswordVisualizing}
                                    style={{pointerEvents: "all", pointer: "cursor"}}
                                >
                                    <RiEyeCloseLine/>
                                </span>
                            }

                            {
                                form.state.isNewPasswordVisible
                                && <span
                                    className="icon is-small is-right"
                                    onClick={hideNewPasswordVisualizing}
                                    style={{pointerEvents: "all", pointer: "cursor"}}
                                >
                                    <RiEyeLine/>
                                </span>
                            }
                        </p>
                        {
                            form.data.newPassword !== ''
                            && (
                                <>
                                    {
                                        form.state.newPasswordMatchesCurrentPassword
                                        && <p className="help is-danger">The new password must not be the same as the current one.</p>
                                    }
                                    {
                                        !form.state.isNewPasswordValid
                                        && <p className="help is-danger">The new password must be at least 6 characters long and must contain at least one uppercase letter, one lowercase letter, one digit and one special character.</p>
                                    }
                                    {
                                        form.state.newPasswordStrength === 'WEAK'
                                        && <p className="help has-text-grey-light">Strength: Weak</p>
                                    }
                                    {
                                        form.state.newPasswordStrength === 'MEDIUM'
                                        && <p className="help has-text-info">Strength: Medium</p>
                                    }
                                    {
                                        form.state.newPasswordStrength === 'STRONG'
                                        && <p className="help has-text-success">Strength: Strong</p>
                                    }
                                </>
                            )
                        }
                    </div>
                    <div className="field">
                        <label className="label">Confirm password</label>
                        <p className="control has-icons-left has-icons-right">
                            <input
                                className={`input ${
                                    form.data.confirmPassword !== ''
                                    && !form.state.confirmPasswordMatchesNewPassword
                                    && 'is-danger'
                                }`}
                                type={`${form.state.isNewPasswordVisible ? "text" : "password" }`}
                                value={form.data.confirmPassword}
                                onChange={(e) => handleConfirmPasswordChange(e.target.value)}
                            />
                            <span className="icon is-small is-left">
                                <FaLock />
                            </span>

                            {
                                form.data.confirmPassword !== ''
                                && (
                                    <>
                                        {
                                            form.state.confirmPasswordMatchesNewPassword
                                            && <span className="icon is-small is-right">
                                                <FaCheck/>
                                            </span>
                                        }

                                        {
                                            !form.state.confirmPasswordMatchesNewPassword
                                            && <span className="icon is-small is-right">
                                                <FaExclamationTriangle/>
                                            </span>
                                        }
                                    </>
                                )
                            }
                        </p>
                        {
                            form.data.confirmPassword !== ''
                            && (
                                <>
                                    {
                                        !form.state.confirmPasswordMatchesNewPassword
                                        && <p className="help is-danger">The confirm password does not match the new password.</p>
                                    }
                                </>
                            )
                        }
                    </div>

                    <div className="field is-grouped is-flex is-justify-content-right">
                        <div className="control">
                            <button
                                onClick={handleCancel}
                                className="button is-rounded"
                            >Cancel</button>
                        </div>
                        <div className="control">
                            <button
                                onClick={handleSave}
                                className="button is-primary is-rounded"
                                disabled={
                                    !isNotEmptyString(form.data.currentPassword)
                                    || !isNotEmptyString(form.data.newPassword)
                                    || !isNotEmptyString(form.data.confirmPassword)

                                    || !form.state.isCurrentPasswordValid
                                    || form.state.newPasswordMatchesCurrentPassword
                                    || !form.state.isNewPasswordValid
                                    || !form.state.confirmPasswordMatchesNewPassword
                                }
                            >
                                Set new password
                                {
                                    form.state.isSaving
                                    && <span className="cl-button-loader"></span>
                                }
                            </button>
                        </div>
                    </div>
                </>
            }
        </>
    );
}

export default FormPasswordChange;