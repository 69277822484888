import { Link } from 'react-router-dom';
import { FaLinkedin } from 'react-icons/fa';
import { useState, useEffect } from "react";

import { useGamesContext } from '../../context';

import tomGrangerProfileImg from '../../assets/img/team/tom-granger.jpeg';
import pavelTashevProfileImg from '../../assets/img/team/pavel-tashev.JPG';


const AboutUs = () => {
	const [game, setGame] = useState(false);
	const gameContext = useGamesContext();

	useEffect(() => {
		if (gameContext.games && gameContext.games.length > 0) {
			setGame(gameContext.games[0]);
		}
	}, [gameContext]);

  	return (
		<div className="container">
			<section className="section">
				<div className="content">
					<h1 className="title">About Us</h1>
					<h2 className="title is-4">Crypto lottery games</h2>
					<p>CoinLottery is a blockchain lottery that can be played from anywhere, prizes transacted immediately after drawing the numbers and received within an hour. You can play with your Bitcoin and win more Bitcoin.</p>
					<p>Ethereum based lottery games are here to come as well much more.</p>
					{
						game &&
						<Link to={`/game/${game.key}`} className="button is-primary is-rounded mt-5">Play the Game</Link>
					}
				</div>

				<div className="columns mt-6">
					<div className="column is-one-fifth"></div>
					<div className="column">
						<div className="content">
							<h2 className="title is-4">Life changing projects</h2>
							<p>CoinLottery has a mission to make a positive impact on the world. With your support, we aim to raise funds and support a variety of projects that benefit local communities, sports teams, the environment, creative talent, and individuals of all ages.</p>
						</div>
						<div className="content">
							<p>Rest assured that your contributions will be directed to where they are needed most through partnerships with organizations that ensure proper allocation of funds. Together, we can make a difference and change lives.</p>
							<Link to="/winners-and-good-causes" className="button is-primary is-rounded mt-5">Discover More</Link>
						</div>
					</div>
				</div>
			</section>

			<section className="section is-hidden-widescreen">
				<div className="content">
					<h1 className="title">Team</h1>
					<div className="columns mt-3">
						<div className="column is-3">
							<div className="media">
								<div className="media-left">
									<figure className="image is-128x128">
										<img className="is-rounded" src={tomGrangerProfileImg} alt="Tom Granger" width="128" />
									</figure>
								</div>
							</div>
						</div>
						<div className="column">
							<div className="media">
								<div className="media-content">
									<p className="title is-4">Tom Granger</p>
									<p className="subtitle is-6">
										Co-founder at CoinLottery <br />
										<FaLinkedin /> <a
											href="https://www.linkedin.com/in/tom-granger-aa75b821/"
											target="_blank"
											rel="noreferrer nofollow"
											className="link-standard"
										>LinkedIn</a>
									</p>
									<p>Product Owner - GuardNet. Software company owner - White Rabbit.</p>
								</div>
							</div>
						</div>
						<div className="column is-3">
							<div className="media">
								<div className="media-left">
									<figure className="image is-128x128">
										<img className="is-rounded" src={pavelTashevProfileImg} alt="Pavel Tashev" width="128" />
									</figure>
								</div>
							</div>
						</div>
						<div className="column">
							<div className="media">
								<div className="media-content">
									<p className="title is-4">Pavel Tashev</p>
									<p className="subtitle is-6">
										Co-founder, CEO and CTO at CoinLottery <br />
										<FaLinkedin /> <a
											href="https://www.linkedin.com/in/paveltashev/"
											target="_blank"
											rel="noreferrer nofollow"
											className="link-standard"
										>LinkedIn</a>
									</p>
									<p>Software developer and a Product owner. Ex-co-founder of the startups Schoodle (connecting teachers and  parents), QRL (bit.ly for QR codes), Peoplegogo (crowdfunding platform for charities) and EasyMail (mail delivery service). Currently a co-founder of DeepSource (software service company).</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="section is-hidden-touch is-hidden-desktop-only">
				<div className="content">
					<h1 className="title">Team</h1>
					<div className="columns mt-3">
						<div className="column is-2">
							<div className="media">
								<div className="media-left">
									<figure className="image is-128x128">
										<img className="is-rounded" src={tomGrangerProfileImg} alt="Tom Granger" width="128" />
									</figure>
								</div>
							</div>
						</div>
						<div className="column">
							<div className="media">
								<div className="media-content">
									<p className="title is-4">Tom Granger</p>
									<p className="subtitle is-6">
										Co-founder at CoinLottery <br />
										<FaLinkedin /> <a
											href="https://www.linkedin.com/in/tom-granger-aa75b821/"
											target="_blank"
											rel="noreferrer nofollow"
											className="link-standard"
										>LinkedIn</a>
									</p>
									<p>Product Owner - GuardNet. Software company owner - White Rabbit.</p>
								</div>
							</div>
						</div>
						<div className="column is-2">
							<div className="media">
								<div className="media-left">
									<figure className="image is-128x128">
										<img className="is-rounded" src={pavelTashevProfileImg} alt="Pavel Tashev" width="128" />
									</figure>
								</div>
							</div>
						</div>
						<div className="column">
							<div className="media">
								<div className="media-content">
									<p className="title is-4">Pavel Tashev</p>
									<p className="subtitle is-6">
										Co-founder, CEO and CTO at CoinLottery <br />
										<FaLinkedin /> <a
											href="https://www.linkedin.com/in/paveltashev/"
											target="_blank"
											rel="noreferrer nofollow"
											className="link-standard"
										>LinkedIn</a>
									</p>
									<p>Software developer and a Product owner. Ex-co-founder of the startups Schoodle (connecting teachers and  parents), QRL (bit.ly for QR codes), Peoplegogo (crowdfunding platform for charities) and EasyMail (mail delivery service). Currently a co-founder of DeepSource (software service company).</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<div className="block my-6"></div>
		</div>
  	)
}

export default AboutUs