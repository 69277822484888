import { Link } from 'react-router-dom';

const PageNotFound = () => {
  	return (
    	<div className="container">
			<section className="section">
				<div className="columns">
					<div className="column"></div>
					<div className="column is-7">
						<div className="content has-text-centered">
							<h1 className="title is-size-1 has-text-primary">404</h1>
							<h2 className="subtitle is-5 mb-6">You didn't break the Internet, but we can't find what you are looking for.</h2>
							<Link to="/" className="link-standard mt-6">Home page</Link>
						</div>
					</div>
					<div className="column"></div>
				</div>
			</section>
		</div>
  	)
}

export default PageNotFound