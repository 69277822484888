import { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';

import {
	LuckyDipBox,
	MenuListOfGamesMobile,
	DataNotLoadedErrorMessage
} from '../../components';
import { useWindowSize } from '../../hooks';
import ListOfWins from './Lists/ListOfWins';
import ListOfPlays from './Lists/ListOfPlays';
import { getValidPage } from '../../services';
import { useUserContext, useGamesContext } from '../../context';
import MenuResultsAndDrawsMobile from './Components/MenuResultsAndDrawsMobile';
import MenuResultsAndDrawsDesktop from './Components/MenuResultsAndDrawsDesktop';


const Results = () => {
	const userContext = useUserContext();

	const nagivate = useNavigate();

	let { page, id: openPlayId } = useParams();
	page = getValidPage(page);
	if (!page) nagivate.push('/page-not-found');

	const { key } = useParams();

	const mobileWindowMaxSize = 1023;
	const size = useWindowSize();





	const [game, setGame] = useState(false);
	const gameContext = useGamesContext();
	useEffect(() => {
		if (gameContext.games) {
			for (let i = 0; i < gameContext.games.length; i++) {
				if (gameContext.games[i].key === key) {
					setGame(gameContext.games[i]);
					break;
				}
			}
		}
	}, [gameContext, key]);

  	return (
		<div className="container">
			{
				!game
				&& <div className="mt-3">
					<DataNotLoadedErrorMessage/>
				</div>
			}

			{
				game
				&& <>
					<div className="container is-fixed-top is-hidden-desktop">
						<MenuListOfGamesMobile
							games={gameContext.games}
							urlEnding="/results"
						/>
						{
							userContext.isAuthenticated
							&& <MenuResultsAndDrawsMobile gameKey={game.key}/>
						}
					</div>

					<section className="section">
						<div className="content">
							<h1 className="title">{game.name} Results</h1>
						</div>
						<div className="content is-hidden-touch">
							{
								userContext.isAuthenticated
								&& <MenuResultsAndDrawsDesktop
									gameKey={game.key}
									gameColor={game.color}
								/>
							}
						</div>
						<div className="content mt-6">
							<div className="columns">
								<div className="column is-8">
									<div className="content">
										<h2 className="title is-4">Wins</h2>
										<ListOfWins gameKey={key} />
									</div>
									<div className="content">
										{
											size.width <= mobileWindowMaxSize
											&& <LuckyDipBox
												user={userContext}
												game={game}
												additionalClassName="is-hidden-desktop"
											/>
										}
									</div>

									<br />

									<div className="content">
										<h2 className="title is-4">Number Matches</h2>
										<ListOfPlays
											gameKey={key}
											openPlayId={openPlayId}
											currentPage={page}
										/>
									</div>
								</div>

								<div className="column">
									{
										size.width > mobileWindowMaxSize
										&& <LuckyDipBox
											user={userContext}
											game={game}
											additionalClassName="is-hidden-touch"
										/>
									}
								</div>
							</div>
						</div>
					</section>
				</>
			}
		</div>
    )
}

export default Results