import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';

import {
    Pagination,
    FiatFormatter,
    InProgressText,
	CryptoFormatter,
    DataNotLoadedErrorMessage
} from '../../../../components';
import { useApi } from '../../../../hooks';
import { useUserContext } from '../../../../context';


const ListOfTransactions = ({ currentPage = 1 }) => {
    const userContext = useUserContext();

    const api = useApi();
    const [error, setError] = useState(false);
    const [results, setResults] = useState(false);
    const [isPending, setIsPending] = useState(true);
    const [totalPages, setTotalPages] = useState(false);
    useEffect(() => {
        api.getSettingsTransactions(currentPage).then(response => {
            setIsPending(false);

            if (!response.ok) {
                setError(true);
                return;
            }

            setResults(response.data.results);
            setTotalPages(response.data.total_pages);
        });
    }, [currentPage]);

    return (
        <>
            {error && <DataNotLoadedErrorMessage />}
			{isPending && <InProgressText/>}
            {
                results
                ? (
                    <>
                        <table className="table tableList is-size-7">
                            <thead>
                                <tr>
                                    <th>Date and Time</th>
                                    <th>Network</th>
                                    <th>Tnx</th>
                                    <th>Status</th>
                                    {/* <th>Invoice</th> */}
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th>Date and Time</th>
                                    <th>Network</th>
                                    <th>Tnx</th>
                                    <th>Status</th>
                                    {/* <th>Invoice</th> */}
                                    <th>Amount</th>
                                </tr>
                            </tfoot>
                            <tbody>
                                {
                                    results.length > 0
                                    ? results.map((transaction) => (
                                        <tr key={transaction.id}>
                                            <td>{transaction.datetime}</td>
                                            <td>
                                                {
                                                    'BITCOIN' === transaction.network
                                                    && <span>Bitcoin</span>
                                                }
                                                {
                                                    'LIGHTNING' === transaction.network
                                                    && <span>Bitcoin Lightning</span>
                                                }
                                                {
                                                    'ONCHAIN' === transaction.network
                                                    && <span>Bitcoin On-chain</span>
                                                }
                                            </td>
                                            <td>
                                                {
                                                    'ONCHAIN' === transaction.network
                                                    && <>
                                                        {
                                                            transaction.transactions.map((t) => (
                                                                <a href={t.tnx_link} className="link-standard" target="_blank">{t.tnx_label}</a>
                                                            ))
                                                        }
                                                    </>
                                                }
                                            </td>
                                            <td>
                                                {
                                                    transaction.status === 'UNDERPAID'
                                                    && <span className="has-text-danger">Underpaid</span>
                                                }
                                                {
                                                    transaction.status === 'PROCESSING'
                                                    && <span className="has-text-info">Pending</span>
                                                }

                                                {
                                                    transaction.status === 'REFUNDED'
                                                    && <span>Refunded</span>
                                                }
                                                {
                                                    transaction.status === 'PAID'
                                                    && <span>Paid</span>
                                                }
                                                {
                                                    transaction.status === 'EXPIRED'
                                                    && <span>Expired</span>
                                                }
                                            </td>
                                            {/* <td><Link to={transaction.invoice_link} className="link-standard">PDF</Link></td> */}
                                            <td>
                                                <CryptoFormatter
                                                    type="DETAIL"
                                                    data={transaction.amount.crypto}
                                                />&nbsp;
                                                (<FiatFormatter
                                                    type="DETAIL"
                                                    data={transaction.amount.fiat[userContext.currency_code]}
                                                />)
                                            </td>
                                        </tr>
                                    ))
                                    : (
                                        <tr className="noHover">
                                            <td colSpan="6">There are no recent transactions to display.</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>

                        <Pagination
                            url="/settings/funds-and-payments/transaction-history"
                            page={currentPage}
                            totalPages={totalPages}
                            numberOfResults={results.length}
                        />
                    </>
                )
                : <>
                    {
                        !isPending
                        && <p className="has-text-grey ml-3 mb-3">There are no recent transactions to display.</p>
                    }
                </>
            }
        </>
     );
}

export default ListOfTransactions;