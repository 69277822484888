import { Link } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { FaAngleUp, FaAngleDown } from 'react-icons/fa';

import {
    Pagination,
    InProgressText,
    DataNotLoadedErrorMessage
} from '../../../components';
import { useApi } from '../../../hooks';


const ListOfPlays = ({ gameKey, openPlayId, currentPage = 1 }) => {
    const api = useApi();
    let openRef = useRef();

    const [data, setData] = useState([]);
    const [error, setError] = useState(false);
    const [results, setResults] = useState(false);
    const [isPending, setIsPending] = useState(true);
    const [totalPages, setTotalPages] = useState(false);
    useEffect(() => {
        setData([]);
        setError(false);
        setResults(false);
        setIsPending(true);
        setTotalPages(false);

        api.getGamePlays(gameKey, currentPage).then(response => {
            setIsPending(false);

            if (!response.ok) {
                setError(true);
                return;
            }

            setResults(response.data.results);
            setTotalPages(response.data.total_pages);
        });
    }, [gameKey, currentPage]);

    useEffect(() => {
        if (data) {
            setResults(data.results);
            setTotalPages(data.totalPages);
        }
    }, [data]);





    const handleToggle = (index) => {
        let newResults = [...results];
		newResults[index].is_open = !newResults[index].is_open
		setData({
            page: currentPage,
            totalPages: totalPages,
            results: newResults,
        });
	}

    useEffect(() => {
        if (
            typeof openPlayId !== 'undefined' &&
            typeof openRef.current !== 'undefined'
        ) {
            window.scrollTo(
                {
                    behavior: 'smooth',
                    top: openRef.current.offsetTop
                }
            )
        }
    }, [openRef, openPlayId]);

    return (
        <>
            {error && <DataNotLoadedErrorMessage />}
			{isPending && <InProgressText/>}
            {
                results
                && (
                    <>
                        {
                            results.length === 0
                            ? (
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <td colSpan="4 is-size-7">No played games.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            )
                            : results.map((play, index) => (
                                <div className="card mb-3" key={`play-${play.id}`} ref={openRef}>
                                    <header
                                        className="card-header"
                                        onClick={() => handleToggle(index)}
                                    >
                                        <p className="card-header-title">
                                            {play.date}
                                            {
                                                Object.keys(play.draw).length === 0
                                                ? (
                                                    <span className="tag is-dark ml-3">Upcoming draw</span>
                                                )
                                                : ''
                                            }
                                        </p>
                                        <button className="card-header-icon" aria-label="more options">
                                            <span className="icon">
                                                {
                                                    play.is_open
                                                    ? <FaAngleUp />
                                                    : <FaAngleDown />
                                                }
                                            </span>
                                        </button>
                                    </header>
                                    <div
                                        className="card-content"
                                        style={{display: (play.is_open || play.id == openPlayId) ? 'block' : 'none' }}
                                    >
                                        <div className="content">
                                            <p>Bought tickets and matched numbers</p>
                                        </div>
                                        <div className="content">
                                            <div className="columns">
                                                <div className="column is-8">
                                                    {
                                                        play.tickets.map((ticket, ticketIndex) => (
                                                            <div
                                                                key={`ticket-index-${ticketIndex}`}
                                                                className={`content columns ${ticketIndex === 0 ? 'mb-0' : ''}`}
                                                            >
                                                                <div className="column clearfix py-0">
                                                                    {
                                                                        ticket.map((number) => (
                                                                            <p
                                                                                key={number}
                                                                                className={
                                                                                    (
                                                                                        Object.keys(play.draw).length > 0 &&
                                                                                        play.draw.numbers.includes(number)
                                                                                    )
                                                                                    ? 'selected-number'
                                                                                    : 'none-selected-number-disabled'
                                                                                }
                                                                            >{number}</p>
                                                                        ))
                                                                    }
                                                                </div>

                                                                { ticketIndex === (ticketIndex.length - 1) && <br /> }
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                                <div className="column is-4 message-in-section-number-matches">
                                                    {
                                                        Object.keys(play.draw.numbers).length === 0
                                                        ? (
                                                            <Link to={`/game/${gameKey}/watch`} className="button is-rounded">WATCH DRAW</Link>
                                                        )
                                                        : (
                                                            <>
                                                                {
                                                                    play.is_win
                                                                    ? (
                                                                        <>
                                                                            {
                                                                                play.win_type === 'JACKPOT'
                                                                                && <>
                                                                                    {
                                                                                        play.is_claimed
                                                                                        ? <p>The jackpot is won and claimed</p>
                                                                                        : <p>You win the jackpot!</p>
                                                                                    }
                                                                                </>
                                                                            }
                                                                            {
                                                                                play.win_type !== 'JACKPOT'
                                                                                && <>
                                                                                    {
                                                                                        play.is_claimed
                                                                                        ? <p>The prize is won and claimed</p>
                                                                                        : <p>You win a prize</p>
                                                                                    }
                                                                                </>
                                                                            }
                                                                            {
                                                                                !play.is_claimed
                                                                                && <Link to="/how-to-claim" className="button is-secondary is-rounded">CLAIM</Link>
                                                                            }
                                                                        </>
                                                                    )
                                                                    : (
                                                                        <p>No match</p>
                                                                    )
                                                                }
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }

                        <Pagination
                            url={`/game/${gameKey}/results`}
                            page={currentPage}
                            totalPages={totalPages}
                            numberOfResults={results.length}
                        />
                    </>
                )
            }
        </>
    );
}

export default ListOfPlays;