import { Link } from 'react-router-dom';

import { useGamesContext } from '../../context';


const ListOfGamesPointingToResults = ({ pointToDraws = false }) => {
    const gameContext = useGamesContext();

    return (
        <>
            {
				gameContext.gameGroups && gameContext.gameGroups.map((gameGroup, index) => (
                    <div key={`gameGroup-${index}`} className="columns">
                        {
                            gameGroup.map((game) => (
                                <Link
                                    to={`/game/${game.key}/${pointToDraws ? 'draws' : 'results'}`}
                                    key={game.id}
                                    className="column p-1"
                                >
                                    <div className={`lotto-card lotto-card-${game.color} is-clickable border-radius-10 has-text-white has-text-centered px-3 py-3`}>
                                        <div className="jackpot-size-nav is-size-4 has-text-weight-bold">
                                            {game.name}
                                        </div>
                                        <button className="button lotto-button is-rounded mt-4">
                                            CHECK
                                        </button>
                                    </div>
                                </Link>
                            ))
                        }
                    </div>
				))
			}
        </>
    );
}

export default ListOfGamesPointingToResults;